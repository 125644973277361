import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import icons from '../../../constants/icons';
import BasicBigHeader from '../../../components/dashboard/common/PageHeaders/BasicBigHeader';
import ContentWithUserDetails from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/ContentWithUserDetails';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import BasicStats from '../../../components/dashboard/common/BasicStats';
import BasicStat from '../../../components/dashboard/common/BasicStats/BasicStat';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableCheckbox from '../../../components/core/table/inputs/Checkbox';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import Pagination from '../../../components/core/table/pagination';
import {globals} from '../../../utils';
import Card from '../../../components/core/dashboard/Card';
import BasicListItem from '../../../components/dashboard/common/Requests/BasicListItem';
import BasicActivitiesList from '../../../components/dashboard/common/Activities/BasicActivitiesList';
import BasicActivityListItem from '../../../components/dashboard/common/Activities/BasicActivityListItem';
import { TweetServices } from '../../../services';
import { ToastAlert } from '../../../utils';

const WardenHomePage = ({user}) => {
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    
    useEffect(() => {
        let nameArray = user.name.split(" ");
        setFirstName(nameArray[0]);
    }, [user]);

     const [stats,setStats] = useState([])

     const [tweets, setTweets] = useState([]);
     const [totalTweetsCount, setTotalTweetsCount] = useState(0);
     const [totalPagesCount, setTotalPagesCount] = useState(0);
     const [currentPage, setCurrentPage] = useState(1);


    const handleGetThisStatReq = async () => {
        const getThisStatReq = await TweetServices.getThisWardenStats({
            token:user.access_token,
        })
        
        if(getThisStatReq.status === false){
            ToastAlert.notifyError(getThisStatReq.message);
            setStats([]);
        }
        else{
            setStats(20);
        }
    }

    useEffect(()=>{
        handleGetThisStatReq()
    },[])

    const handleGetTweetsRequest = async () => {
        const getTweetsReq = await TweetServices.getThisWardenTweets({
            token: user.access_token,
        });

        if(getTweetsReq.status === false){
            ToastAlert.notifyError(getTweetsReq.message);
            setTweets([]);
        }
        else{
            const Tweets = getTweetsReq.data.items.map(tweet => ({
                ...tweet,
                createdAtFormatted : moment(tweet.createdAt).format('MMMM Do YYYY, h:mm:ss A'),
            }));
            setTweets(Tweets);
        }
    }

    useEffect(() => {
        handleGetTweetsRequest();
    },[]);

    return(
        <>
            <BasicBigHeader>
                <ContentWithUserDetails 
                    avatar={'/images/default-avatar.jpg'}
                    heading={`welcome back, ${firstName}!`}
                    descriptionIcon={icons.bellSolid}
                    hasDescriptionIcon={true}
                    description={`Stay connected ${user?.name} : Create and review your tweets seamlessly!`}
                />
    {/* 
                    <HeaderActions>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg="primary"
                            borderRadius="full"
                            hasIcon={true}
                            iconPosition="left"
                            icon={icons.tickCircle}
                        >
                            pending approvals
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg="dashboard"
                            borderRadius="full"
                            hasIcon={true}
                            iconPosition="left"
                            icon={icons.settingsGear}
                        >
                            settings
                        </Button>
                    </HeaderActions> */}
            </BasicBigHeader>

            <BasicStats>
                <PlainContainer>
                    <SplitContainer styles={{marginLeft:"20%",marginBottom:"2px"}}>
                    <BasicStat 
                    cardHeading={"Your Tweet"}
                    statCount={21}
                    statLable="Tweets Creation"
                    cardStatColor="yellow"
                    secondaryStatLable="Feature Updates"    
                    secondaryStatCount={12}
                    statId={`pendingRequests`}
                />
                <BasicStat 
                    cardHeading={"Total Organization"}
                    statCount={68}
                    statLable="organization"
                    cardStatColor="red"
                    secondaryStatLable="Regular"
                    secondaryStatCount={20}
                    statId={`pendingApprovals`}
                />
                    </SplitContainer>
             
                </PlainContainer>
              
            </BasicStats>
            <PlainContainer type="full" styles={{paddingLeft: '30px', paddingRight: '30px'}}>
                {/* <SplitContainer type="full" styles={{margin: '0px', flex: '50%'}}> */}
                    <PlainContainer type="full">
                        <Card   
                            heading={"recent Tweets"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryAction={()=>navigate("/masterdata/get-tweet")}
                            bottomPrimaryActionLable={"see all recent requests"}
                        >
                            <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        {/* <PlainContainer type="full" styles={{paddingTop: "15px", paddingRight: "25px", paddingBottom: "25px", paddingLeft: "25px"}}>
                            <DistrictsListFilters
                            users={users}
                             states={states} 
                             state={state}
                             StateTypeChanged={setState}
                             createdOn={createdOn}
                             createdOnChanged={setCreatedOn} 
                             createdBy={createdBy}
                             createdByChanged={setCreatedOn}
                            />
                        </PlainContainer> */}
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    {/* <TableHeadingItem size={0} hasChildren={true}>
                                        <TableCheckbox name={"items-selected-ckb"} />
                                    </TableHeadingItem> */}
                                    <TableHeadingItem size={2} lable={"Organization"} />
                                    <TableHeadingItem size={1} lable={"Description"} />
                                    <TableHeadingItem size={2} lable={"Posted On"} />
                                    <TableHeadingItem size={1} lable={"created by"} />
                                    {/* <TableHeadingItem size={1} lable={"no. of cities"} /> */}
                                    {/* <TableHeadingItem size={0} lable={""} />
                                    <TableHeadingItem size={2} lable={"Actions"} /> */}
                                </TableHeading>
                                <TableRows>
                                    {
                                        tweets.map((tweet, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    {/* <TableRowItem size={0} hasChildren={true} isCenter={false}>
                                                        <TableCheckbox name={`items-selected-ckb-${1}`} />
                                                    </TableRowItem> */}
                                                    <TableRowItem size={2} isCenter={false} hasPreview={true} previewType={"image"} previewItem={`${globals.API_BASE_URL}${tweet?.image?.image_url?.full?.high_res}`} lable={tweet?.content} />
                                                    <TableRowItem size={1} isCenter={false} lable={tweet?.district?.name?.en} />
                                                    <TableRowItem size={2} isCenter={false} lable={tweet?.createdAtFormatted} />
                                                    {/* <TableRowItem size={1} isCenter={false} lable={`${school?.created_by.first_name} (${school?.created_by?.role === "SUPER_ADMIN" ? "Super Admin" : "Admin" })`} /> */}
                                                    <TableRowItem size={1} isCenter={false} lable={`${tweet?.created_by?.first_name} ${tweet?.created_by?.last_name}`} />
                                                    {/* <TableRowItem size={0} isCenter={true} hasChildren={true}>
                                                        <TableRowIcons>
                                                            <TableRowIcon icon={icons.eye} color={"dark"} action={() => navigate(`${school.id}`)} />
                                                        </TableRowIcons>
                                                    </TableRowItem> */}
                                                    {/* <TableRowItem size={2} isCenter={false} hasChildren={true}>
                                                        <TableRowActions>
                                                            <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => navigate(`edit/${school?.id}`)}>edit</TableButton>
                                                            <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => console.log(school.id)}>delete</TableButton>
                                                        </TableRowActions>
                                                    </TableRowItem> */}
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                        {
                            totalPagesCount > 1
                            ?
                                <PlainContainer type='full' styles={{ padding: "0px 25px" }}>
                                    <Pagination totalPages={totalPagesCount} setCurrentPage={setCurrentPage}/>
                                </PlainContainer>
                            :
                                <></>
                        }
                    </PlainContainer>
                </Card>
                        </Card>
                    </PlainContainer>
                    {/* <PlainContainer type="full">
                        <Card 
                            heading={"pending approvals"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryActionLable={"see all recent approvals"}
                        >
                            <PlainContainer type="full" styles={{marginTop: '5px'}}>
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                            </PlainContainer>
                        </Card>
                    </PlainContainer> */}
                {/* </SplitContainer> */}
            </PlainContainer>

            {/* <PlainContainer type="full" styles={{paddingLeft: '30px', paddingRight: '30px', marginTop: '30px', marginBottom: '30px'}}>
                <SplitContainer type="full"styles={{margin: '0px', flex: '50%'}}>
                    <PlainContainer type="full">
                        <Card 
                            heading={"recent complaints"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryActionLable={"see all recent complaints"}
                        >
                            <PlainContainer type="full" styles={{marginTop: '5px'}}>
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                            </PlainContainer>
                        </Card>
                    </PlainContainer>
                    <PlainContainer type="full">
                        <Card 
                            heading={"recent activities"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryActionLable={"see all recent activities"}
                        >
                            <PlainContainer type="full" styles={{marginTop: '5px', padding: '0px 25px'}}>
                                <BasicActivitiesList>
                                    <BasicActivityListItem 
                                        activityType="updated"
                                        activityBy="sharath"
                                        activityIcon={icons.image}
                                        activityPrimaryEntity="disclaimer"
                                        activitySecondaryEntity="defaults"
                                        activityTime={"monday, 10:50 am"}
                                        activityLink="/"
                                    />
                                    <BasicActivityListItem 
                                        activityType="updated"
                                        activityBy="sharath"
                                        activityIcon={icons.add}
                                        activityPrimaryEntity="disclaimer"
                                        activitySecondaryEntity="defaults"
                                        activityTime={"monday, 10:50 am"}
                                        activityLink="/"
                                    />
                                    <BasicActivityListItem 
                                        activityType="updated"
                                        activityBy="sharath"
                                        activityIcon={icons.add}
                                        activityPrimaryEntity="disclaimer"
                                        activitySecondaryEntity="defaults"
                                        activityTime={"monday, 10:50 am"}
                                        activityLink="/"
                                    />
                                    <BasicActivityListItem 
                                        activityType="updated"
                                        activityBy="sharath"
                                        activityIcon={icons.add}
                                        activityPrimaryEntity="disclaimer"
                                        activitySecondaryEntity="defaults"
                                        activityTime={"monday, 10:50 am"}
                                        activityLink="/"
                                    />
                                    <BasicActivityListItem 
                                        activityType="updated"
                                        activityBy="sharath"
                                        activityIcon={icons.add}
                                        activityPrimaryEntity="disclaimer"
                                        activitySecondaryEntity="defaults"
                                        activityTime={"monday, 10:50 am"}
                                        activityLink="/"
                                    />
                                </BasicActivitiesList>
                            </PlainContainer>
                        </Card>
                    </PlainContainer>
                </SplitContainer>
            </PlainContainer> */}
        </>
    )
}

export default WardenHomePage