import axios from "axios";
import { statusCodes, defaults } from "../../utils"

const config = (token) => {
    return (
        {
            headers: {
                'Authorization': token
            }
        }
    )
}

const saveTwitter = async (payload) => {

    if(!payload.entity_id){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Entity ID is required.",
        });

        return response
    }

    if(!payload.url){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "URL is required.",
        });

        return response
    }

    // if(!payload.password){

    //     const response = defaults.ServiceResponse({
    //         status: false,
    //         status_code: statusCodes.VALIDATION_FAILED,
    //         message: "password is required.",
    //     });

    //     return response
    // }

    // if(!payload.key){

    //     const response = defaults.ServiceResponse({
    //         status: false,
    //         status_code: statusCodes.VALIDATION_FAILED,
    //         message: "key is required.",
    //     });

    //     return response
    // }

    // if(!payload.keySecret){
    //     const response = defaults.ServiceResponse({
    //         status: false,
    //         status_code: statusCodes.VALIDATION_FAILED,
    //         message: "Key Secret required.",
    //     });

    //     return response
    // }

    // if(!payload.bearerToken){
    //     const response = defaults.ServiceResponse({
    //         status: false,
    //         status_code: statusCodes.VALIDATION_FAILED,
    //         message: "Bearer Token is required.",
    //     });

    //     return response
    // }
    
    // if(!payload.accessToken){
    //     const response = defaults.ServiceResponse({
    //         status: false,
    //         status_code: statusCodes.VALIDATION_FAILED,
    //         message: "Access Token is required.",
    //     });

    //     return response
    // }
    // if(!payload.accessTokenSecret){
    //     const response = defaults.ServiceResponse({
    //         status: false,
    //         status_code: statusCodes.VALIDATION_FAILED,
    //         message: "Access Token Secret is required.",
    //     });

    //     return response
    // }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    const data = {
        url: payload.url,
        api_key: payload.key,
        key_secret: payload.keySecret,
        bearer_token: payload.bearerToken,
        access_token: payload.accessToken,
        access_token_secret: payload.accessTokenSecret,
        password:payload.password
    }

    try{
        let headers = await config(payload.token);

        const addNewTwitterApiReq = await axios.patch(`${defaults.API_BASE_URL}organization/twitter/${payload.entity_id}`, data, headers);
        if(addNewTwitterApiReq.status === statusCodes.NOT_FOUND){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.NOT_FOUND,
                message: "Unabe to add Twitter Api  ."
            });
    
            return response
        }
        else if(addNewTwitterApiReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Twitter credentials successfully updated.",
                data: addNewTwitterApiReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}

const TwitterServices = {
    saveTwitter,
}
export default TwitterServices