import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DefaultHeader from '../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import DashboardHeaderSearchBar from '../../components/core/form/DashboardHeaderSearchBar';
import Button from '../../components/core/dashboard/Button';
import { icons } from '../../constants';
import PlainContainer from '../../components/core/containers/PlainContainer';
import Card from '../../components/core/dashboard/Card';
import Table from '../../components/core/table/containers/Table';
import TableHeading from '../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../components/core/table/containers/TableHeadingItem';
import TableCheckbox from '../../components/core/table/inputs/Checkbox';
import TableRows from '../../components/core/table/containers/TableRows';
import TableRow from '../../components/core/table/containers/TableRow';
import TableRowItem from '../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../components/core/table/containers/TableRowActions';
import TableButton from '../../components/core/table/inputs/Button';
import { globals, ToastAlert } from '../../utils';
import Pagination from '../../components/core/table/pagination';
import {  DDServices } from '../../services';

const DDTweetListPage = ({ user }) => {
    const navigate = useNavigate();

    const [tweets, setTweets] = useState([]);
    const [totalTweetsCount, setTotalTweetsCount] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [createdOn, setCreatedOn] = useState("RECENT");
   
    const [createdBy,setCreatedBy]=useState("ALL");

    const handleGetTweetsRequest = async () => {
        const getTweetsReq = await DDServices.getThisDDTweets({
            token: user.access_token,
        });

        if(getTweetsReq.status === false){
            ToastAlert.notifyError(getTweetsReq.message);
            setTweets([]);
            setTotalTweetsCount(0);
            setTotalPagesCount(0);
            setCurrentPage(1);
        }
        else{
            const Tweets = getTweetsReq.data.items.map(tweet => ({
                ...tweet,
                createdAtFormatted : moment(tweet.createdAt).format('MMMM Do YYYY, h:mm:ss A'),
            }));
            setTweets(Tweets);
            setTotalTweetsCount(getTweetsReq.data.total_items);
            setTotalPagesCount(getTweetsReq.data.total_pages);
            setCurrentPage(getTweetsReq.data?.current_page === undefined ? 1 : getTweetsReq.data.current_page);
        }
    }

    useEffect(() => {
        handleGetTweetsRequest();
    },[]);

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Tweets'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='List of All Tweets.'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate("add") }
                    >
                        Add dNew
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        {/* <PlainContainer type="full" styles={{paddingTop: "15px", paddingRight: "25px", paddingBottom: "25px", paddingLeft: "25px"}}>
                            <DistrictsListFilters
                            users={users}
                             states={states} 
                             state={state}
                             StateTypeChanged={setState}
                             createdOn={createdOn}
                             createdOnChanged={setCreatedOn} 
                             createdBy={createdBy}
                             createdByChanged={setCreatedOn}
                            />
                        </PlainContainer> */}
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    {/* <TableHeadingItem size={0} hasChildren={true}>
                                        <TableCheckbox name={"items-selected-ckb"} />
                                    </TableHeadingItem> */}
                                    <TableHeadingItem size={2} lable={"Organization"} />
                                    <TableHeadingItem size={1} lable={"Description"} />
                                    <TableHeadingItem size={2} lable={"Posted On"} />
                                    <TableHeadingItem size={1} lable={"created by"} />
                                    {/* <TableHeadingItem size={1} lable={"no. of cities"} /> */}
                                    {/* <TableHeadingItem size={0} lable={""} />
                                    <TableHeadingItem size={2} lable={"Actions"} /> */}
                                </TableHeading>
                                <TableRows>
                                    {
                                        tweets.map((tweet, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    {/* <TableRowItem size={0} hasChildren={true} isCenter={false}>
                                                        <TableCheckbox name={`items-selected-ckb-${1}`} />
                                                    </TableRowItem> */}
                                                    <TableRowItem size={2} isCenter={false} hasPreview={true} previewType={"image"} previewItem={`${globals.API_BASE_URL}${tweet?.image?.image_url?.full?.high_res}`} lable={tweet?.content} />
                                                    <TableRowItem size={1} isCenter={false} lable={tweet?.district?.name?.en} />
                                                    <TableRowItem size={2} isCenter={false} lable={tweet?.createdAtFormatted}  />
                                                    {/* <TableRowItem size={1} isCenter={false} lable={`${school?.created_by.first_name} (${school?.created_by?.role === "SUPER_ADMIN" ? "Super Admin" : "Admin" })`} /> */}
                                                    <TableRowItem size={1} isCenter={false} lable={`${tweet?.created_by?.first_name} ${tweet?.created_by?.last_name}`} />
                                                    {/* <TableRowItem size={0} isCenter={true} hasChildren={true}>
                                                        <TableRowIcons>
                                                            <TableRowIcon icon={icons.eye} color={"dark"} action={() => navigate(`${school.id}`)} />
                                                        </TableRowIcons>
                                                    </TableRowItem> */}
                                                    {/* <TableRowItem size={2} isCenter={false} hasChildren={true}>
                                                        <TableRowActions>
                                                            <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => navigate(`edit/${school?.id}`)}>edit</TableButton>
                                                            <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => console.log(school.id)}>delete</TableButton>
                                                        </TableRowActions>
                                                    </TableRowItem> */}
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                        {
                            totalPagesCount > 1
                            ?
                                <PlainContainer type='full' styles={{ padding: "0px 25px" }}>
                                    <Pagination totalPages={totalPagesCount} setCurrentPage={setCurrentPage}/>
                                </PlainContainer>
                            :
                                <></>
                        }
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default DDTweetListPage