import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../constants';
import TextInputRT from '../../../core/form/TextInputRT';
import TextAreaInput from '../../../core/form/TextAreaInput';
import FileUploadInput from '../../../core/form/FileUploadInput';
import Form from '../../../form/Form';
import Button from '../../../core/dashboard/Button';
import FormActions from '../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../utils';
import SplitContainer from '../../../core/containers/SplitContainer';
import PlainContainer from '../../../core/containers/PlainContainer';
import {ContactUsPageServices, TwitterServices } from '../../../../services';
import { WardenServices } from '../../../../services';
import PasswordInputRT from '../../../core/form/PasswordInputRT';

const AddTwitterApiPopUp = ({ user, twitter, concludeAddTwitterApiPopUp }) => {

    // console.log(twitter);

    const [url, setURL] = useState(twitter === null ? 0 : twitter?.twitter?.url);
    const [hasURLMessage, setHasURLMessage] = useState(false);
    const [urlMessage, setURLMessage] = useState("");
    const [urlMessageType, setURLMessageType] = useState("");

    const [key, setKey] = useState(twitter === null ? 0 : twitter?.twitter?.api_key);
    const [hasKeyMessage, setHasKeyMessage] = useState(false);
    const [keyMessage, setKeyMessage] = useState("");
    const [keyMessageType, setKeyMessageType] = useState("");

    const [keySecret, setKeySecret] = useState(twitter === null ? 0 : twitter?.twitter?.api_key_secret);
    const [hasKeySecretMessage, setHasKeySecretMessage] = useState(false);
    const [keySecretMessage, setKeySecretMessage] = useState("");
    const [keySecretMessageType, setKeySecretMessageType] = useState("");

    const [bearerToken, setBearerToken] = useState(twitter === null ? 0 : twitter?.twitter?.bearer_token);
    const [hasBearerTokenMessage, setHasBearerTokenMessage] = useState(false);
    const [bearerTokenMessage, setBearerTokenMessage] = useState("");
    const [bearerTokenMessageType, setBearerTokenMessageType] = useState("");

    const [accessToken, setAccessToken] = useState(twitter === null ? 0 : twitter?.twitter?.access_token);
    const [hasAccessTokenMessage, setHasAccessTokenMessage] = useState(false);
    const [accessTokenMessage, setAccessTokenMessage] = useState("");
    const [accessTokenMessageType, setAccessTokenMessageType] = useState("");

    const [acessTokenSecret, setAcessTokenSecret] = useState(twitter === null ? 0 : twitter?.twitter?.access_token_secret);
    const [hasAcessTokenSecretMessage, setHasAcessTokenSecretMessage] = useState(false);
    const [acessTokenSecretMessage, setAcessTokenSecretMessage] = useState("");
    const [acessTokenSecretMessageType, setAcessTokenSecretMessageType] = useState("");

    const [password, setPassword] = useState(twitter === null ? 0 : twitter?.twitter?.password);
    const [hasPasswordMessage, setHasPasswordSecretMessage] = useState(false);
    const [passwordMessage, setPasswordSecretMessage] = useState("");
    const [passwordMessageType, setPasswordMessageType] = useState("");

    
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);


    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleAddTwitterApiSubmission = async (e) => {
        e.preventDefault();

        // if(key.trim() === "" || keySecret.trim()==="" || bearerToken.trim()==="" || accessToken.trim()==="" || acessTokenSecret.trim()==="" ){

        if(url === ""){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveTwitterApiReq = await TwitterServices.saveTwitter({
                entity_id: twitter.id,
                url: url,
                key: key,
                keySecret: keySecret,
                bearerToken: bearerToken,
                accessToken: accessToken,
                accessTokenSecret: acessTokenSecret,
                password:password,
                token: user.access_token
            });

            if(saveTwitterApiReq.status === false){
                ToastAlert.notifyError(saveTwitterApiReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(saveTwitterApiReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeAddTwitterApiPopUp({
                    status: true
                })
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleAddTwitterApiSubmission(e)} noValidate>
                <div className="cmsForm">

                    <TextInputRT 
                        hasLable={true}
                        lable={"URL"}
                        isMandatory={true}
                        hasMessage={hasURLMessage}
                        message={urlMessage}
                        messageType={urlMessageType}
                        value={url}
                    >
                        <input 
                            type={"text"} 
                            className="cmsFormStepInputText" 
                            placeholder={"enter URL"}
                            onChange={(e) => setURL(e.target.value)}
                            value={url}
                        />
                    </TextInputRT>
                    <PasswordInputRT
                     hasLable={true}
                     lable={"enter Password"}
                     isMandatory={false}
                     hasMessage={hasPasswordMessage}
                     message={passwordMessage}
                     messageType={passwordMessageType}
                     value={password}
                     >
                        <input 
                            type={showRepeatPassword ? 'text' : 'password'} 
                            className="cmsFormStepInputText" 
                            placeholder={"enter Password"}
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                        />
                        {
                                    showRepeatPassword === false
                                    ?
                                    <div className="cmsFormStepInputIcon">
                                        <i className={`fa-solid fa-eye cmsFormStepInputIco clickable`} onClick={() => setShowRepeatPassword(true)}></i>
                                    </div>
                                    :
                                    <div className="cmsFormStepInputIcon">
                                        <i className={`fa-solid fa-eye-slash cmsFormStepInputIco clickable`} onClick={() => setShowRepeatPassword(false)}></i>
                                    </div>
                                }
                    </PasswordInputRT>
                   <TextInputRT 
                        hasLable={true}
                        lable={"Key "}
                        isMandatory={false}
                        hasMessage={hasKeyMessage}
                        message={keyMessage}
                        messageType={keyMessageType}
                        value={key}
                    >
                        <input 
                            type={"text"} 
                            className="cmsFormStepInputText" 
                            placeholder={"enter Key Secret"}
                            onChange={(e) => setKey(e.target.value)}
                            value={key}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={true}
                        lable={"enter Key Secret"}
                        isMandatory={false}
                        hasMessage={hasKeySecretMessage}
                        message={keySecretMessage}
                        messageType={keySecretMessageType}
                        value={keySecret}
                    >
                        <input 
                            type={"text"} 
                            className="cmsFormStepInputText" 
                            placeholder={"enter Key Secret"}
                            onChange={(e) => setKeySecret(e.target.value)}
                            value={keySecret}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={true}
                        lable={"enter Bearer Token"}
                        isMandatory={false}
                        hasMessage={hasBearerTokenMessage}
                        message={bearerTokenMessage}
                        messageType={bearerTokenMessageType}
                        value={bearerToken}
                    >
                        <input 
                            type={"text"} 
                            className="cmsFormStepInputText" 
                            placeholder={"enter Bearer Token"}
                            onChange={(e) => setBearerToken(e.target.value)}
                            value={bearerToken}
                        />
                    </TextInputRT>
                    <TextInputRT 
                        hasLable={true}
                        lable={"enter Access Token"}
                        isMandatory={false}
                        hasMessage={hasAccessTokenMessage}
                        message={accessTokenMessage}
                        messageType={accessTokenMessageType}
                        value={accessToken}
                    >
                        <input 
                            type={"text"} 
                            className="cmsFormStepInputText" 
                            placeholder={"enter  Access Token"}
                            onChange={(e) => setAccessToken(e.target.value)}
                            value={accessToken}
                        />
                    </TextInputRT>
                    <TextInputRT 
                        hasLable={true}
                        lable={"enter Access Token Secret"}
                        isMandatory={false}
                        hasMessage={hasAcessTokenSecretMessage}
                        message={acessTokenSecretMessage}
                        messageType={acessTokenSecretMessageType}
                        value={acessTokenSecret}
                    >
                        <input 
                            type={"text"} 
                            className="cmsFormStepInputText" 
                            placeholder={"enter  Access Token Secret"}
                            onChange={(e) => setAcessTokenSecret(e.target.value)}
                            value={acessTokenSecret}
                        />
                    </TextInputRT>
                   
                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeAddTwitterApiPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )

}

export default AddTwitterApiPopUp