import React, { useEffect } from 'react';
import ContentSidebarFooter from './ContentSidebarFooter';
import ContentContainer from './ContentContainer';
import WardenLayoutNavBar from './Navbar';
import WardenLayoutHeader from './Header';
import WardenLayoutFooter from './Footer';

const WardenLayout = ({ user, children }) => {
    return (
        <div className="dashboardPage">
            <WardenLayoutNavBar user={user} />
            <ContentSidebarFooter>
                <ContentContainer>
                    <WardenLayoutHeader user={user} />
                    {children}
                </ContentContainer>
                <WardenLayoutFooter />
            </ContentSidebarFooter>
        </div>
    )
}

export default WardenLayout