const home = 'fa-solid fa-house';
const user = 'fa-regular fa-user';
const userExec = 'fa-solid fa-user-astronaut';
const userAdmin = 'fa-solid fa-user-tag';
const userSolid = 'fa-solid fa-user';
const users = 'fa-solid fa-user-group';
const email = 'fa-regular fa-envelope-open';
const emailSolid = 'fa-solid fa-envelope-open';
const simpleEmail = 'fa-regular fa-envelope';
const simpleEmailSolid = 'fa-solid fa-envelope';
const emailStyled = 'fa-solid fa-envelope-open-text';
const at = 'fa-solid fa-at';
const phone = 'fa-solid fa-phone';
const phoneRight = 'fa-solid fa-phone-flip';
const phoneCall = 'fa-solid fa-phone-volume';
const noPhone = 'fa-solid fa-phone-slash';
const headphone = 'fa-solid fa-headphones';
const headphoneStyled = 'fa-solid fa-headphones-simple';
const mic = 'fa-solid fa-microphone';
const noMic = 'fa-solid fa-microphone-slash';
const micStyled = 'fa-solid fa-microphone-lines';
const noMicStyled = 'fa-solid fa-microphone-lines-slash';
const mobile = 'fa-solid fa-mobile';
const mobileAndroid = 'fa-solid fa-mobile-screen';
const iphone = 'fa-solid fa-mobile-screen-button';
const search = 'fa-solid fa-magnifying-glass';
const tick = 'fa-solid fa-check';
const tickCircle = 'fa-regular fa-circle-check';
const tickCircleSolid = 'fa-solid fa-circle-check';
const tickBordered = 'fa-regular fa-square-check';
const wrong = 'fa-solid fa-xmark';
const wrongCircle = 'fa-regular fa-circle-xmark';
const wrongCircleSolid = 'fa-solid fa-circle-xmark';
const wrongBordered = 'fa-solid fa-square-xmark';
const dowload = 'fa-solid fa-download';
const image = 'fa-regular fa-image';
const imageSolid = 'fa-solid fa-image';
const like = 'fa-regular fa-thumbs-up';
const likeSolid = 'fa-solid fa-thumbs-up';
const dislike = 'fa-regular fa-thumbs-down';
const dislikeSolid = 'fa-solid fa-thumbs-down';
const love = 'fa-regular fa-heart';
const loveSolid = 'fa-solid fa-heart';
const happy = 'fa-regular fa-face-smile';
const happySolid = 'fa-solid fa-face-smile';
const sad = 'fa-regular fa-face-frown-open';
const sadSolid = 'fa-solid fa-face-frown-open';
const refresh = 'fa-solid fa-rotate';
const menu = 'fa-solid fa-bars';
const add = 'fa-solid fa-plus';
const addSolid = 'fa-solid fa-square-plus';
const addSolidCircle = 'fa-solid fa-circle-plus';
const addBordered = 'fa-regular fa-square-plus';
const addCart = 'fa-solid fa-cart-plus';
const addFolder = 'fa-solid fa-folder-plus';
const addDate = 'fa-regular fa-calendar-plus';
const addDateSolid = 'fa-solid fa-calendar-plus';
const images = 'fa-regular fa-images';
const imagesSolid = 'fa-solid fa-images';
const camera = 'fa-solid fa-camera';
const cameraRetro = 'fa-solid fa-camera-retro';
const panorama = 'fa-solid fa-panorama';
const imageFile = 'fa-regular fa-file-image';
const imageFileSolid = 'fa-solid fa-file-image';
const imageIDLong = 'fa-regular fa-id-badge';
const imageIDLongSolid = 'fa-solid fa-id-badge';
const imageIDWide = 'fa-regular fa-id-card';
const imageIDWideSolid = 'fa-solid fa-id-card';
const imageLong = 'fa-solid fa-image-portrait';
const film = 'fa-solid fa-film';
const imageVideo = 'fa-solid fa-photo-film';
const clapboard = 'fa-solid fa-clapperboard';
const videoCamera = 'fa-solid fa-video';
const videoFile = 'fa-regular fa-file-video';
const videoFileSolid = 'fa-solid fa-file-video';
const youtube = 'fa-brands fa-youtube';
const play = 'fa-solid fa-play';
const playRound = 'fa-regular fa-circle-play';
const playRoundSolid = 'fa-solid fa-circle-play';
const fileBasic = 'fa-regular fa-file';
const fileBasicSolid = 'fa-solid fa-file';
const fileLines = 'fa-regular fa-file-lines';
const fileLinesSolid = 'fa-solid fa-file-lines';
const book = 'fa-solid fa-book';
const filePDF = 'fa-regular fa-file-pdf';
const filePDFSolid = 'fa-solid fa-file-pdf';
const fileWord = 'fa-regular fa-file-word';
const fileWordSolid = 'fa-solid fa-file-word';
const fileExcel = 'fa-regular fa-file-excel';
const fileExcelSolid = 'fa-solid fa-file-word';
const filePowePoint = 'fa-regular fa-file-powerpoint';
const filePowePointSolid = 'fa-solid fa-file-powerpoint';
const fileCSV = 'fa-solid fa-file-csv';
const addFile = 'fa-solid fa-file-circle-plus';
const removeFile = 'fa-solid fa-file-circle-minus';
const fileError = 'fa-solid fa-file-circle-xmark';
const fileSuccess = 'fa-solid fa-file-circle-check';
const fileWarning = 'fa-solid fa-file-circle-exclamation';
const fileQuestion = 'fa-solid fa-file-circle-question';
const star = 'fa-regular fa-star';
const starSolid = 'fa-solid fa-star';
const starHalfSolidBorder = 'fa-solid fa-star-half-stroke';
const starHalfSolid = 'fa-solid fa-star-half';
const starHalfBorder = 'fa-regular fa-star-half';
const bell = 'fa-regular fa-bell';
const bellSolid = 'fa-solid fa-bell';
const graphHigh = 'fa-solid fa-arrow-up-right-dots';
const bookOpenSolid = 'fa-solid fa-book-open';
const bookOpenRead = 'fa-solid fa-book-open-reader';
const website = 'fa-solid fa-globe';
const settingsGear = 'fa-solid fa-gear';
const userProfessional = 'fa-solid fa-user-tie';
const sitemap = 'fa-solid fa-sitemap';
const govtBuilding = 'fa-solid fa-landmark';
const bars = 'fa-solid fa-bars-progress';
const link = 'fa-solid fa-link';
const html = 'fa-brands fa-html5';
const window = 'fa-regular fa-window-maximize';
const facebookSolid = 'fa-brands fa-square-facebook';
const calenderDays = 'fa-regular fa-calendar-days';
const phoneRinging = 'fa-solid fa-phone-volume';
const eye = 'fa-solid fa-eye';
const language = 'fa-solid fa-language';
const display = 'fa-solid fa-display';
const weather = 'fa-solid fa-cloud-sun-rain';
const dotsLong = 'fa-solid fa-ellipsis-vertical';
const rightArrow = 'fa-solid fa-angle-right';
const leftArrow = 'fa-solid fa-angle-left';
const downArrow = 'fa-solid fa-angle-down';
const upArrow = 'fa-solid fa-angle-up';
const rightArrowTail = 'fa-solid fa-arrow-right';
const leftArrowTail = 'fa-solid fa-arrow-left';
const downArrowTail = 'fa-solid fa-arrow-down';
const upArrowTail = 'fa-solid fa-arrow-up';
const clock = 'fa-regular fa-clock';
const clockSolid = 'fa-solid fa-clock';
const list = 'fa-solid fa-list-ul';
const listCheck = 'fa-solid fa-list-check';
const grid = 'fa-solid fa-border-all';
const warningCircle = 'fa-solid fa-circle-exclamation';
const warningSolid = 'fa-solid fa-triangle-exclamation';
const warningPlain = 'fa-solid fa-exclamation';
const folderOpen = 'fa-regular fa-folder-open';
const removePlain = 'fa-regular fa-trash-can';
const removePlainSolid = 'fa-solid fa-trash-can';
const removeSolid = 'fa-solid fa-trash';
const removeSolidUp = 'fa-solid fa-trash-can-arrow-up';
const removeSolidStyledUp = 'fa-solid fa-trash-arrow-up';
const signOut = 'fa-solid fa-arrow-right-from-bracket';
const signOutSolid = 'fa-solid fa-right-from-bracket';
const crop = 'fa-solid fa-crop';
const upload = 'fa-solid fa-upload';
const music = 'fa-solid fa-music';
const rotateRight = 'fa-solid fa-rotate-right';
const rotateLeft = 'fa-solid fa-rotate-left';
const ban = 'fa-solid fa-ban';
const pencilSolid = 'fa-solid fa-pen-to-square';
const pencil = 'fa-regular fa-pen-to-square';
const pencilBordered = 'fa-solid fa-square-pen';
const pencilPlain = 'fa-solid fa-pencil';
const fileEdit = 'fa-solid fa-file-pen';
const mapRealistic =  'fa-solid fa-signs-post';
const fileCheck = 'fa-solid fa-clipboard-check';
const percentage = 'fa-solid fa-percent';
const signal = 'fa-solid fa-signal';
const commentTypingSolid = 'fa-solid fa-comment-dots';
const bug = 'fa-solid fa-bug';
const rocket = 'fa-solid fa-rocket';
const colorPalette = 'fa-solid fa-palette';
const font = 'fa-solid fa-font';
const copy = 'fa-regular fa-copy';
const twitter = 'fa-brands fa-twitter';
const buildingWarning = 'fa-solid fa-building-circle-exclamation';

export default {
    home,
    user,
    userExec,
    userAdmin,
    userSolid,
    users,
    email,
    emailSolid,
    simpleEmail,
    simpleEmailSolid,
    emailStyled,
    at,
    phone,
    phoneRight,
    phoneCall,
    noPhone,
    headphone,
    headphoneStyled,
    mic,
    noMic,
    micStyled,
    noMicStyled,
    mobile,
    mobileAndroid,
    iphone,
    search,
    tick,
    tickCircle,
    tickCircleSolid,
    tickBordered,
    wrong,
    wrongCircle,
    wrongCircleSolid,
    wrongBordered,
    dowload,
    image,
    imageSolid,
    like,
    likeSolid,
    dislike,
    dislikeSolid,
    love,
    loveSolid,
    happy,
    happySolid,
    sad,
    sadSolid,
    refresh,
    menu,
    add,
    addSolid,
    addSolidCircle,
    addBordered,
    addCart,
    addFolder,
    addDate,
    addDateSolid,
    images,
    imagesSolid,
    camera,
    cameraRetro,
    panorama,
    imageFile,
    imageFileSolid,
    imageIDLong,
    imageIDLongSolid,
    imageIDWide,
    imageIDWideSolid,
    imageLong,
    film,
    imageVideo,
    clapboard,
    videoCamera,
    videoFile,
    videoFileSolid,
    youtube,
    play,
    playRound,
    playRoundSolid,
    fileBasic,
    fileBasicSolid,
    fileLines,
    fileLinesSolid,
    book,
    filePDF,
    filePDFSolid,
    fileWord,
    fileWordSolid,
    fileExcel,
    fileExcelSolid,
    filePowePoint,
    filePowePointSolid,
    fileCSV,
    addFile,
    removeFile,
    fileError,
    fileSuccess,
    fileWarning,
    fileQuestion,
    star,
    starSolid,
    starHalfSolidBorder,
    starHalfSolid,
    starHalfBorder,
    bell,
    bellSolid,
    graphHigh,
    bookOpenSolid,
    bookOpenRead,
    website,
    settingsGear,
    userProfessional,
    sitemap,
    govtBuilding,
    bars,
    link,
    html,
    window,
    facebookSolid,
    calenderDays,
    phoneRinging,
    eye,
    language,
    display,
    weather,
    dotsLong,
    rightArrow,
    leftArrow,
    downArrow,
    upArrow,
    rightArrowTail,
    leftArrowTail,
    downArrowTail,
    upArrowTail,
    clock,
    clockSolid,
    list,
    listCheck,
    grid,
    warningCircle,
    warningSolid,
    warningPlain,
    folderOpen,
    removePlain,
    removePlainSolid,
    removeSolid,
    removeSolidStyledUp,
    removeSolidUp,
    signOut,
    signOutSolid,
    crop,
    upload,
    music,
    rotateRight,
    rotateLeft,
    ban,
    pencilSolid,
    pencil,
    pencilBordered,
    pencilPlain,
    fileEdit,
    mapRealistic,
    fileCheck,
    percentage,
    signal,
    commentTypingSolid,
    bug,
    rocket,
    colorPalette,
    font,
    copy,
    twitter,
    buildingWarning
}