import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { 
    LoginPage, 
    ForgotPasswordPage, 
    ChangePasswordPage, 
    ForgotPasswordNeedsApprovalPage, 
    VerifyForgotPasswordOTPPage, 
    ForgotPasswordOTPExpiredPage, 
    PasswordChangeSuccessPage, 
    ScanToEnable2FAPage, 
    VerifyEnable2FAOTPPage, 
    Verify2FAOTPPage, 
    RegisterSuperAdminPage, 
    ValidateEmailIDPage, 
    ValidatePhoneNumberPage, 
    ForgotPasswordReqAlreadyExistsPage, 
    ForgotPasswordRecentlyRejectedPage ,
    SuperAdminHomePage,
    AdminHomePage,
    CreatorHomePage,
    ModeratorHomePage,
    ApproverHomePage,
    ResetPasswordRequestWithdrawnPage,
    SuperAdminFileManagerPage,
    SuperAdminAddFilesPage,
    SuperAdminAddAdminUserPage,
    SuperAdminAdminUsersPage,
    SuperAdminAvatarsListPage,
    SuperAdminAddAvatarPage,
    SuperAdminEditAvatarPage,
    DistrictsListPage,
    AddDistrictPage,
    MediaTypesListPage,
    AddMediaTypePage,
    PagesListPage,
    AddPagesPage,
    TaluksListPage,
    AddTalukPage,
    SchoolsListPage,
    AddSchoolPage,
    HostelsListPage,
    AddHostelPage,
    SchoolDetailsPage,
    HostelDetailsPage,
    EditSchoolPage,
    EditHostelPage,
    EditDistrictPage,
    EditTalukPage,
    EntityListPage,
    AddEntityPage,
    EntityDetailsPage,
    EditEntityPage,
    AddTweetPage,
    TweetsListPage,
    DDTweetListPage,
    DDTaluksListPage,
    AddDDEntityPage,
    DDEntityListPage,
    DDEntityDetailsPage,
    EditDDEntityPage,
    SuperAdminDDUsersPage,
    SuperAdminAddDDUserPage,
    AddDDTalukPage,
    HelplineHomePage,
    SuperAdminHelplineUsersPage,
    SuperAdminAddHelplineUserPage,
    WardenHomePage,
    DDHomePage,
    DDDistrictDetailsPage,
    EditDDTalukPage,
    MealPostingReportDDBreakfastPage,
    MealPostingReportDDDinnerPage,
    MealPostingReportDDLunchPage,
} from '../pages';
import PreLoginOutlet from './LoginOutlet';
import { defaults, verifyToken } from '../utils';
import DefaultOutlet from './DefaultOutlet';
import DashboardOutlet from './DashboardOutlet';
import HelplineIncompleteInstitutionsPage from '../pages/Report/Helpline/IncompleteInstitutions';
import EntityWithNoWardenListHelplinePage from '../pages/Report/Helpline/IncompleteInstitutions/NoWarden';
import EntityWithNoTwitterIDListHelplinePage from '../pages/Report/Helpline/IncompleteInstitutions/NoTwitterID';
import EntityWithNoTwitterPasswordListHelplinePage from '../pages/Report/Helpline/IncompleteInstitutions/NoTwitterPassword';
import DDIncompleteInstitutionsPage from '../pages/Report/DD/IncompleteInstitutions';
import EntityWithNoWardenListDDPage from '../pages/Report/DD/IncompleteInstitutions/NoWarden';
import EntityWithNoTwitterIDListDDPage from '../pages/Report/DD/IncompleteInstitutions/NoTwitterID';
import EntityWithNoTwitterPasswordListDDPage from '../pages/Report/DD/IncompleteInstitutions/NoTwitterPassword';

const AppRouter = ({user}) => {
    // console.log(user)

    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [isValidated, setIsValidated] = useState(false);
    const [toBeValidated, setToBeValidated] = useState("");
    const [is2FAEnabled, setIs2FAEnabled] = useState(false);
    
    const isSuperAdmin = defaults.ROLES.SUPER_ADMIN === user.role;
    const isAdmin = defaults.ROLES.ADMIN === user.role;
    const isCreator = defaults.ROLES.CREATOR === user.role;
    const isModerator = defaults.ROLES.MODERATOR === user.role;
    const isApprover = defaults.ROLES.APPROVER === user.role;
    const isWarden =  defaults.ROLES.WARDEN === user.role;
    const isDD = defaults.ROLES.DD === user.role;
    const isHelpline = defaults.ROLES.HELPLINE === user.role;
  
    useEffect(() => {
        checkIsLoggedIn();
    }, [user]);

    const checkIsLoggedIn = async () => {
        if(user.is_logged_in === true){
            const checkToken = await verifyToken(user.access_token);
            setIsLoggedIn(checkToken.status);
            setIsValidated(user.is_validated);
            setToBeValidated(user.to_be_validated);
            setIs2FAEnabled(user.is_2fa_enabled);
        }
        else{
            setIsLoggedIn(false);
            setIsValidated(user.is_validated);
            setToBeValidated(user.to_be_validated);
            setIs2FAEnabled(user.is_2fa_enabled);
        }
    }

    return (
        <>
            {
                isLoggedIn != null
                ?
                    <Routes>
                        {
                            isLoggedIn === false
                            ?
                            <Route path="/" element={<PreLoginOutlet user={user} />}>
                                <Route index element={<LoginPage />} />
                                <Route path="forgot-password" element={
                                    user.forgot_password_current_route === "FORGOT_PASSWORD" 
                                    ?
                                    <ForgotPasswordPage user={user} />
                                    :
                                    user.forgot_password_current_route === "CHANGE_PASSWORD" 
                                    ?
                                    <ChangePasswordPage user={user} />
                                    :
                                    user.forgot_password_current_route === "ALREADY_EXIST"
                                    ?
                                    <ForgotPasswordReqAlreadyExistsPage user={user} />
                                    :
                                    user.forgot_password_current_route === "VALIDATE_OTP"
                                    ?
                                    <VerifyForgotPasswordOTPPage user={user} />
                                    :
                                    user.forgot_password_current_route === "REQUEST_WITHDRAWN"
                                    ?
                                    <ResetPasswordRequestWithdrawnPage user={user} />
                                    :
                                    user.forgot_password_current_route === "TRY_LATER"
                                    ?
                                    <ForgotPasswordRecentlyRejectedPage user={user} />
                                    :
                                    user.forgot_password_current_route === "REQUIRES_APPROVAL"
                                    ?
                                    <ForgotPasswordNeedsApprovalPage user={user} />
                                    :
                                    <ForgotPasswordPage user={user} />
                                } />
                                <Route path="password-reset-expired-otp" element={<ForgotPasswordOTPExpiredPage />} />
                                <Route path="password-change-success" element={<PasswordChangeSuccessPage />} />
                                <Route path="register" element={<RegisterSuperAdminPage />} />
                            </Route>
                            :
                            isLoggedIn === true && isValidated === false
                            ?
                                toBeValidated === "email"
                                ?
                                    <Route path="/" element={<PreLoginOutlet user={user} />}>
                                        <Route index element={<ValidateEmailIDPage user={user} />} />
                                    </Route>
                                :
                                toBeValidated === "phone"
                                ?
                                    <Route path="/" element={<PreLoginOutlet user={user} />}>
                                        <Route index element={<ValidatePhoneNumberPage user={user} />} />
                                    </Route>
                                :
                                toBeValidated === "2fa"
                                ?
                                    is2FAEnabled === true
                                    ?
                                        <Route path="/" element={<PreLoginOutlet user={user} />}>
                                            <Route index element={<Verify2FAOTPPage user={user} />} />
                                            <Route path="/enable-2fa" element={<ScanToEnable2FAPage user={user} />} />
                                        </Route>
                                    :
                                        <Route path="/" element={<PreLoginOutlet user={user} />}>
                                            <Route index element={<ScanToEnable2FAPage user={user} />} />
                                            <Route path="/verify-enable-2fa-otp" element={<VerifyEnable2FAOTPPage user={user} />} />
                                        </Route>
                                :
                                <></>
                            :
                            isLoggedIn === true && isValidated === true
                            ?
                                <>
                                    <Route path="/" element={<Navigate to={"/dashboard/home"} />} />
                                    <Route path="/dashboard" element={<DashboardOutlet user={user} />}>
                                        <Route index element={<Navigate to={"/dashboard/home"} />} />
                                        <Route path={"home"} element={
                                            isSuperAdmin ? <SuperAdminHomePage user={user} /> : 
                                            isWarden ? < WardenHomePage user={user}/> :
                                            isAdmin ? <AdminHomePage /> : 
                                            isCreator ? <CreatorHomePage /> : 
                                            isModerator ? <ModeratorHomePage /> : 
                                            isApprover ? <ApproverHomePage /> : 
                                            isDD ? < DDHomePage  user={user}/> :
                                            isHelpline ? <HelplineHomePage user={user}/> :
                                            <Navigate to="/404" /> } 
                                        />
                                    </Route>
                                    <Route path="/general" element={<DashboardOutlet user={user} />}>
                                        <Route path={"file-manager"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <SuperAdminFileManagerPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"upload"} element={
                                                isSuperAdmin ? <SuperAdminAddFilesPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                        <Route path={"users"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <Navigate to={"/general/users/admins"} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"admins"} element={<DefaultOutlet />}>
                                                <Route index element={
                                                    isSuperAdmin ? <SuperAdminAdminUsersPage user={user} /> : 
                                                    // isAdmin ? <AdminHomePage /> : 
                                                    // isCreator ? <CreatorHomePage /> : 
                                                    // isModerator ? <ModeratorHomePage /> : 
                                                    // isApprover ? <ApproverHomePage /> : 
                                                    <Navigate to="/404" /> } 
                                                />
                                                <Route path={"add"} element={
                                                    isSuperAdmin ? <SuperAdminAddAdminUserPage user={user} /> : 
                                                    // isAdmin ? <AdminHomePage /> : 
                                                    // isCreator ? <CreatorHomePage /> : 
                                                    // isModerator ? <ModeratorHomePage /> : 
                                                    // isApprover ? <ApproverHomePage /> : 
                                                    <Navigate to="/404" /> } 
                                                />
                                            </Route>
                                            <Route path={"dd"} element={<DefaultOutlet />}>
                                                <Route index element={
                                                    isSuperAdmin ? <SuperAdminDDUsersPage user={user} /> : 
                                                    // isAdmin ? <AdminHomePage /> : 
                                                    // isCreator ? <CreatorHomePage /> : 
                                                    // isModerator ? <ModeratorHomePage /> : 
                                                    // isApprover ? <ApproverHomePage /> : 
                                                    <Navigate to="/404" /> } 
                                                />
                                                <Route path={"add"} element={
                                                    isSuperAdmin ? <SuperAdminAddDDUserPage user={user} /> : 
                                                    // isAdmin ? <AdminHomePage /> : 
                                                    // isCreator ? <CreatorHomePage /> : 
                                                    // isModerator ? <ModeratorHomePage /> : 
                                                    // isApprover ? <ApproverHomePage /> : 
                                                    <Navigate to="/404" /> } 
                                                />
                                            </Route>
                                            <Route path={"helpline"} element={<DefaultOutlet />}>
                                                <Route index element={
                                                    isSuperAdmin ? <SuperAdminHelplineUsersPage user={user} /> : 
                                                    // isAdmin ? <AdminHomePage /> : 
                                                    // isCreator ? <CreatorHomePage /> : 
                                                    // isModerator ? <ModeratorHomePage /> : 
                                                    // isApprover ? <ApproverHomePage /> : 
                                                    <Navigate to="/404" /> } 
                                                />
                                                <Route path={"add"} element={
                                                    isSuperAdmin ? <SuperAdminAddHelplineUserPage user={user} /> : 
                                                    // isAdmin ? <AdminHomePage /> : 
                                                    // isCreator ? <CreatorHomePage /> : 
                                                    // isModerator ? <ModeratorHomePage /> : 
                                                    // isApprover ? <ApproverHomePage /> : 
                                                    <Navigate to="/404" /> } 
                                                />
                                            </Route>

                                        </Route>
                                        <Route path={"pages"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <PagesListPage user={user} /> : 
                                                isAdmin ? <PagesListPage user={user} /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"add"} element={
                                                isSuperAdmin ? <AddPagesPage user={user} /> : 
                                                isAdmin ? <AddPagesPage user={user} /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                    </Route>
                                    <Route path={"masterdata"} element={<DashboardOutlet user={user} />}>
                                        <Route index element={
                                            isSuperAdmin ? <Navigate to={"/dashboard/home"} /> : 
                                            // isAdmin ? <AdminHomePage /> : 
                                            // isCreator ? <CreatorHomePage /> : 
                                            // isModerator ? <ModeratorHomePage /> : 
                                            // isApprover ? <ApproverHomePage /> : 
                                            <Navigate to="/404" /> } 
                                        />
                                        <Route path={"media-types"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <MediaTypesListPage user={user} /> : 
                                                isAdmin ? <MediaTypesListPage user={user} /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"add"} element={
                                                isSuperAdmin ? <AddMediaTypePage user={user} /> : 
                                                isAdmin ? <AddMediaTypePage user={user} /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                        <Route path={"avatars"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <SuperAdminAvatarsListPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"add"} element={
                                                isSuperAdmin ? <SuperAdminAddAvatarPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"edit:id"} element={
                                                isSuperAdmin ? <SuperAdminEditAvatarPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                        <Route path={"taluk"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isDD ? <DDTaluksListPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"add"} element={
                                                isDD ? <AddDDTalukPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"edit/:id"} element={
                                                isDD ? <EditDDTalukPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            
                                        </Route>
                                        <Route path={"locations"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <Navigate to={"/masterdata/locations/states"} /> : 
                                                isAdmin ? <Navigate to={"/masterdata/locations/states"} /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"districts"} element={<DefaultOutlet />}>
                                                <Route index element={
                                                    isSuperAdmin ? <DistrictsListPage user={user} /> : 
                                                    isAdmin ? <DistrictsListPage user={user} /> :
                                                    isHelpline ?<DistrictsListPage user={user} /> : 
                                                    <Navigate to="/404" /> } 
                                                />
                                                <Route path={"add"} element={
                                                    isSuperAdmin ? <AddDistrictPage user={user} /> : 
                                                    isAdmin ? <AddDistrictPage user={user} /> : 
                                                    isHelpline ? <AddDistrictPage user={user} /> : 
                                                    <Navigate to="/404" /> } 
                                                />
                                                <Route path={"edit/:id"} element={
                                                    isSuperAdmin ? <EditDistrictPage user={user} /> : 
                                                    isAdmin ? <EditDistrictPage user={user} /> :
                                                    isHelpline ? <EditDistrictPage user={user} /> : 
                                                    <Navigate to="/404" /> } 
                                                />
                                                 <Route path={":id"} element={
                                                    isSuperAdmin ? <DDDistrictDetailsPage user={user} /> : 
                                                    isHelpline ? <DDDistrictDetailsPage user={user} /> : 
                                                    // isAdmin ? <EditDistrictPage user={user} /> :
                                                    // isHelpline ? <EditDistrictPage user={user} /> : 
                                                    <Navigate to="/404" /> } 
                                                />
                                                
                                            </Route>
                                            <Route path={"taluks"} element={<DefaultOutlet />}>
                                                <Route index element={
                                                    isSuperAdmin ? <TaluksListPage user={user} /> : 
                                                    isAdmin ? <TaluksListPage user={user} /> :
                                                    isHelpline ? <TaluksListPage user={user} /> :
                                                    <Navigate to="/404" /> } 
                                                />
                                                <Route path={"add"} element={
                                                    isSuperAdmin ? <AddTalukPage user={user} /> : 
                                                    isAdmin ? <AddTalukPage user={user} /> : 
                                                    isHelpline ? <AddTalukPage user={user} /> :
                                                    <Navigate to="/404" /> } 
                                                />
                                                <Route path={"edit/:id"} element={
                                                    isSuperAdmin ? <EditTalukPage user={user} /> : 
                                                    isAdmin ? <EditTalukPage user={user} /> : 
                                                    isHelpline ? <EditTalukPage user={user} /> :
                                                    <Navigate to="/404" /> } 
                                                />
                                            </Route>
                                        </Route>
                                        <Route path={"institutions"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <EntityListPage user={user} /> : 
                                                isDD  ? <DDEntityListPage user={user} /> : 
                                                isHelpline ? <EntityListPage user={user} /> :
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"add"} element={
                                                isSuperAdmin ? <AddEntityPage user={user} /> : 
                                                isDD  ? <AddDDEntityPage user={user} /> :
                                                isHelpline ? <AddEntityPage user={user} /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                             <Route path={":id"} element={
                                                isSuperAdmin ? <EntityDetailsPage user={user} /> : 
                                                isDD  ? <DDEntityDetailsPage user={user} /> : 
                                                isHelpline ? <EntityDetailsPage user={user} /> :
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"edit/:id"} element={
                                                isSuperAdmin ? <EditEntityPage user={user} /> : 
                                                isDD  ? <EditDDEntityPage  user={user}/> :
                                                isHelpline ? <EditEntityPage user={user} /> :
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                        <Route path={"tweet"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                 //isSuperAdmin ? <AddTweetPage user={user} /> :
                                                isWarden ? <AddTweetPage user={user} /> :  
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            {/* <Route path={"add"} element={
                                                isSuperAdmin ? <AddEntityPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                             <Route path={":id"} element={
                                                isSuperAdmin ? <EntityDetailsPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            /> */}
                                            {/* <Route path={"edit/:id"} element={
                                                isSuperAdmin ? <EditEntityPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            /> */}
                                        </Route>
                                        <Route path={"get-tweet"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                // isSuperAdmin ? <AddTweetPage user={user} /> :
                                                isDD ? <DDTweetListPage user={user} /> :  
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            {/* <Route path={"add"} element={
                                                isSuperAdmin ? <AddEntityPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                             <Route path={":id"} element={
                                                isSuperAdmin ? <EntityDetailsPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            /> */}
                                            {/* <Route path={"edit/:id"} element={
                                                isSuperAdmin ? <EditEntityPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            /> */}
                                        </Route>
                                        <Route path={"hostels"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <HostelsListPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"add"} element={
                                                isSuperAdmin ? <AddHostelPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={":id"} element={
                                                isSuperAdmin ? <HostelDetailsPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"edit/:id"} element={
                                                isSuperAdmin ? <EditHostelPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                    </Route>
                                    <Route path={"reports"} element={<DashboardOutlet user={user} />}>
                                        <Route index element={<Navigate to={"/reports/incomplete-institutions"} />} />
                                        <Route path={"incomplete-institutions"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isHelpline ? <HelplineIncompleteInstitutionsPage user={user} /> : 
                                                isDD ? <DDIncompleteInstitutionsPage user={user} /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"no-warden"} element={
                                                // isSuperAdmin ? <AddEntityPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                isHelpline ? <EntityWithNoWardenListHelplinePage user={user} /> : 
                                                isDD ? <EntityWithNoWardenListDDPage user={user} /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"twitter-id"} element={
                                                // isSuperAdmin ? <AddEntityPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                isHelpline ? <EntityWithNoTwitterIDListHelplinePage user={user} /> : 
                                                isDD ? <EntityWithNoTwitterIDListDDPage user={user} /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"twitter-password"} element={
                                                // isSuperAdmin ? <AddEntityPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                isHelpline ? <EntityWithNoTwitterPasswordListHelplinePage user={user} /> : 
                                                isDD ? <EntityWithNoTwitterPasswordListDDPage user={user} /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                        <Route path={"meal-posting-status"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isDD ? <Navigate to="/reports/meal-posting-status/breakfast" /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"breakfast"} element={ 
                                                isDD ? <MealPostingReportDDBreakfastPage user={user} /> :  
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"lunch"} element={ 
                                                isDD ? <MealPostingReportDDLunchPage user={user} /> :  
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"dinner"} element={ 
                                                isDD ? <MealPostingReportDDDinnerPage user={user} /> :  
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                    </Route>
                                </>
                            :
                            <></>
                        }
                        {/* <Route path="/404" element={<div>404</div>} /> */}
                        <Route path="*" element={<Navigate to={isLoggedIn === true && isValidated === true ? "/dashboard/home" : "/"} />} />
                    </Routes>
                :
                    <></>
            }
        </>
    )
}

export default AppRouter