import React, { useEffect } from 'react';
import ContentContainer from './ContentContainer';
import ContentSidebarFooter from './ContentSidebarFooter';
import DDLayoutNavBar from './Navbar';
import DDLayoutFooter from "./Footer";
import DDLayoutHeader from "./Header"

const DDLayout = ({ user, children }) => {
    return (
        <div className="dashboardPage">
            <DDLayoutNavBar user={user} />
            <ContentSidebarFooter>
                <ContentContainer>
                    <DDLayoutHeader user={user} />
                    {children}
                </ContentContainer>
                <DDLayoutFooter />
            </ContentSidebarFooter>
        </div>
    )
}

export default DDLayout