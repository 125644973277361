import React from 'react'
import { motion } from 'framer-motion';
import { icons } from '../../../../../../constants';
import { globals } from '../../../../../../utils';

const SidebarFileVisual = ({ type="folder", ext="", details={}, variants, closeSidebar }) => {
    return (
        <motion.div className="fileManagerSidebarFileFolderInfo" variants={variants}>
            <div className="fileManagerSidebarFileFolderInfoClose" onClick={closeSidebar}>
                <div className="fileManagerSidebarFileFolderInfoCloseIcon">
                    <i className={`${icons.wrong} fileManagerSidebarFileFolderInfoCloseIco`}></i>
                </div>
            </div>
            {
                type === "folder"
                ?
                    <div className="fileManagerSidebarFolderInfoIcon">
                        <i className={`${icons.folderOpen} fileManagerSidebarFolderInfoIco`}></i>
                    </div>
                :
                type === "file"
                ?
                    ext === "webp" || ext === "svg"
                    ?
                    <div className="fileManagerSidebarImageInfo">
                        <div className="fileManagerSidebarImageInfoVisual" style={{backgroundImage: `url(${globals.API_BASE_URL}${details.image_url.thumbnail.low_res})`}}>&nbsp;</div>
                    </div>
                    :
                    ext === "pdf"
                    ?
                    <div className="fileManagerSidebarFileInfoIcon">
                        <i className={`${icons.filePDF} fileManagerSidebarFileInfoIco`}></i>
                    </div>
                    :
                    <></>
                :
                <></>
            }
        </motion.div>
    )
}

export default SidebarFileVisual