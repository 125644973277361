import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import icons from '../../../constants/icons';
import BasicBigHeader from '../../../components/dashboard/common/PageHeaders/BasicBigHeader';
import ContentWithUserDetails from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/ContentWithUserDetails';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import BasicStats from '../../../components/dashboard/common/BasicStats';
import BasicStat from '../../../components/dashboard/common/BasicStats/BasicStat';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableCheckbox from '../../../components/core/table/inputs/Checkbox';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import Pagination from '../../../components/core/table/pagination';
import {globals} from '../../../utils';
import Card from '../../../components/core/dashboard/Card';
import BasicListItem from '../../../components/dashboard/common/Requests/BasicListItem';
import BasicActivitiesList from '../../../components/dashboard/common/Activities/BasicActivitiesList';
import BasicActivityListItem from '../../../components/dashboard/common/Activities/BasicActivityListItem';
import { DDServices, HelplineServices, StatsServices, TweetServices } from '../../../services';
import { ToastAlert } from '../../../utils';

const HelplineHomePage = ({user}) => {
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    
    useEffect(() => {
        let nameArray = user.name.split(" ");
        setFirstName(nameArray[0]);
    }, [user]);

    const [stats,setStats] = useState([])

    const [tweets, setTweets] = useState([]);
    const [districts , setDistricts] = useState([]);
    const [institutions, setInstitutions] = useState([])
    const [totalTweetsCount, setTotalTweetsCount] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);


    const handleGetThisStatReq = async () => {
        const getThisStatReq = await StatsServices.getAdminStats({
            token:user.access_token,
        })
        
        if(getThisStatReq.status === false){
            ToastAlert.notifyError(getThisStatReq.message);
            setStats([]);
        }
        else{
            setStats(getThisStatReq?.data);
        }
    }

    useEffect(()=>{
        handleGetThisStatReq()
    },[])

    const handleGetTweetsRequest = async () => {
        const getTweetsReq = await DDServices.getDDDashboardTweets({
            token: user.access_token,
        });

        if(getTweetsReq.status === false){
            ToastAlert.notifyError(getTweetsReq.message);
            setTweets([]);
        }
        else{
            const Tweets = getTweetsReq?.data?.map(tweet => ({
                ...tweet,
                createdAtFormatted : moment(tweet.createdAt).format('MMMM Do YYYY, h:mm:ss A'),
            }));
            setTweets(Tweets);
        }
    }

    // useEffect(() => {
    //     handleGetTweetsRequest();
    // },[]);

    const handleGetDistrictsRequest = async () => {
        const getDistrictsReq = await HelplineServices.getHelplineDashboardDistricts({
            token: user.access_token,
        });

        if(getDistrictsReq.status === false){
            ToastAlert.notifyError(getDistrictsReq.message);
            setDistricts([]);
        }
        else{
            const districts = getDistrictsReq?.data?.map(district => ({
                ...district,
                createdAtFormatted : moment(district.createdAt).format('MMMM Do YYYY, h:mm:ss A'),
            }));
            setDistricts(districts);
        }
    }

    useEffect(() => {
        handleGetDistrictsRequest();
    },[]);


    const handleGetInstitutionsRequest = async () => {
        const getInstitutionsReq = await HelplineServices.getHelplineDashboardInstitutions({
            token: user.access_token,
        });

        if(getInstitutionsReq.status === false){
            ToastAlert.notifyError(getInstitutionsReq.message);
            setInstitutions([]);
        }
        else{
            const Institutions = getInstitutionsReq?.data?.map(inst => ({
                ...inst,
                createdAtFormatted : moment(inst.createdAt).format('MMMM Do YYYY, h:mm:ss A'),
            }));
            setInstitutions(Institutions);
        }
    }

    useEffect(() => {
        handleGetInstitutionsRequest();
    },[]);

    return(
        <>
            <BasicBigHeader>
                <ContentWithUserDetails 
                    avatar={'/images/default-avatar.jpg'}
                    heading={`welcome back, ${firstName}!`}
                    descriptionIcon={icons.bellSolid}
                    hasDescriptionIcon={true}
                    description={`Stay connected ${user?.name} : Create and review your tweets seamlessly!`}
                />
                {/* 
                    <HeaderActions>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg="primary"
                            borderRadius="full"
                            hasIcon={true}
                            iconPosition="left"
                            icon={icons.tickCircle}
                        >
                            pending approvals
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg="dashboard"
                            borderRadius="full"
                            hasIcon={true}
                            iconPosition="left"
                            icon={icons.settingsGear}
                        >
                            settings
                        </Button>
                    </HeaderActions> */}
            </BasicBigHeader>

            <BasicStats>
                <BasicStat 
                    cardHeading={"Districts"}
                    statCount={stats?.no_of_districts}
                    statLable="No. of Districts"
                    cardStatColor="blue"
                    secondaryStatLable="Under Progress"
                    secondaryStatCount={20}
                    statId={`websites`}
                    //headingActionIcon={icons.rightArrow}
                />
                <BasicStat 
                    cardHeading={"Taluks"}
                    statCount={stats?.no_of_taluks}
                    statLable="No. of Taluks"
                    cardStatColor="red"
                    secondaryStatLable="Feature Updates"
                    secondaryStatCount={12}
                    statId={`pendingRequests`}
                />
                <BasicStat 
                    cardHeading={"Institutions"}
                    statCount={`${stats?.no_of_schools + stats?.no_of_hostels}`}
                    statLable="No. of Institutions"
                    cardStatColor="yellow"
                    secondaryStatLable="Regular"
                    secondaryStatCount={20}
                    statId={`pendingApprovals`}
                />
                <BasicStat 
                    cardHeading={"Tweets"}
                    statCount={stats?.no_of_tweets}
                    statLable="No. of Tweets"
                    cardStatColor="green"
                    secondaryStatLable="Staff Under Owner"
                    secondaryStatCount={20}
                    statId={`users`}
                />
            </BasicStats>
  
            <PlainContainer type="full" styles={{paddingLeft: '30px', paddingRight: '30px'}}>
                <PlainContainer type="full">
                    <Card 
                        heading={"Hostels/Schools"} 
                        isHeadingAction={false} 
                        isBottomAction={true} 
                        isBottomPrimaryAction={true} 
                        bottomPrimaryActionLable={"see all schools and hostels"}
                        bottomPrimaryAction={() => navigate("/masterdata/institutions")}
                    >
                        <PlainContainer type="full">
                            {institutions.map((inst) => (
                                <BasicListItem 
                                    key={inst.id} 
                                    // heading={inst?.name?.en} 
                                    heading={inst?.organization_type === "SCHOOL" ? `(School)${inst?.name?.en}`: `(Hostel)${inst?.name?.en}`}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={`${inst?.district?.name?.en}, ${inst?.taluk?.name?.en}`}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={inst?.createdAtFormatted}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate(`/masterdata/institutions/${inst?.id}`)} 
                                />
                            ))}
                        </PlainContainer> 
                    </Card>
                </PlainContainer>

                <SplitContainer type="full" styles={{marginTop: '30px', flex: '50%',}}>
                    <PlainContainer type="full">
                        <Card 
                            heading={"Districts"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryActionLable={"see all Districts"}
                            bottomPrimaryAction={() => navigate("/masterdata/locations/districts")}
                        >
                               <PlainContainer type="full" styles={{ marginTop: '5px' }}>
                                    {districts.map((dist) => (
                                        <BasicListItem 
                                            key={dist.id} 
                                            heading={dist?.district?.name?.en} 
                                            hasPrimaryInfo={true}
                                            hasPrimaryInfoIcon={true}
                                            primaryInfoHeading={dist?.created_by?.first_name}
                                            primaryInfoIcon={icons.user}
                                            hasSecondaryInfo={true}
                                            hasSecondaryInfoIcon={true}
                                            secondaryInfoHeading={dist?.createdAtFormatted}
                                            secondaryInfoIcon={icons.clockSolid}
                                            hasAction={true}
                                            actionIcon={icons.rightArrow}
                                            action={() => console.log("")} 
                                        />
                                    ))}
                                </PlainContainer> 
                        </Card>
                    </PlainContainer>
                    <PlainContainer type="full">
                        <Card 
                            heading={"taluks"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryActionLable={"see all taluks"}
                            bottomPrimaryAction={() => navigate("/masterdata/locations/taluks")}
                        >
                           <PlainContainer type="full" styles={{ marginTop: '5px' }}>
                                    {districts.map((taluk) => (
                                        <BasicListItem 
                                            key={taluk.id} 
                                            heading={taluk?.taluk?.name?.en} 
                                            hasPrimaryInfo={true}
                                            hasPrimaryInfoIcon={true}
                                            primaryInfoHeading={taluk?.created_by?.first_name}
                                            primaryInfoIcon={icons.user}
                                            hasSecondaryInfo={true}
                                            hasSecondaryInfoIcon={true}
                                            secondaryInfoHeading={taluk?.createdAtFormatted}
                                            secondaryInfoIcon={icons.clockSolid}
                                            hasAction={true}
                                            actionIcon={icons.rightArrow}
                                            action={() => console.log()} 
                                        />
                                    ))}
                                </PlainContainer>
                        </Card>
                    </PlainContainer>
                </SplitContainer>

                <PlainContainer type="full" styles={{marginTop: '15px'}}>
                    <SplitContainer type="full"styles={{margin: '0px', flex: '50%'}}>
                        
                        <PlainContainer type="full">
                            {
                                tweets.length === 0
                                ?
                                <>
                                <Card 
                                    heading={"tweets"} 
                                    isHeadingAction={false} 
                                    isBottomAction={false} 
                                    isBottomPrimaryAction={false} 
                                    bottomPrimaryActionLable={"see all tweets"}
                                >
                                    <PlainContainer type="full" styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                        {/* <NoDataContainer
                                            icon={icons.sad}
                                            heading={"No Tweet Details found!"}
                                            description={"Update Tweet Details details by clicking on the below button."}
                                            hasSectionAction={false}
                                            // sectionAction={() => alert("section add")}
                                            // hasActions={true}
                                            // hasPrimaryAction={true}
                                            // primaryActionLable={"update"}
                                            // primaryAction={()=> console.log(true)}
                                            // hasSecondaryAction={true}
                                            // secondaryActionLable={"refresh"}
                                            // secondaryAction={() =>console.log("refresh")}
                                        /> */}

                                        <div className="noDataContainerDashboard">
                                            <div className="noDataContainerDashboardInner">
                                                <div className="noDataContainerDashboardIcon">
                                                    <i className={`noDataContainerIco ${icons.sad}`}></i>
                                                </div>
                                                <div className="noDataContainerHeadingDescription">
                                                    <div className="noDataContainerHeading">
                                                        <p className="noDataContainerHeadingTxt">No Tweets found!</p>
                                                    </div>
                                                    <div className="noDataContainerDescription">
                                                        <p className="noDataContainerDescriptionTxt">The tweets made by the wardens of your district will be displayed here. Comeback to this section later to review.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </PlainContainer>
                                    
                                </Card>
                                    
                                </>
                                :
                            <Card 
                                heading={"tweets"} 
                                isHeadingAction={false} 
                                isBottomAction={true} 
                                isBottomPrimaryAction={true} 
                                bottomPrimaryActionLable={"see all tweets"}
                            >
                                <PlainContainer type="full" styles={{ marginTop: '5px' }}>
                                        {tweets.map((tweet) => (
                                            <BasicListItem 
                                                key={tweet.id} 
                                                heading={tweet?.name?.en} 
                                                hasPrimaryInfo={true}
                                                hasPrimaryInfoIcon={true}
                                                primaryInfoHeading={tweet?.district?.name?.en}
                                                primaryInfoIcon={icons.sitemap}
                                                hasSecondaryInfo={true}
                                                hasSecondaryInfoIcon={true}
                                                secondaryInfoHeading={tweet?.createdAtFormatted}
                                                secondaryInfoIcon={icons.clockSolid}
                                                hasAction={true}
                                                actionIcon={icons.rightArrow}
                                                action={() => console.log()} 
                                            />
                                        ))}
                                </PlainContainer>
                            </Card>
                            }
                            
                        </PlainContainer>
                    </SplitContainer>
                </PlainContainer>
            </PlainContainer>

            
                        {
                            totalPagesCount > 1
                            ?
                                <PlainContainer type='full' styles={{ padding: "0px 25px" }}>
                                    <Pagination totalPages={totalPagesCount} setCurrentPage={setCurrentPage}/>
                                </PlainContainer>
                            :
                                <></>
                        }
        </>
    )
}

export default HelplineHomePage