import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { functions } from '../../utils';
import moment from 'moment';
import DefaultHeader from '../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import DashboardHeaderSearchBar from '../../components/core/form/DashboardHeaderSearchBar';
import Button from '../../components/core/dashboard/Button';
import { icons } from '../../constants';
import PlainContainer from '../../components/core/containers/PlainContainer';
import Card from '../../components/core/dashboard/Card';
import Table from '../../components/core/table/containers/Table';
import TableHeading from '../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../components/core/table/containers/TableHeadingItem';
import TableCheckbox from '../../components/core/table/inputs/Checkbox';
import TableRows from '../../components/core/table/containers/TableRows';
import TableRow from '../../components/core/table/containers/TableRow';
import TableRowItem from '../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../components/core/table/containers/TableRowActions';
import TableButton from '../../components/core/table/inputs/Button';
import { ToastAlert } from '../../utils';
import Pagination from '../../components/core/table/pagination';
import MediaTypeServices from '../../services/MediaType';

const MediaTypesListPage = ({ user }) => {
    const navigate = useNavigate();

    const [mediaTypes, setMediaTypes] = useState([]);
    const [totalMediaTypesCount, setTotalMediaTypesCount] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    
    const handleGetMediaTypesRequest = async () => {
        const getMediaTypesReq = await MediaTypeServices.getAllMediaTypes({
            token: user.access_token,
            current_page: currentPage
        });

        console.log("d",getMediaTypesReq);

        if(getMediaTypesReq.status === false){
            ToastAlert.notifyError(getMediaTypesReq.message);
            setMediaTypes([]);
            setTotalMediaTypesCount(0);
            setTotalPagesCount(0);
            setCurrentPage(1);
        }
        else{
            if(getMediaTypesReq.data.items.length > 0){
                setMediaTypes(getMediaTypesReq.data.items);
                setTotalMediaTypesCount(getMediaTypesReq.data.total_items);
                setTotalPagesCount(getMediaTypesReq.data.total_pages);
                setCurrentPage(getMediaTypesReq.data.current_page);
            }
            else{
                setMediaTypes([]);
                setTotalMediaTypesCount(0);
                setTotalPagesCount(0);
                setCurrentPage(1);
            }
        }
    }

    useEffect(() => {
        handleGetMediaTypesRequest();
    }, [currentPage]);

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Media Types'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='List of Media Types using which files will be uploaded later on.'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions>
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        {/* <PlainContainer type="full" styles={{paddingTop: "15px", paddingRight: "25px", paddingBottom: "25px", paddingLeft: "25px"}}>
                            <StatesListFilters />
                        </PlainContainer> */}
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={0} hasChildren={true}>
                                        <TableCheckbox name={"items-selected-ckb"} />
                                    </TableHeadingItem>
                                    <TableHeadingItem size={2} lable={"name"} />
                                    <TableHeadingItem size={1} lable={"created by"} />
                                    <TableHeadingItem size={1} lable={"No. of files"} />
                                    <TableHeadingItem size={1} lable={"Uploaded Size"} />
                                    <TableHeadingItem size={1} lable={"Optimized Size"} />
                                    <TableHeadingItem size={0} lable={""} />
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>
                                <TableRows>
                                    {
                                        mediaTypes.map((mt, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    <TableRowItem size={0} hasChildren={true} isCenter={false}>
                                                        <TableCheckbox name={`items-selected-ckb-0`} />
                                                    </TableRowItem>
                                                    <TableRowItem size={2} isCenter={false} hasPreview={mt?.icon === undefined ? false : true} previewType={'icon'} previewItem={mt?.icon} lable={mt?.name} />
                                                    <TableRowItem size={1} isCenter={false} lable={`${mt?.created_by?.first_name} (${mt?.created_by?.role === "SUPER_ADMIN" ? "super admin" : mt?.created_by?.role === "ADMIN" ? "admin" : mt?.created_by?.role === "CREATOR" ? "creator" : mt?.created_by?.role === "MODERATOR" ? "moderator" : mt?.created_by?.role === "APPROVER" ? "approver" : ""})`} />
                                                    <TableRowItem size={1} isCenter={false} lable={mt?.no_of_files} />
                                                    <TableRowItem size={1} isCenter={false} lable={functions.getReadableFileSize(mt?.uploaded_files_size)} />
                                                    <TableRowItem size={1} isCenter={false} lable={functions.getReadableFileSize(mt?.optimized_files_size)} />
                                                    <TableRowItem size={0} isCenter={true} hasChildren={true}>
                                                        <TableRowIcons>
                                                            <TableRowIcon icon={icons.eye} color={"dark"} action={() => console.log()} />
                                                        </TableRowIcons>
                                                    </TableRowItem>
                                                    <TableRowItem size={2} isCenter={false} hasChildren={true}>
                                                        <TableRowActions>
                                                            <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"}>edit</TableButton>
                                                            <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => console.log('')}>delete</TableButton>
                                                        </TableRowActions>
                                                    </TableRowItem>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                        {
                            totalPagesCount > 1
                            ?
                            <PlainContainer type='full' styles={{ padding: "0px 25px" }}>
                                <Pagination totalPages={totalPagesCount} setCurrentPage={setCurrentPage}/>
                            </PlainContainer>
                            :
                            <></>
                        }
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default MediaTypesListPage