import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DefaultHeader from '../../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../../../components/core/dashboard/Button';
import { icons } from '../../../../../constants';
import PlainContainer from '../../../../../components/core/containers/PlainContainer';
import Card from '../../../../../components/core/dashboard/Card';
import Table from '../../../../../components/core/table/containers/Table';
import TableHeading from '../../../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../../../components/core/table/containers/TableHeadingItem';
import TableRows from '../../../../../components/core/table/containers/TableRows';
import TableRow from '../../../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../../../components/core/table/inputs/Button';
import TalukServices from '../../../../../services/Location/Taluk';
import { ToastAlert } from '../../../../../utils';
import Pagination from '../../../../../components/core/table/pagination';
import DistrictServices from '../../../../../services/Location/District';
import AdminServices from '../../../../../services/Admin';
import { StatsServices } from '../../../../../services';
import MealPostingStatusFilter from '../MealPostingStatusFilter';
import BasicStats from '../../../../../components/dashboard/common/BasicStats';
import BasicStat from '../../../../../components/dashboard/common/BasicStats/BasicStat';

const MealPostingReportDDDinnerPage = ({ user }) => {
    const navigate = useNavigate();

    const [postedOnTimeEntities, setPostedOnTimeEntities] = useState([]);
    const [postedOnDelayEntities, setPostedOnDelayEntities] = useState([]);
    const [postedAfterDeadlineEntities, setPostedAfterDeadlineEntities] = useState([]);
    const [notPostedEntities, setNotPostedEntities] = useState([]);

    const [taluks, setTaluks] = useState([]);

    const [district, setDistrict] = useState('ALL');
    const [taluk, setTaluk] = useState('ALL');
    const [institutionType, setInstitutionType] = useState("ALL");
    const [status, setStatus] = useState("ALL");

    const handleGetPublicTaluksReq = async () => {
        const getTaluksReq = await TalukServices.getDropdownTaluks({
            district:district,
            token: user.access_token
        })
        if(getTaluksReq.status === false){
            ToastAlert.notifyError(getTaluksReq.message);   
            setTaluks([]);
        }
        else{
            setTaluks(getTaluksReq.data);
        }
    }

   useEffect(() => {
          handleGetPublicTaluksReq()
      }, [district]);

      
    const handleGetDDDistrictRequest = async () => {
        const getDDDistrictReq = await AdminServices.getThisAdminDetails({
            token:user.access_token,
        });

        if(getDDDistrictReq.status === false){
            // console.log("districts error",getDDDistrictReq)
            ToastAlert.notifyError(getDDDistrictReq.message);
            setDistrict([]);
        }
        else{
            // console.log("districts success",getDDDistrictReq?.data?.data?.district?.id)
            setDistrict(getDDDistrictReq?.data?.data?.district?.id);
        }
    }

    useEffect(() => {
        handleGetDDDistrictRequest();
    }, []);


    const handleGetReportRequest = async () => {

        const getReportReq = await StatsServices.getThisDistrictMealPostingReport({
            token: user.access_token,
            district: district,
            taluk: taluk,
            institution_type: institutionType,
            status: status
        });

        if(getReportReq.status === false){
            ToastAlert.notifyError(getReportReq.message);
            setPostedOnTimeEntities([]);
            setPostedOnDelayEntities([]);
            setPostedAfterDeadlineEntities([]);
            setNotPostedEntities([]);
        }
        else{
            setPostedOnTimeEntities(getReportReq?.data.Meal_3.posted_on_time);
            setPostedOnDelayEntities(getReportReq?.data.Meal_3.posted_with_delay);
            setPostedAfterDeadlineEntities(getReportReq?.data.Meal_3.post_not_considered);
            setNotPostedEntities(getReportReq?.data.Meal_3.not_posted);
        }
    }

    useEffect(() => {
        if(district != "ALL"){
            handleGetReportRequest();
        }
    }, [district, taluk, institutionType, status]);

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Meal Posting Status - Dinner'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='List of Institutions with Current Meal Posting Status of Today.'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.leftArrowTail}
                        action={()=> navigate(-1) }
                    >
                        Go Back
                    </Button>
                </HeaderActions>
            </DefaultHeader>

            <BasicStats>
                <BasicStat 
                    cardHeading={"On Time"}
                    statCount={postedOnTimeEntities.length}
                    statLable="Posted On Time"
                    cardStatColor="green"
                    secondaryStatLable="Under Progress"
                    secondaryStatCount={20}
                    statId={`websites`}
                    //headingActionIcon={icons.rightArrow}
                />
                <BasicStat 
                    cardHeading={"Delayed"}
                    statCount={postedOnDelayEntities.length}
                    statLable="Posted With Delay"
                    cardStatColor="blue"
                    secondaryStatLable="Feature Updates"
                    secondaryStatCount={12}
                    statId={`pendingRequests`}
                />
                <BasicStat 
                    cardHeading={"After Deadline"}
                    statCount={postedAfterDeadlineEntities.length}
                    statLable="Posted After Deadline"
                    cardStatColor="yellow"
                    secondaryStatLable="Regular"
                    secondaryStatCount={20}
                    statId={`pendingApprovals`}
                />
                <BasicStat 
                    cardHeading={"Not Posted"}
                    statCount={notPostedEntities.length}
                    statLable="Not Posted"
                    cardStatColor="red"
                    secondaryStatLable="Staff Under Owner"
                    secondaryStatCount={20}
                    statId={`users`}
                />
            </BasicStats>

            <PlainContainer type="full" styles={{paddingBottom: '30px', paddingRight: '30px', paddingLeft: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        <PlainContainer type="full" styles={{paddingTop: "15px", paddingRight: "25px", paddingBottom: "25px", paddingLeft: "25px"}}>
                            <MealPostingStatusFilter
                                taluks={taluks} 
                                taluk={taluk}
                                TalukTypeChanged={setTaluk}
                                institution_type={institutionType}
                                InstitutionTypeChanged={setInstitutionType} 
                                status={status}
                                statusChanged={setStatus} 
                            />
                        </PlainContainer>
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={0} lable={"SL NO."} />
                                    <TableHeadingItem size={4} lable={"name"} />
                                    <TableHeadingItem size={1} lable={"taluk"} />
                                    <TableHeadingItem size={1} lable={"institution type"} />
                                    <TableHeadingItem size={1} lable={"status"} />
                                </TableHeading>
                                {
                                    status === "ALL"
                                    ?
                                    <>
                                        {
                                            postedOnTimeEntities.map((entity, i) => {
                                                return (
                                                    <TableRow key={i} isCenter={false}>
                                                        <TableRowItem size={0} isCenter={false} hasPreview={false} lable={i+1} />
                                                        <TableRowItem size={4} isCenter={false} hasPreview={false} lable={entity?.name?.en} />
                                                        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={entity?.taluk?.name?.en} />
                                                        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={entity?.organization_type} />
                                                        <TableRowItem size={1} isCenter={false} hasChildren={true}>
                                                            <div className={`tableRoundedMessage success`}>
                                                                <p className="tableRoundedMessageText">On Time</p>
                                                            </div>
                                                        </TableRowItem>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                        {
                                            postedOnDelayEntities.map((entity, i) => {
                                                return (
                                                    <TableRow key={i} isCenter={false}>
                                                        <TableRowItem size={0} isCenter={false} hasPreview={false} lable={i+1} />
                                                        <TableRowItem size={4} isCenter={false} hasPreview={false} lable={entity?.name?.en} />
                                                        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={entity?.taluk?.name?.en} />
                                                        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={entity?.organization_type} />
                                                        <TableRowItem size={1} isCenter={false} hasChildren={true}>
                                                            <div className={`tableRoundedMessage warning`}>
                                                                <p className="tableRoundedMessageText">Delayed</p>
                                                            </div>
                                                        </TableRowItem>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                        {
                                            postedAfterDeadlineEntities.map((entity, i) => {
                                                return (
                                                    <TableRow key={i} isCenter={false}>
                                                        <TableRowItem size={0} isCenter={false} hasPreview={false} lable={i+1} />
                                                        <TableRowItem size={4} isCenter={false} hasPreview={false} lable={entity?.name?.en} />
                                                        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={entity?.taluk?.name?.en} />
                                                        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={entity?.organization_type} />
                                                        <TableRowItem size={1} isCenter={false} hasChildren={true}>
                                                            <div className={`tableRoundedMessage dangerLight`}>
                                                                <p className="tableRoundedMessageText">After Deadline</p>
                                                            </div>
                                                        </TableRowItem>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                        {
                                            notPostedEntities.map((entity, i) => {
                                                return (
                                                    <TableRow key={i} isCenter={false}>
                                                        <TableRowItem size={0} isCenter={false} hasPreview={false} lable={i+1} />
                                                        <TableRowItem size={4} isCenter={false} hasPreview={false} lable={entity?.name?.en} />
                                                        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={entity?.taluk?.name?.en} />
                                                        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={entity?.organization_type} />
                                                        <TableRowItem size={1} isCenter={false} hasChildren={true}>
                                                            <div className={`tableRoundedMessage danger`}>
                                                                <p className="tableRoundedMessageText">Not Posted</p>
                                                            </div>
                                                        </TableRowItem>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </>
                                    :
                                    status === "POSTED_ON_TIME"
                                    ?
                                    <>
                                        {
                                            postedOnTimeEntities.map((entity, i) => {
                                                return (
                                                    <TableRow key={i} isCenter={false}>
                                                        <TableRowItem size={0} isCenter={false} hasPreview={false} lable={i+1} />
                                                        <TableRowItem size={4} isCenter={false} hasPreview={false} lable={entity?.name?.en} />
                                                        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={entity?.taluk?.name?.en} />
                                                        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={entity?.organization_type} />
                                                        <TableRowItem size={1} isCenter={false} hasChildren={true}>
                                                            <div className={`tableRoundedMessage success`}>
                                                                <p className="tableRoundedMessageText">On Time</p>
                                                            </div>
                                                        </TableRowItem>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </>
                                    :
                                    status === "POSTED_WITH_DELAY"
                                    ?
                                    <>
                                        {
                                            postedOnDelayEntities.map((entity, i) => {
                                                return (
                                                    <TableRow key={i} isCenter={false}>
                                                        <TableRowItem size={0} isCenter={false} hasPreview={false} lable={i+1} />
                                                        <TableRowItem size={4} isCenter={false} hasPreview={false} lable={entity?.name?.en} />
                                                        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={entity?.taluk?.name?.en} />
                                                        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={entity?.organization_type} />
                                                        <TableRowItem size={1} isCenter={false} hasChildren={true}>
                                                            <div className={`tableRoundedMessage warning`}>
                                                                <p className="tableRoundedMessageText">Delayed</p>
                                                            </div>
                                                        </TableRowItem>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </>
                                    :
                                    status === "POST_NOT_CONSIDERED"
                                    ?
                                    <>
                                        {
                                            postedAfterDeadlineEntities.map((entity, i) => {
                                                return (
                                                    <TableRow key={i} isCenter={false}>
                                                        <TableRowItem size={0} isCenter={false} hasPreview={false} lable={i+1} />
                                                        <TableRowItem size={4} isCenter={false} hasPreview={false} lable={entity?.name?.en} />
                                                        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={entity?.taluk?.name?.en} />
                                                        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={entity?.organization_type} />
                                                        <TableRowItem size={1} isCenter={false} hasChildren={true}>
                                                            <div className={`tableRoundedMessage dangerLight`}>
                                                                <p className="tableRoundedMessageText">After Deadline</p>
                                                            </div>
                                                        </TableRowItem>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </>
                                    :
                                    status === "NOT_POSTED"
                                    ?
                                    <>
                                        {
                                            notPostedEntities.map((entity, i) => {
                                                return (
                                                    <TableRow key={i} isCenter={false}>
                                                        <TableRowItem size={0} isCenter={false} hasPreview={false} lable={i+1} />
                                                        <TableRowItem size={4} isCenter={false} hasPreview={false} lable={entity?.name?.en} />
                                                        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={entity?.taluk?.name?.en} />
                                                        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={entity?.organization_type} />
                                                        <TableRowItem size={1} isCenter={false} hasChildren={true}>
                                                            <div className={`tableRoundedMessage danger`}>
                                                                <p className="tableRoundedMessageText">Not Posted</p>
                                                            </div>
                                                        </TableRowItem>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </>
                                    :
                                    <></>
                                }
                            </Table>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default MealPostingReportDDDinnerPage