import axios from "axios";
import { statusCodes, defaults } from "../../utils"

const config = (token) => {
    return (
        {
            headers: {
                'Authorization': token
            }
        }
    )
}

const saveEntity = async (payload) => {
    if(!payload.en_name){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "English name is required.",
        });

        return response
    }

    if(!payload.kn_name){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Kannada name is required.",
        });

        return response
    }

    if(!payload.district){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "District is required.",
        });

        return response
    }
    
    if(!payload.taluk){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Taluk is required.",
        });

        return response
    }
      
    if(!payload.orgType){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Organization Type is required.",
        });

        return response
    }
      
    if(payload.orgType === "HOSTEL" && !payload.hostelType){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Hostel Type  is required.",
        });

        return response
    }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    const data = {
        en_name: payload.en_name.toLowerCase(),
        kn_name: payload.kn_name,
        taluk :payload.taluk,
        district: payload.district,
        organization_type:payload.orgType,
        hostel_type:payload.hostelType,
    }

    try{
        let headers = await config(payload.token);
        const addNewEntityReq = await axios.post(`${defaults.API_BASE_URL}organization`, data, headers);
        if(addNewEntityReq.status === statusCodes.NOT_FOUND){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.NOT_FOUND,
                message: "Unabe to add Entity ."
            });
    
            return response
        }
        else if(addNewEntityReq.status === statusCodes.CREATED){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.CREATED,
                message: "Entity  successfully created.",
                data: addNewEntityReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}


const getAllEntity = async (payload) => {
    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    try{
        let headers = await config(payload.token);

        const getAllEntityReq = await axios.get(`${defaults.API_BASE_URL}organization?page=${payload.current_page}&size=10&district=${payload?.district === undefined ? "ALL" : payload?.district}&taluk=${payload?.taluk === undefined ? "ALL" : payload?.taluk}&created_on=${payload?.created_on === undefined ? "RECENT" : payload?.created_on}&created_by=${payload?.created_by === undefined ? null : payload?.created_by}&search=${payload?.search === undefined ? null : payload?.search}`, headers);

        if(getAllEntityReq.status === statusCodes.SUCCESS){
        
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Entity successfully recieved.",
                data: getAllEntityReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}

const getThisAuthEntity = async (payload) => {

    if(!payload.id){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Entity ID is required.",
        });

        return response
    }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }
    try{
        let headers = await config(payload.token);

        const getAuthEntityReq = await axios.get(`${defaults.API_BASE_URL}organization/auth/${payload.id}`, headers);
        if(getAuthEntityReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Entity successfully recieved.",
                data: getAuthEntityReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.BAD_REQUEST,
            message: "Request failed due to expected technical error."
        });

        return response
    }
}

const updateEntity = async (payload) => {
    if(!payload.id){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "ID is required.",
        });

        return response
    }
    if(!payload.en_name){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "English name is required.",
        });

        return response
    }

    if(!payload.kn_name){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Kannada name is required.",
        });

        return response
    }

    if(!payload.district){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "District is required.",
        });

        return response
    }
    
    if(!payload.taluk){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "District is required.",
        });

        return response
    }
      
    if(!payload.orgType){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Organization Type is required.",
        });

        return response
    }
      
    if(payload.orgType === "HOSTEL" && !payload.hostelType){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Hostel Type  is required.",
        });

        return response
    }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    const data = {
        en_name: payload.en_name.toLowerCase(),
        kn_name: payload.kn_name,
        taluk :payload.taluk,
        district: payload.district,
        organization_type:payload.orgType,
        hostel_type:payload.hostelType,
    }

    try{
        let headers = await config(payload.token);

        const addNewEntityReq = await axios.patch(`${defaults.API_BASE_URL}organization/${payload.id}`, data, headers);
        if(addNewEntityReq.status === statusCodes.NOT_FOUND){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.NOT_FOUND,
                message: "Unabe to add Entity ."
            });
    
            return response
        }
        else if(addNewEntityReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Entity  successfully created.",
                data: addNewEntityReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}

const updateWardenToEntity = async (payload) => {

    if(!payload.org_id){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Organization ID is required.",
        });

        return response
    }

    if(!payload.admin_id){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Warden ID is required.",
        });

        return response
    }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    const data = {}

    try{
        let headers = await config(payload.token);

        const updateWardenToEntityReq = await axios.patch(`${defaults.API_BASE_URL}organization/assign-warden/${payload.org_id}/${payload.admin_id}`, data, headers);
        if(updateWardenToEntityReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Warden Successfully Assigned to The Organization!"
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}
const getPublicEntity = async () => {
    try{
        const getPublicEntityReq = await axios.get(`${defaults.API_BASE_URL}organization/public`);
        if(getPublicEntityReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Entity successfully recieved.",
                data: getPublicEntityReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.BAD_REQUEST,
            message: "Request failed due to expected technical error."
        });

        return response
    }
}

const getOrganizationsBelongsToThisWarden = async (payload) => {
        if(!payload.token){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Token is required.",
            });
    
            return response
        }
    
        try{
            let headers = await config(payload.token);
    
            const getOrganizationsBelongsToThisWardenReq = await axios.get(`${defaults.API_BASE_URL}organization/warden`, headers);
            if(getOrganizationsBelongsToThisWardenReq.status === statusCodes.SUCCESS){
            
                const response = defaults.ServiceResponse({
                    status: true,
                    status_code: statusCodes.SUCCESS,
                    message: "Entity successfully recieved.",
                    data: getOrganizationsBelongsToThisWardenReq?.data?.data
                });
        
                return response
            }
            else {
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.BAD_REQUEST,
                    message: "Request failed due to unexpected technical error."
                });
        
                return response
            }
        }
        catch(error){
            if(error.response.status === statusCodes.UNAUTHORISED){
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.UNAUTHORISED,
                    message: "Please provide your token.",
                });
        
                return response
            }
            else if(error.response.status === statusCodes.FORBIDDEN){
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.FORBIDDEN,
                    message: "User doesn't have required rights to process this request.",
                });
        
                return response
            }
            else if(error.response.status === statusCodes.VALIDATION_FAILED){
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.VALIDATION_FAILED,
                    message: "Invalid Inputs",
                });
        
                return response
            }
            else{
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.BAD_REQUEST,
                    message: "Request failed due to expected technical error."
                });
        
                return response
            }
        }
    }

    const deleteEntity = async (payload) => {
        if(!payload.id){
    
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: " ID is required.",
            });
    
            return response
        }
    
        if(!payload.token){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Token is required.",
            });
    
            return response
        }
    
        try{
            let headers = await config(payload.token);
    
            const updateEntityReq = await axios.delete(`${defaults.API_BASE_URL}entity/${payload.id}`, headers);
            if(updateEntityReq.status === statusCodes.SUCCESS){
                const response = defaults.ServiceResponse({
                    status: true,
                    status_code: statusCodes.SUCCESS,
                    message: "Entity successfully deleted.",
                    data: updateEntityReq?.data?.data
                });
        
                return response
            }
            else if(updateEntityReq.status === statusCodes.NOT_FOUND){
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.NOT_FOUND,
                    message: "Invalid request. The Taluk that you are trying delete is not found."
                });
        
                return response
            }
            else {
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.BAD_REQUEST,
                    message: "Request failed due to unexpected technical error."
                });
        
                return response
            }
        }
        catch(error){
            if(error.response.status === statusCodes.UNAUTHORISED){
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.UNAUTHORISED,
                    message: "Please provide your token.",
                });
        
                return response
            }
            else if(error.response.status === statusCodes.FORBIDDEN){
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.FORBIDDEN,
                    message: "User doesn't have required rights to process this request.",
                });
        
                return response
            }
            else if(error.response.status === statusCodes.VALIDATION_FAILED){
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.VALIDATION_FAILED,
                    message: "Invalid Inputs",
                });
        
                return response
            }
            else{
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.BAD_REQUEST,
                    message: "Request failed due to expected technical error."
                });
        
                return response
            }
        }
    }
    

const EntityServices = {
    saveEntity,
    getAllEntity,
    getThisAuthEntity,
    updateEntity,
    updateWardenToEntity,
    getPublicEntity,
    getOrganizationsBelongsToThisWarden,
    deleteEntity
}
export default EntityServices