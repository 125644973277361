import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import moment from 'moment';
import DefaultHeader from '../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import DashboardHeaderSearchBar from '../../components/core/form/DashboardHeaderSearchBar';
import Button from '../../components/core/dashboard/Button';
import { icons } from '../../constants';
import PlainContainer from '../../components/core/containers/PlainContainer';
import Card from '../../components/core/dashboard/Card';
import Table from '../../components/core/table/containers/Table';
import TableHeading from '../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../components/core/table/containers/TableHeadingItem';
import TableCheckbox from '../../components/core/table/inputs/Checkbox';
import TableRows from '../../components/core/table/containers/TableRows';
import TableRow from '../../components/core/table/containers/TableRow';
import TableRowItem from '../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../components/core/table/containers/TableRowActions';
import TableButton from '../../components/core/table/inputs/Button';
import { functions, globals, ToastAlert } from '../../utils';
import { PageServices } from '../../services';

const PagesListPage = ({ user }) => {
    const navigate = useNavigate();

    const [pages, setPages] = useState([]);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const handleGetPagesRequest = async () => {
        const getPagesReq = await PageServices.getAllAuthPages({
            token: user.access_token,
            current_page: currentPage
        });

        console.log(getPagesReq);

        if(getPagesReq.status === false){
            ToastAlert.notifyError(getPagesReq.message);
            setPages([]);
            setTotalItemsCount(0);
            setTotalPagesCount(0);
            setCurrentPage(1);
        }
        else{
            if(getPagesReq.data.items.length > 0){
                setPages(getPagesReq.data.items);
                setTotalItemsCount(getPagesReq.data.total_items);
                setTotalPagesCount(getPagesReq.data.total_pages);
                setCurrentPage(getPagesReq.data.current_page);
            }
            else{
                setPages([]);
                setTotalItemsCount(0);
                setTotalPagesCount(0);
                setCurrentPage(1);
            }
        }
    }

    useEffect(() => {
        handleGetPagesRequest();
    }, [currentPage]);

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Pages'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Default Representation of The Pages.'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions>
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={2} lable={"name"} />
                                    <TableHeadingItem size={1} lable={"type"} />
                                    <TableHeadingItem size={2} lable={"path"} />
                                    <TableHeadingItem size={1} lable={"created by"} />
                                    <TableHeadingItem size={0} lable={""} />
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>

                                {/* <SortableContainer onSortEnd={handleSortEnd} useDragHandle>
                                    {pages.map((page, index) => (
                                        <SortableItem key={`item-${index}`} index={index} value={page} />
                                    ))}
                                </SortableContainer> */}

                                <TableRows>
                                    {
                                        pages.map((page, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableRowItem size={2} isCenter={false} lable={page.name} />
                                                    <TableRowItem size={1} isCenter={false} lable={page.page_type === "PARENT" ? "parent" : page.page_type === "LEVEL_1" ? "level 1" : page.page_type === "LEVEL_2" ? "level 2" : ""} />
                                                    <TableRowItem size={2} isCenter={false} isCapital={false} lable={page.path} />
                                                    <TableRowItem size={1} isCenter={false} lable={`${page.created_by.first_name} (${page.created_by.role === "SUPER_ADMIN" ? "super admin" : "admin"})`} />
                                                    <TableRowItem size={0} isCenter={true} hasChildren={true}>
                                                        <TableRowIcons>
                                                            <TableRowIcon icon={icons.eye} color={"dark"} action={() => console.log()} />
                                                        </TableRowIcons>
                                                    </TableRowItem>
                                                    <TableRowItem size={2} isCenter={false} hasChildren={true}>
                                                        <TableRowActions>
                                                            <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"}>edit</TableButton>
                                                            <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => console.log('')}>delete</TableButton>
                                                        </TableRowActions>
                                                    </TableRowItem>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default PagesListPage
