import React from 'react'
import { icons } from '../../../constants'
import Button from '../dashboard/Button'

const NoDataContainer = ({ icon=icons.sad, heading="no data found!", description="", hasSectionAction=true, sectionAction=() => console.log(), hasActions=false, hasPrimaryAction=false, primaryActionLable="", primaryAction=() => console.log(), hasSecondaryAction=false, secondaryActionLable="", secondaryAction=() => console.log() }) => {
    return (
        <div className={`noDataContainer ${hasActions === false && hasSecondaryAction === true ? "hasSectionAction" : ""}`} onClick={hasActions === false && hasSectionAction === true ? sectionAction : console.log() }>
            <div className="noDataContainerInner">
                <div className="noDataContainerIcon">
                    <i className={`noDataContainerIco ${icon}`}></i>
                </div>
                <div className="noDataContainerHeadingDescription">
                    <div className="noDataContainerHeading">
                        <p className="noDataContainerHeadingTxt">{heading}</p>
                    </div>
                    <div className="noDataContainerDescription">
                        <p className="noDataContainerDescriptionTxt">{description}</p>
                    </div>
                </div>
                {
                    hasActions === true
                    ?
                    <div className="noDataContainerActions">
                        <div className="noDataContainerActionsInner">
                            {
                                hasPrimaryAction === true
                                ?
                                    <Button bg="dashboard" action={primaryAction}>{primaryActionLable}</Button>
                                :
                                    <></>
                            }
                            {
                                hasSecondaryAction === true
                                ?
                                    <Button bg="warning" action={secondaryAction}>{secondaryActionLable}</Button>
                                :
                                    <></>
                            }
                        </div>
                    </div>
                    :
                    <></>
                }
                
            </div>
        </div>
    )
}

export default NoDataContainer