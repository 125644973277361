import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DefaultHeader from '../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../../components/core/dashboard/Button';
import { icons } from '../../../../constants';
import PlainContainer from '../../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../../components/core/containers/SplitContainer';
import Card from '../../../../components/core/dashboard/Card';
import DetailsRow from '../../../../components/core/containers/DetailsRow';
import DetailsItem from '../../../../components/core/containers/DetailsItem';
import { defaults, ToastAlert, functions, moments, globals } from '../../../../utils';
import { DDServices, EntityServices, ProductServices } from '../../../../services';
import PopUp from '../../../../components/core/popup';
import NoDataContainer from '../../../../components/core/containers/NoDataContainer';
import AddWardenPopUp from '../../../../components/dashboard/popups/WardenPage/AddHostelWardenPopUp';
import AddTwitterApiPopUp from '../../../../components/dashboard/popups/TwitterPage';
import DistrictServices from '../../../../services/Location/District';
import AddDirectorPopUp from '../../../../components/dashboard/popups/Districts/AddDirectorPopUp';

const DDDistrictDetailsPage = ({ user }) => {
    const navigate = useNavigate();
    const params = useParams();

    const [district, setDistrict] = useState(null);

    const handleGetDistrictRequest = async () => {
        const getDistrictReq = await DistrictServices.getThisAuthDistrict({
            id: params.id,
            token: user.access_token
        });

        if(getDistrictReq.status === false){
            ToastAlert.notifyError(getDistrictReq.message);
            setDistrict(null);
        }
        else{
            setDistrict(getDistrictReq.data);
        }
    }

    useEffect(() => {
        handleGetDistrictRequest();
    }, []);

    const [showAddDirectorPopUp, setShowAddDirectorPopUp] = useState(false);

    const handleConcludeAddDirectorPopUp = async (e) => {
        if(e.status === false){
            setShowAddDirectorPopUp(false);
        }
        else{
            setShowAddDirectorPopUp(false);
            handleGetDistrictRequest();
        }
    }

    // const [showAddTwitterApiPopUp, setShowAddTwitterApiPopUp] = useState(false);

    // const handleConcludeAddTwitterApiPopUp = async (e) => {
    //     if(e.status === false){
    //         setShowAddTwitterApiPopUp(false);
    //     }
    //     else{
    //         setShowAddTwitterApiPopUp(false);
    //         handleGetEntityRequest();
    //     }
    // }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='District Details'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to the District in this page'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Warden"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddDirectorPopUp(true)}  isBottomAction={false}>
                        {
                            district === null
                            ?
                            <>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No warden Details found!"}
                                        description={"Update warden details by clicking on the below button."}
                                        hasSectionAction={false}
                                        sectionAction={() => alert("section add")}
                                        hasActions={true}
                                        hasPrimaryAction={true}
                                        primaryActionLable={"update"}
										hasSecondaryAction={true}
										secondaryActionLable={"refresh"}
                                        secondaryAction={() => handleGetDistrictRequest("refresh")}
                                    />
                                </PlainContainer>
                            </>
                            :
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                {
                                    district != ""
                                    ?
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"name"} 
                                            description={defaults.capitalize(district?.first_name)} 
                                        />
                                    </DetailsRow>
                                    :
                                    <></>
                                }

                                {/* {
                                    school?.sitemap?.image != undefined
                                    ?
                                    <DetailsRow>
                                        <DetailsItemWithPreview 
                                            lable={`Image`}
                                            preview={`${globals.API_BASE_URL}${school?.sitemap?.image?.image_url?.full?.high_res}`}
                                            previewType={"image"}
                                            fileName={school?.sitemap?.image?.name?.original}
                                            fileExtension={school?.sitemap?.image?.extension?.original}
                                            fileSize={school?.sitemap?.image?.size?.current}
                                        />
                                    </DetailsRow>
                                    :
                                    <></>
                                } */}
                            </PlainContainer>
                        }
                    </Card>
                </PlainContainer>
                {/* <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Manage Twitter API"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddTwitterApiPopUp(true)}  isBottomAction={false}>
                        {
                            entity?.twitter === undefined
                            ?
                            <>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No Twitter API Details found!"}
                                        description={"Update Twitter API details by clicking on the below button."}
                                        hasSectionAction={false}
                                        sectionAction={() => alert("section add")}
                                        hasActions={true}
                                        hasPrimaryAction={true}
                                        primaryActionLable={"update"}
                                        primaryAction={()=> setShowAddTwitterApiPopUp(true)}
										hasSecondaryAction={true}
										secondaryActionLable={"refresh"}
                                        secondaryAction={() => handleGetEntityRequest("refresh")}
                                    />
                                </PlainContainer>
                            </>
                            :
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                {
                                    entity?.twitter != undefined
                                    ?
                                    <>
                                        <DetailsRow>
                                            <DetailsItem 
                                                hasIcon={false} 
                                                icon={``} 
                                                lable={"URL"} 
                                                description={entity?.twitter?.url} 
                                            />
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsItem 
                                                hasIcon={false} 
                                                icon={``} 
                                                lable={"API Key"} 
                                                description={defaults.capitalize(entity?.twitter?.api_key)} 
                                            />
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsItem 
                                                hasIcon={false} 
                                                icon={``} 
                                                lable={"API Key Secret"} 
                                                description={defaults.capitalize(entity?.twitter?.api_key_secret)} 
                                            />
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsItem 
                                                hasIcon={false} 
                                                icon={``} 
                                                lable={"Bearer Token"} 
                                                description={defaults.capitalize(entity?.twitter?.bearer_token)} 
                                            />
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsItem 
                                                hasIcon={false} 
                                                icon={``} 
                                                lable={"Access Token"} 
                                                description={defaults.capitalize(entity?.twitter?.access_token)} 
                                            />
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsItem 
                                                hasIcon={false} 
                                                icon={``} 
                                                lable={"Access Token Secret"} 
                                                description={defaults.capitalize(entity?.twitter?.access_token_secret)} 
                                            />
                                        </DetailsRow>
                                    </>
                                    
                                    :
                                    <></>
                                }
                            </PlainContainer>
                        }
                    </Card>
                </PlainContainer> */}
                
            </PlainContainer>

            {
                showAddDirectorPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Add Director of District" closePopUp={() => handleConcludeAddDirectorPopUp({status: false})}>
                    <AddDirectorPopUp user={user} district={district} concludeAddDistrictPopUp={(e) => handleConcludeAddDirectorPopUp(e)} />
                </PopUp>
                :
                <></>
            }
              {/* {
                showAddTwitterApiPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Add New Twitter Api" closePopUp={() => handleConcludeAddTwitterApiPopUp({status: false})}>
                    <AddTwitterApiPopUp user={user} twitter={entity} concludeAddTwitterApiPopUp={(e) => handleConcludeAddTwitterApiPopUp(e)} />
                </PopUp>
                :
                <></>
            } */}
        </>
    )
}

export default DDDistrictDetailsPage