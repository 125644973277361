import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DefaultHeader from '../../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../../../components/core/dashboard/Button';
import { icons } from '../../../../../constants';
import PlainContainer from '../../../../../components/core/containers/PlainContainer';
import Card from '../../../../../components/core/dashboard/Card';
import Table from '../../../../../components/core/table/containers/Table';
import TableHeading from '../../../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../../../components/core/table/containers/TableHeadingItem';
import TableRows from '../../../../../components/core/table/containers/TableRows';
import TableRow from '../../../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../../../components/core/table/inputs/Button';
// import DistrictsListFilters from './DistrictsListFilters';
import TalukServices from '../../../../../services/Location/Taluk';
import { ToastAlert } from '../../../../../utils';
import Pagination from '../../../../../components/core/table/pagination';
import DistrictServices from '../../../../../services/Location/District';
import AdminServices from '../../../../../services/Admin';
import { DDServices, EntityServices, SchoolServices, StatsServices } from '../../../../../services';
import IncompleteInstitutionsFilter from '../IncompleteInstitutionsFilter';
// import EntityListFilters from './EntityListFilters';

const EntityWithNoTwitterPasswordListHelplinePage = ({ user }) => {
    const navigate = useNavigate();

    const [entity, setEntity] = useState([]);
    const [totalEntityCount, setTotalEntityCount] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [districts, setDistricts]=useState([]);
    const [taluks, setTaluks] = useState([]);

    const [district, setDistrict] = useState('ALL');
    const [taluk, setTaluk] = useState('ALL');
    const [institutionType, setInstitutionType] = useState("ALL");

    const handleGetPublicDistrictsRequest = async () => {
        const getDistrictsReq = await DistrictServices.getDropdownDistricts({
            token: user.access_token
        });

        if(getDistrictsReq.status === false){
            ToastAlert.notifyError(getDistrictsReq.message);
            setDistricts([]);
        }
        else{
            setDistricts(getDistrictsReq.data);
        }
    }

    useEffect(() => {
        handleGetPublicDistrictsRequest();
    }, []);

    
    useEffect(() => {
        // console.log(districts);
    }, [districts])

    const handleGetPublicTaluksReq = async () => {
        const getTaluksReq = await TalukServices.getDropdownTaluks({
            district:district,
            token: user.access_token
        })
      

        if(getTaluksReq.status === false){
            ToastAlert.notifyError(getTaluksReq.message);   
            setTaluks([]);
        }
        else{
            setTaluks(getTaluksReq.data);
        }
    }

    useEffect(()=>{
        if(district != 0){
            handleGetPublicTaluksReq()
        }
        else{
            setDistricts([]);
        }
    },[district])

    useEffect(() => {
        // console.log(taluks);
    }, [taluks]);

    const handleGetEntityRequest = async () => {

        const getEntityReq = await StatsServices.getInstitutionsWithNoTwitterPassword({
            token: user.access_token,
            current_page: currentPage,
            district: district,
            taluk: taluk,
            institution_type: institutionType
        });

        if(getEntityReq.status === false){
            ToastAlert.notifyError(getEntityReq.message);
            setEntity([]);
            setTotalEntityCount(0);
            setTotalPagesCount(0);
            setCurrentPage(1);
        }
        else{
            setEntity(getEntityReq?.data?.items);
            setTotalEntityCount(getEntityReq.data.total_items);
            setTotalPagesCount(getEntityReq.data.total_pages);
            setCurrentPage(getEntityReq.data?.current_page === undefined ? 1 : getEntityReq.data.current_page);
        }
    }

    useEffect(() => {
        handleGetEntityRequest();
    }, [currentPage, district, taluk, institutionType]);

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Institutions With No Twitter Password'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='List of Institutions Missing Twitter Password.'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.leftArrowTail}
                        action={()=> navigate(-1) }
                    >
                        Go Back
                    </Button>
                </HeaderActions>
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        <PlainContainer type="full" styles={{paddingTop: "15px", paddingRight: "25px", paddingBottom: "25px", paddingLeft: "25px"}}>
                            <IncompleteInstitutionsFilter
                                districts={districts} 
                                district={district}
                                DistrictTypeChanged={setDistrict}
                                taluks={taluks} 
                                taluk={taluk}
                                TalukTypeChanged={setTaluk}
                                institution_type={institutionType}
                                InstitutionTypeChanged={setInstitutionType} 
                            />
                        </PlainContainer>
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    {/* <TableHeadingItem size={0} hasChildren={true}>
                                        <TableCheckbox name={"items-selected-ckb"} />
                                    </TableHeadingItem> */}
                                    <TableHeadingItem size={4} lable={"name"} />
                                    <TableHeadingItem size={1} lable={"district"} />
                                    <TableHeadingItem size={1} lable={"taluk"} />
                                    <TableHeadingItem size={1} lable={"organization Type"} />
                                    {/* <TableHeadingItem size={1} lable={"no. of cities"} /> */}
                                    <TableHeadingItem size={1} lable={"Actions"} />
                                </TableHeading>
                                <TableRows>
                                    {
                                        entity.map((entity, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    {/* <TableRowItem size={0} hasChildren={true} isCenter={false}>
                                                        <TableCheckbox name={`items-selected-ckb-${1}`} />
                                                    </TableRowItem> */}
                                                    <TableRowItem size={4} isCenter={false} hasPreview={false} lable={entity?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={entity?.district?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={entity?.taluk?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={entity?.organization_type} />
                                                    <TableRowItem size={1} isCenter={false} hasChildren={true}>
                                                        <TableRowActions>
                                                            <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => navigate(`/masterdata/institutions/${entity.id}`) }>edit</TableButton>
                                                        </TableRowActions>
                                                    </TableRowItem>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                        {
                            totalPagesCount > 1
                            ?
                                <PlainContainer type='full' styles={{ padding: "0px 25px" }}>
                                    <Pagination totalPages={totalPagesCount} setCurrentPage={setCurrentPage}/>
                                </PlainContainer>
                            :
                                <></>
                        }
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default EntityWithNoTwitterPasswordListHelplinePage