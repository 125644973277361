import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Card from '../../../components/core/dashboard/Card';
import DetailsRow from '../../../components/core/containers/DetailsRow';
import DetailsItem from '../../../components/core/containers/DetailsItem';
import { defaults, ToastAlert, functions, moments, globals } from '../../../utils';
import { DDServices, EntityServices, ProductServices } from '../../../services';
import PopUp from '../../../components/core/popup';
import NoDataContainer from '../../../components/core/containers/NoDataContainer';
import AddWardenPopUp from '../../../components/dashboard/popups/WardenPage/AddHostelWardenPopUp';
import AddTwitterApiPopUp from '../../../components/dashboard/popups/TwitterPage';

const DDEntityDetailsPage = ({ user }) => {
    const navigate = useNavigate();
    const params = useParams();

    const [entity, setEntity] = useState(null);

    const handleGetEntityRequest = async () => {
        const getEntityReq = await DDServices.getThisAuthEntity({
            id: params.id,
            token: user.access_token
        });

        if(getEntityReq.status === false){
            ToastAlert.notifyError(getEntityReq.message);
            setEntity(null);
        }
        else{
            setEntity(getEntityReq.data);
        }
    }

    useEffect(() => {
        handleGetEntityRequest();
    }, []);

    const [showAddWardenPopUp, setShowAddWardenPopUp] = useState(false);

    const handleConcludeAddWardenPopUp = async (e) => {
        if(e.status === false){
            setShowAddWardenPopUp(false);
        }
        else{
            setShowAddWardenPopUp(false);
            handleGetEntityRequest();
        }
    }

    const [showAddTwitterApiPopUp, setShowAddTwitterApiPopUp] = useState(false);

    const handleConcludeAddTwitterApiPopUp = async (e) => {
        if(e.status === false){
            setShowAddTwitterApiPopUp(false);
        }
        else{
            setShowAddTwitterApiPopUp(false);
            handleGetEntityRequest();
        }
    }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Institution Details'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to the Institution in this page'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                <Card hasHeading={true} heading={"Basic Details"} headingActionIcon={icons.addSolidCircle} headingAction={() => navigate(`/masterdata/institutions/edit/${entity.id}`)} isBottomAction={false}>
                    {
                        entity == null
                        ?
                        <>
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <NoDataContainer
                                    icon={icons.sad}
                                    heading={"Basic Details"}
                                    description={"Update School details by clicking on the below button."}
                                    hasSectionAction={false}
                                    sectionAction={() => alert("section add")}
                                    hasActions={true}
                                    hasPrimaryAction={true}
                                    primaryActionLable={"update"}
                                    hasSecondaryAction={true}
                                    secondaryActionLable={"refresh"}
                                    secondaryAction={() => handleGetEntityRequest("refresh")}
                                />
                            </PlainContainer>
                        </>
                        :
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            {
                                 entity?.name != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"Name"} 
                                        description={defaults.capitalize(entity?.name?.en)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                 entity?.district != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"district"} 
                                        description={defaults.capitalize(entity?.district?.name?.en)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                               entity?.taluk != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"taluk"} 
                                        description={defaults.capitalize(entity?.taluk?.name?.en)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                entity?.organization_type === "SCHOOL"
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"Organization Type"} 
                                        description={defaults.capitalize(entity?.organization_type)} 
                                    />
                                </DetailsRow>
                                :
                                <>
                                {
                                entity?.hostel_type!= ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"Hostel Type"} 
                                        description={defaults.capitalize(entity?.hostel_type)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            </>
                            }
                        </PlainContainer>
                    }
                </Card>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Warden"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddWardenPopUp(true)}  isBottomAction={false}>
                        {
                            entity?.warden === null
                            ?
                            <>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No warden Details found!"}
                                        description={"Update warden details by clicking on the below button."}
                                        hasSectionAction={false}
                                        sectionAction={() => alert("section add")}
                                        hasActions={true}
                                        hasPrimaryAction={true}
                                        primaryActionLable={"update"}
										hasSecondaryAction={true}
										secondaryActionLable={"refresh"}
                                        secondaryAction={() => handleGetEntityRequest("refresh")}
                                    />
                                </PlainContainer>
                            </>
                            :
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                {
                                    entity != ""
                                    ?
                                    <>
                                        <DetailsRow>
                                            <DetailsItem 
                                                hasIcon={false} 
                                                icon={``} 
                                                lable={"name"} 
                                                description={defaults.capitalize(entity?.warden?.first_name)} 
                                            />
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsItem 
                                                hasIcon={false} 
                                                icon={``} 
                                                lable={"Email ID"} 
                                                description={entity?.warden?.email_data?.email_id} 
                                            />
                                        </DetailsRow>
                                    </>
                                    :
                                    <></>
                                }

                                {/* {
                                    school?.sitemap?.image != undefined
                                    ?
                                    <DetailsRow>
                                        <DetailsItemWithPreview 
                                            lable={`Image`}
                                            preview={`${globals.API_BASE_URL}${school?.sitemap?.image?.image_url?.full?.high_res}`}
                                            previewType={"image"}
                                            fileName={school?.sitemap?.image?.name?.original}
                                            fileExtension={school?.sitemap?.image?.extension?.original}
                                            fileSize={school?.sitemap?.image?.size?.current}
                                        />
                                    </DetailsRow>
                                    :
                                    <></>
                                } */}
                            </PlainContainer>
                        }
                    </Card>
                </PlainContainer>
                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Manage Twitter API"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddTwitterApiPopUp(true)}  isBottomAction={false}>
                        {
                            entity?.twitter === undefined
                            ?
                            <>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No Twitter API Details found!"}
                                        description={"Update Twitter API details by clicking on the below button."}
                                        hasSectionAction={false}
                                        sectionAction={() => alert("section add")}
                                        hasActions={true}
                                        hasPrimaryAction={true}
                                        primaryActionLable={"update"}
                                        primaryAction={()=> setShowAddTwitterApiPopUp(true)}
										hasSecondaryAction={true}
										secondaryActionLable={"refresh"}
                                        secondaryAction={() => handleGetEntityRequest("refresh")}
                                    />
                                </PlainContainer>
                            </>
                            :
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                {
                                    entity?.twitter != undefined
                                    ?
                                    <>
                                        <DetailsRow>
                                            <DetailsItem 
                                                hasIcon={false} 
                                                icon={``} 
                                                lable={"URL"} 
                                                description={entity?.twitter?.url} 
                                            />
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsItem 
                                                hasIcon={false} 
                                                icon={``} 
                                                lable={"API Key"} 
                                                description={defaults.capitalize(entity?.twitter?.api_key)} 
                                            />
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsItem 
                                                hasIcon={false} 
                                                icon={``} 
                                                lable={"API Key Secret"} 
                                                description={defaults.capitalize(entity?.twitter?.api_key_secret)} 
                                            />
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsItem 
                                                hasIcon={false} 
                                                icon={``} 
                                                lable={"Bearer Token"} 
                                                description={defaults.capitalize(entity?.twitter?.bearer_token)} 
                                            />
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsItem 
                                                hasIcon={false} 
                                                icon={``} 
                                                lable={"Access Token"} 
                                                description={defaults.capitalize(entity?.twitter?.access_token)} 
                                            />
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsItem 
                                                hasIcon={false} 
                                                icon={``} 
                                                lable={"Access Token Secret"} 
                                                description={defaults.capitalize(entity?.twitter?.access_token_secret)} 
                                            />
                                        </DetailsRow>
                                    </>
                                    
                                    :
                                    <></>
                                }
                            </PlainContainer>
                        }
                    </Card>
                </PlainContainer>
                
            </PlainContainer>

            {
                showAddWardenPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Add Email Of Warden" closePopUp={() => handleConcludeAddWardenPopUp({status: false})}>
                    <AddWardenPopUp user={user} entity={entity} concludeAddWardenPopUp={(e) => handleConcludeAddWardenPopUp(e)} />
                </PopUp>
                :
                <></>
            }
              {
                showAddTwitterApiPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Add New Twitter Api" closePopUp={() => handleConcludeAddTwitterApiPopUp({status: false})}>
                    <AddTwitterApiPopUp user={user} twitter={entity} concludeAddTwitterApiPopUp={(e) => handleConcludeAddTwitterApiPopUp(e)} />
                </PopUp>
                :
                <></>
            }
        </>
    )
}

export default DDEntityDetailsPage