import React, { useEffect } from 'react';
import SuperAdminLayoutNavBar from './Navbar';
import ContentSidebarFooter from './ContentSidebarFooter';
import ContentContainer from './ContentContainer';
import SuperAdminLayoutHeader from './Header';
import SuperAdminLayoutFooter from './Footer';

const SuperAdminLayout = ({ user, children }) => {
    return (
        <div className="dashboardPage">
            <SuperAdminLayoutNavBar user={user} />
            <ContentSidebarFooter>
                <ContentContainer>
                    <SuperAdminLayoutHeader user={user} />
                    {children}
                </ContentContainer>
                <SuperAdminLayoutFooter />
            </ContentSidebarFooter>
        </div>
    )
}

export default SuperAdminLayout