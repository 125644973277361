import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Card from '../../../components/core/dashboard/Card';
import CardHeading from '../../../components/core/typography/dashboard/CardHeading';
import CardDescription from '../../../components/core/typography/dashboard/CardDescription';
import TextInputRT from '../../../components/core/form/TextInputRT';
import FormActions from '../../../components/form/FormActions';
import { ToastAlert, validations } from '../../../utils';
import MediaTypeServices from '../../../services/MediaType';

const AddMediaTypePage = ({ user }) => {
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [hasNameIcon, setHasNameIcon] = useState(false);
    const [nameIcon, setNameIcon] = useState("");
    const [nameIconPosition, setNameIconPosition] = useState("right");
    const [nameIconType, setNameIconType] = useState("");
    const [hasNameMessage, setHasNameMessage] = useState(false);
    const [nameMessage, setNameMessage] = useState("");
    const [nameMessageType, setNameMessageType] = useState("");

    const [iconClass, setIconClass] = useState("");
    const [hasIconClassIcon, setHasIconClassIcon] = useState(false);
    const [iconClassIcon, setIconClassIcon] = useState("");
    const [iconClassIconPosition, setIconClassIconPosition] = useState("right");
    const [iconClassIconType, setIconClassIconType] = useState("");
    const [hasIconClassMessage, setHasIconClassMessage] = useState(false);
    const [iconClassMessage, setIconClassMessage] = useState("");
    const [iconClassMessageType, setIconClassMessageType] = useState("");

    const [maxUploadSize, setMaxUploadSize] = useState("");
    const [hasMaxUploadSizeIcon, setHasMaxUploadSizeIcon] = useState(false);
    const [maxUploadSizeIcon, setMaxUploadSizeIcon] = useState("");
    const [maxUploadSizeIconPosition, setMaxUploadSizeIconPosition] = useState("right");
    const [maxUploadSizeIconType, setMaxUploadSizeIconType] = useState("");
    const [hasMaxUploadSizeMessage, setHasMaxUploadSizeMessage] = useState(false);
    const [maxUploadSizeMessage, setMaxUploadSizeMessage] = useState("");
    const [maxUploadSizeMessageType, setMaxUploadSizeMessageType] = useState("");

    const nameChanged = async (e) => {
        const isNameValid = await validations.disableSpecialCharacterStrict(e);
        if(isNameValid.status){
            setName(e);
        }
        else if(e.length === 0){
            setName('');
        }
    }

    const sizeChanged = async (e) => {
        const isSizeValid = await validations.allowOnlyNumbers(e);
        if(isSizeValid.status){
            setMaxUploadSize(e);
        }
        else if(e.length === 0){
            setMaxUploadSize("");
        }
    }

    const [submitButtonHasIcon, setSubmitButtonHasIcon] = useState(false);
    const [submitButtonIconPosition, setSubmitButtonIconPosition] = useState('left');
    const [submitButtonIco, setSubmitButtonIco] = useState(icons.tick);
    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleAddMediaTypeSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        const isNameValid = await validations.validateNameStrict(name);

        if(isNameValid.status === false){
            setHasNameIcon(true);
            setNameIcon(icons.wrongCircle);
            setNameIconType("error");
            setHasNameMessage(true);
            setNameMessage("Invalid Name");
            setNameMessageType("error");
        }
        else{
            setHasNameIcon(true);
            setNameIcon(icons.tickCircle);
            setNameIconType("success");
            setHasNameMessage(false);
            setNameMessage("");
            setNameMessageType("");
        }

        if(maxUploadSize === ""){
            setHasMaxUploadSizeIcon(true);
            setMaxUploadSizeIcon(icons.wrongCircle);
            setMaxUploadSizeIconType("error");
            setHasMaxUploadSizeMessage(true);
            setMaxUploadSizeMessage("Invalid Max Upload Size");
            setMaxUploadSizeMessageType("error");
        }
        else{
            setHasMaxUploadSizeIcon(true);
            setMaxUploadSizeIcon(icons.tickCircle);
            setMaxUploadSizeIconType("success");
            setHasMaxUploadSizeMessage(false);
            setMaxUploadSizeMessage("");
            setMaxUploadSizeMessageType("");
        }

        if(iconClass === ""){
            setHasIconClassIcon(true);
            setIconClassIcon(icons.wrongCircle);
            setIconClassIconType("error");
            setHasIconClassMessage(true);
            setIconClassMessage("Invalid Icon Class");
            setIconClassMessageType("error");
        }
        else{
            setHasIconClassIcon(true);
            setIconClassIcon(icons.tickCircle);
            setIconClassIconType("success");
            setHasIconClassMessage(false);
            setIconClassMessage("");
            setIconClassMessageType("");
        }

        if(isNameValid.status === false || iconClass === ""){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveMediaTypeReq = await MediaTypeServices.saveMediaType({
                name: name,
                icon: iconClass,
                max_upload_size: maxUploadSize,
                token: user.access_token
            });

            if(saveMediaTypeReq.status === false){
                ToastAlert.notifyError(saveMediaTypeReq.message);
                setSubmitButtonLoading(false);
                setHasNameIcon(false);
                setNameIcon("");
                setNameIconType("");
                setHasMaxUploadSizeIcon(false);
                setMaxUploadSizeIcon("");
                setMaxUploadSizeIconType("");
                setHasIconClassIcon(false);
                setIconClassIcon("");
                setIconClassIconType("");
            }
            else{
                ToastAlert.notifySuccess(saveMediaTypeReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonHasIcon(true);
                setSubmitButtonLable('Success');
                navigate(-1);
            }
        }
    }

    return(
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Media Types'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='List of Media Types using which files will be uploaded later on.'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.list}
                        action={()=> navigate('/masterdata/media-types') }
                    >
                        View Media Types
                    </Button>
                </HeaderActions>
            </DefaultHeader>
            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type='full' styles={{paddingTop: "15px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                        <CardHeading heading={"add new Media Type"} color="dark" />
                        <CardDescription description={"Types of media files which can be uploaded using CMS"} />

                        <PlainContainer type='full' styles={{marginTop: "30px"}}>
                            <form onSubmit={(e) => handleAddMediaTypeSubmission(e)} noValidate>
                                <div className="cmsForm">
                                    <SplitContainer>
                                        <TextInputRT 
                                            hasLable={true}
                                            lable="Name"
                                            isMandatory={true}
                                            hasIcon={hasNameIcon}
                                            icon={nameIcon}
                                            iconPosition={nameIconPosition}
                                            iconType={nameIconType}
                                            hasMessage={hasNameMessage}
                                            message={nameMessage}
                                            messageType={nameMessageType}
                                        >
                                            <input 
                                                type="text" 
                                                className="cmsFormStepInputText" 
                                                placeholder={"Enter Name"}
                                                onChange={(e) => nameChanged(e.target.value)}
                                                value={name}
                                            />
                                        </TextInputRT>
                                        <TextInputRT 
                                            hasLable={true}
                                            lable="max upload size"
                                            isMandatory={true}
                                            hasIcon={hasMaxUploadSizeIcon}
                                            icon={maxUploadSizeIcon}
                                            iconPosition={maxUploadSizeIconPosition}
                                            iconType={maxUploadSizeIconType}
                                            hasMessage={hasMaxUploadSizeMessage}
                                            message={maxUploadSizeMessage}
                                            messageType={maxUploadSizeMessageType}
                                        >
                                            <input 
                                                type="text" 
                                                className="cmsFormStepInputText" 
                                                placeholder={"Enter Size in MB"}
                                                onChange={(e) => sizeChanged(e.target.value)}
                                                value={maxUploadSize}
                                            />
                                        </TextInputRT>
                                    </SplitContainer>

                                    <TextInputRT 
                                        hasLable={true}
                                        lable="Icon Class"
                                        isMandatory={true}
                                        hasIcon={hasIconClassIcon}
                                        icon={iconClassIcon}
                                        iconPosition={iconClassIconPosition}
                                        iconType={iconClassIconType}
                                        hasMessage={hasIconClassMessage}
                                        message={iconClassMessage}
                                        messageType={iconClassMessageType}
                                    >
                                        <input 
                                            type="text" 
                                            className="cmsFormStepInputText" 
                                            placeholder={"Enter Icon Class"}
                                            onChange={(e) => setIconClass(e.target.value)}
                                            value={iconClass}
                                        />
                                    </TextInputRT>
                                    
                                    <FormActions>
                                        <Button 
                                            type="submit"
                                            bgType="fill"
                                            width="auto"
                                            bg={submitButtonBg}
                                            borderRadius="short"
                                            hasIcon={submitButtonHasIcon}
                                            iconPosition={submitButtonIconPosition}
                                            icon={submitButtonIco}
                                            disabled={submitButtonDisabled}
                                            isLoading={submitButtonLoading}
                                        >
                                            {submitButtonLable}
                                        </Button>
                                        <Button 
                                            type="button"
                                            bgType="fill"
                                            width="auto"
                                            bg={cancelButtonBg}
                                            borderRadius="short"
                                            action={() => navigate(-1)}
                                        >
                                            {cancelButtonLable}
                                        </Button>
                                    </FormActions>
                                </div>
                            </form>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default AddMediaTypePage