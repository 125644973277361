import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { icons } from '../../../../constants';
import DefaultHeader from '../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../../components/core/dashboard/Button';
import BasicStats from '../../../../components/dashboard/common/BasicStats';
import BasicStat from '../../../../components/dashboard/common/BasicStats/BasicStat';
import PlainContainer from '../../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../../components/core/containers/SplitContainer';
import Table from '../../../../components/core/table/containers/Table';
import TableHeading from '../../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../../components/core/table/containers/TableHeadingItem';
import TableCheckbox from '../../../../components/core/table/inputs/Checkbox';
import TableRows from '../../../../components/core/table/containers/TableRows';
import TableRow from '../../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../../components/core/table/containers/TableRowItem';
import Pagination from '../../../../components/core/table/pagination';
import { globals } from '../../../../utils';
import Card from '../../../../components/core/dashboard/Card';
import BasicListItem from '../../../../components/dashboard/common/Requests/BasicListItem';
import BasicActivitiesList from '../../../../components/dashboard/common/Activities/BasicActivitiesList';
import BasicActivityListItem from '../../../../components/dashboard/common/Activities/BasicActivityListItem';
import { HelplineServices, DistrictServices, TalukServices, StatsServices, TweetServices } from '../../../../services';
import { ToastAlert } from '../../../../utils';
import IncompleteInstitutionsFilter from './IncompleteInstitutionsFilter';
import TableRowIcons from '../../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../../components/core/table/inputs/Button';

const HelplineIncompleteInstitutionsPage = ({user}) => {
    const navigate = useNavigate();

    const [stats, setStats] = useState({});
    const [districtStats, setDistrictStats] = useState([]);

    const [institutionType, setInstitutionType] = useState("ALL");

    const [districts, setDistricts]=useState([]);
    const [taluks, setTaluks] = useState([]);

    const [district, setDistrict] = useState('ALL');
    const [taluk, setTaluk] = useState('ALL');

    const [noWardens, setNoWardens] = useState([]);
    const [noTwitterIds, setNoTwitterIds] = useState([]);
    const [noTwitterPasswords, setNoTwitterPasswords] = useState([]);

    const handleGetPublicDistrictsRequest = async () => {
        const getDistrictsReq = await DistrictServices.getDropdownDistricts({
            token: user.access_token
        });

        if(getDistrictsReq.status === false){
            ToastAlert.notifyError(getDistrictsReq.message);
            setDistricts([]);
        }
        else{
            setDistricts(getDistrictsReq.data);
        }
    }

    useEffect(() => {
        handleGetPublicDistrictsRequest();
    }, []);

    
    useEffect(() => {
        // console.log(districts);
    }, [districts])

    const handleGetPublicTaluksReq = async () => {
        const getTaluksReq = await TalukServices.getDropdownTaluks({
            district:district,
            token: user.access_token
        })
      

        if(getTaluksReq.status === false){
            ToastAlert.notifyError(getTaluksReq.message);   
            setTaluks([]);
        }
        else{
            setTaluks(getTaluksReq.data);
        }
    }

    useEffect(()=>{
        if(district != 0){
            handleGetPublicTaluksReq()
        }
        else{
            setDistricts([]);
        }
    },[district])

    useEffect(() => {
        // console.log(taluks);
    }, [taluks]);

    const handleGetThisStatReq = async () => {
        const getThisStatReq = await StatsServices.getIncompleteInstitutesStats({
            token:user.access_token,
            district: district,
            taluk: taluk,
            institution_type: institutionType
        });

        // console.log(getThisStatReq);
        
        if(getThisStatReq.status === false){
            ToastAlert.notifyError(getThisStatReq.message);
            setStats({});
        }
        else{
            setStats(getThisStatReq?.data);
        }
    }

    const handleGetThisIncompleteInstitutionsReq = async () => {
        const getThisIncompleteInstitutesListReq = await StatsServices.getLimitedIncompleteInstitutes({
            token:user.access_token,
            district: district,
            taluk: taluk,
            institution_type: institutionType
        });

        // console.log(getThisIncompleteInstitutesListReq);
        
        if(getThisIncompleteInstitutesListReq.status === false){
            ToastAlert.notifyError(getThisIncompleteInstitutesListReq.message);
            setNoWardens([]);
            setNoTwitterIds([]);
            setNoTwitterPasswords([]);
        }
        else{
            setNoWardens(getThisIncompleteInstitutesListReq.data.no_warden_orgs);
            setNoTwitterIds(getThisIncompleteInstitutesListReq.data.no_twitter_url_orgs);
            setNoTwitterPasswords(getThisIncompleteInstitutesListReq.data.no_twitter_password_orgs);
            // setStats(getThisStatReq?.data);
        }
    }

    useEffect(()=>{
        handleGetThisStatReq();
        handleGetThisIncompleteInstitutionsReq();
    },[district, taluk, institutionType]);

    const handleGetAllDistrictIncompleteStatReq = async () => {
        const getThisStatReq = await StatsServices.getAllDistrictsIncompleteInstitutesStats({
            token: user.access_token
        });

        // console.log(getThisStatReq);
        
        if(getThisStatReq.status === false){
            ToastAlert.notifyError(getThisStatReq.message);
            setDistrictStats([]);
        }
        else{
            setDistrictStats(getThisStatReq?.data);
        }
    }

    useEffect(()=>{
        handleGetAllDistrictIncompleteStatReq();
    }, []);

    return(
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Incomplete Institutions'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Identify the institutions which lacks basic information such as Warden, Twitter ID / Password, Etc..,'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.list}
                        action={()=> navigate('/masterdata/institutions') }
                    >
                        View All Institutions
                    </Button>
                </HeaderActions>
            </DefaultHeader>

            <BasicStats>
                <BasicStat 
                    cardHeading={"No Warden"}
                    statCount={stats?.no_warden_orgs_count}
                    statLable="Missing Wardens"
                    cardStatColor="blue"
                    secondaryStatLable="Under Progress"
                    secondaryStatCount={20}
                    statId={`websites`}
                    headingActionIcon={icons.rightArrow}
                    headingAction={() => navigate("/reports/incomplete-institutions/no-warden")}
                />
                <BasicStat 
                    cardHeading={"No Twitter ID"}
                    statCount={stats?.no_twitter_url_orgs_count}
                    statLable="Missing Twitter IDs"
                    cardStatColor="red"
                    secondaryStatLable="Feature Updates"
                    secondaryStatCount={12}
                    statId={`pendingRequests`}
                    headingActionIcon={icons.rightArrow}
                    headingAction={() => navigate("/reports/incomplete-institutions/twitter-id")}
                />
                <BasicStat 
                    cardHeading={"No Twitter Password"}
                    statCount={stats?.no_twitter_password_orgs_count}
                    statLable="Missing Twitter Passwords"
                    cardStatColor="yellow"
                    secondaryStatLable="Regular"
                    secondaryStatCount={20}
                    statId={`pendingApprovals`}
                    headingActionIcon={icons.rightArrow}
                    headingAction={() => navigate("/reports/incomplete-institutions/twitter-password")}
                />
            </BasicStats>

            <PlainContainer type="full" styles={{ marginBottom: '30px', paddingLeft: '30px', paddingRight: '30px' }}>
                <Card 
                    heading={"District Wise Incomplete Institutions Count"} 
                    isHeadingAction={false} 
                    isBottomAction={false} 
                    isBottomPrimaryAction={false} 
                    bottomPrimaryActionLable={""}
                    bottomPrimaryAction={() => navigate("/")}
                >
                    <PlainContainer type="full" styles={{paddingBottom: "10px"}}>
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={3} lable={"district"} />
                                    <TableHeadingItem size={1} lable={"no warden"} />
                                    <TableHeadingItem size={1} lable={"no twitter ID"} />
                                    <TableHeadingItem size={1} lable={"no twitter password"} />
                                </TableHeading>
                                <TableRows>
                                    {
                                        districtStats.map((entity, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    <TableRowItem size={3} isCenter={false} lable={entity?.district_name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={entity?.number_of_incomplete_warden} />
                                                    <TableRowItem size={1} isCenter={false} lable={entity?.number_of_incomplete_twitter_id} />
                                                    <TableRowItem size={1} isCenter={false} lable={entity?.number_of_incomplete_twitter_password} />
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>

            <PlainContainer type="full" styles={{ paddingLeft: '30px', paddingRight: '30px' }}>
                <Card 
                    hasHeading={false}
                    heading={""} 
                    isHeadingAction={false} 
                    isBottomAction={false} 
                    isBottomPrimaryAction={false} 
                >
                    <PlainContainer type="full" styles={{paddingTop: "15px", paddingRight: "25px", paddingBottom: "25px", paddingLeft: "25px"}}>
                        <IncompleteInstitutionsFilter
                            districts={districts} 
                            district={district}
                            DistrictTypeChanged={setDistrict}
                            taluks={taluks} 
                            taluk={taluk}
                            TalukTypeChanged={setTaluk}
                            institution_type={institutionType}
                            InstitutionTypeChanged={setInstitutionType} 
                        />
                    </PlainContainer>
                </Card>
            </PlainContainer>

            <PlainContainer type="full" styles={{ marginTop: '30px', paddingLeft: '30px', paddingRight: '30px' }}>
                <Card 
                    heading={"List of Institutions Missing Wardens"} 
                    isHeadingAction={false} 
                    isBottomAction={true} 
                    isBottomPrimaryAction={true} 
                    bottomPrimaryActionLable={"see all institutions missing wardens"}
                    bottomPrimaryAction={() => navigate("/reports/incomplete-institutions/no-warden")}
                >
                    <PlainContainer type="full">
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={4} lable={"name"} />
                                    <TableHeadingItem size={1} lable={"district"} />
                                    <TableHeadingItem size={1} lable={"taluk"} />
                                    <TableHeadingItem size={1} lable={"organization Type"} />
                                    <TableHeadingItem size={1} lable={"Actions"} />
                                </TableHeading>
                                <TableRows>
                                    {
                                        noWardens.map((entity, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    <TableRowItem size={4} isCenter={false} hasPreview={false} lable={entity?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={entity?.district?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={entity?.taluk?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={entity?.organization_type} />
                                                    <TableRowItem size={1} isCenter={false} hasChildren={true}>
                                                        <TableRowActions>
                                                            <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => navigate(`/masterdata/institutions/${entity.id}`) }>edit</TableButton>
                                                        </TableRowActions>
                                                    </TableRowItem>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>

            <PlainContainer type="full" styles={{ marginTop: '30px', paddingLeft: '30px', paddingRight: '30px' }}>
                <Card 
                    heading={"List of Institutions Missing Twitter IDs"} 
                    isHeadingAction={false} 
                    isBottomAction={true} 
                    isBottomPrimaryAction={true} 
                    bottomPrimaryActionLable={"see all institutions missing twitter IDs"}
                    bottomPrimaryAction={() => navigate("/reports/incomplete-institutions/twitter-id")}
                >
                    <PlainContainer type="full">
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={4} lable={"name"} />
                                    <TableHeadingItem size={1} lable={"district"} />
                                    <TableHeadingItem size={1} lable={"taluk"} />
                                    <TableHeadingItem size={1} lable={"organization Type"} />
                                    <TableHeadingItem size={1} lable={"Actions"} />
                                </TableHeading>
                                <TableRows>
                                    {
                                        noTwitterIds.map((entity, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    <TableRowItem size={4} isCenter={false} hasPreview={false} lable={entity?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={entity?.district?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={entity?.taluk?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={entity?.organization_type} />
                                                    <TableRowItem size={1} isCenter={false} hasChildren={true}>
                                                        <TableRowActions>
                                                            <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => navigate(`/masterdata/institutions/${entity.id}`) }>edit</TableButton>
                                                        </TableRowActions>
                                                    </TableRowItem>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>

            <PlainContainer type="full" styles={{ marginTop: '30px', paddingLeft: '30px', paddingRight: '30px', paddingBottom: '30px' }}>
                <Card 
                    heading={"List of Institutions Missing Twitter Passwords"} 
                    isHeadingAction={false} 
                    isBottomAction={true} 
                    isBottomPrimaryAction={true} 
                    bottomPrimaryActionLable={"see all institutions missing twitter Passwords"}
                    bottomPrimaryAction={() => navigate("/reports/incomplete-institutions/twitter-password")}
                >
                    <PlainContainer type="full">
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={4} lable={"name"} />
                                    <TableHeadingItem size={1} lable={"district"} />
                                    <TableHeadingItem size={1} lable={"taluk"} />
                                    <TableHeadingItem size={1} lable={"organization type"} />
                                    <TableHeadingItem size={1} lable={"Actions"} />
                                </TableHeading>
                                <TableRows>
                                    {
                                        noTwitterPasswords.map((entity, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    <TableRowItem size={4} isCenter={false} hasPreview={false} lable={entity?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={entity?.district?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={entity?.taluk?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={entity?.organization_type} />
                                                    <TableRowItem size={1} isCenter={false} hasChildren={true}>
                                                        <TableRowActions>
                                                            <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => navigate(`/masterdata/institutions/${entity.id}`) }>edit</TableButton>
                                                        </TableRowActions>
                                                    </TableRowItem>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default HelplineIncompleteInstitutionsPage