import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Card from '../../../components/core/dashboard/Card';
import CardHeading from '../../../components/core/typography/dashboard/CardHeading';
import CardDescription from '../../../components/core/typography/dashboard/CardDescription';
import TextInputRT from '../../../components/core/form/TextInputRT';
import FormActions from '../../../components/form/FormActions';
import { ToastAlert, validations } from '../../../utils';
import SelectInput from '../../../components/core/form/SelectInput';
import RadioButtonGroup from '../../../components/core/form/RadioButtonGroup';
import RadioButtonInput from '../../../components/core/form/RadioButtonInput';
import DistrictServices from '../../../services/Location/District';
import TalukServices from '../../../services/Location/Taluk';
import { EntityServices, SchoolServices, TweetServices } from '../../../services';
import TextAreaInput from '../../../components/core/form/TextAreaInput';
import FileUploadInput from '../../../components/core/form/FileUploadInput';

const AddTweetPage = ({user}) => {
    const navigate = useNavigate();

    const[entities,setEntities] =useState([])

    const handleGetEntitiesReq = async () => {
        const getEntityReq = await EntityServices.getOrganizationsBelongsToThisWarden({
            token:user.access_token
        })
        if(getEntityReq.status === false){
            ToastAlert.notifyError(getEntityReq.message);   
            setEntities([]);
        }
        else{
            setEntities(getEntityReq?.data);
        }
    }

    useEffect(()=>{
        handleGetEntitiesReq()
    },[])

    
    const [entity, setEntity] = useState(0);
    const [hasEntityMessage, setHasEntityMessage] = useState(false);
    const [entityMessage, setEntityMessage] = useState("");
    const [entityMessageType, setEntityMessageType] = useState("");

    const [description, setDescription] = useState("");
    const [hasDescriptionMessage, setHasDescriptionMessage] = useState(false);
    const [descriptionMessage, setDescriptionMessage] = useState("");
    const [descriptionMessageType, setDescriptionMessageType] = useState("");

    const [selectedImages, setSelectedImages] = useState([]);
    const [imageType, setImageType] = useState("image");
    const [imageIsMultiple, setImageIsMultiple] = useState(false);
    const [imageMaxFileSelection, setImageMaxFileSelection] = useState(1);
    const [imagePopUpLable, setImagePopUpLable] = useState("Image");
    const [imageIsError, setImageIsError] = useState(false);
    const [imageData, setImageData] = useState(null);

    const [selectedImages1, setSelectedImages1] = useState([]);
    const [image1Type, setImage1Type] = useState("image");
    const [image1IsMultiple, setImage1IsMultiple] = useState(false);
    const [image1MaxFileSelection, setImage1MaxFileSelection] = useState(1);
    const [image1PopUpLable, setImage1PopUpLable] = useState("Image");
    const [image1IsError, setImage1IsError] = useState(false);
    const [image1Data, setImage1Data] = useState(null);

    const [selectedImages2, setSelectedImages2] = useState([]);
    const [image2Type, setImage2Type] = useState("image");
    const [image2IsMultiple, setImage2IsMultiple] = useState(false);
    const [image2MaxFileSelection, setImage2MaxFileSelection] = useState(1);
    const [image2PopUpLable, setImage2PopUpLable] = useState("Image");
    const [image2IsError, setImage2IsError] = useState(false);
    const [image2Data, setImage2Data] = useState(null);

    const [selectedImages3, setSelectedImages3] = useState([]);
    const [image3Type, setImage3Type] = useState("image");
    const [image3IsMultiple, setImage3IsMultiple] = useState(false);
    const [image3MaxFileSelection, setImage3MaxFileSelection] = useState(1);
    const [image3PopUpLable, setImage3PopUpLable] = useState("Image");
    const [image3IsError, setImage3IsError] = useState(false);
    const [image3Data, setImage3Data] = useState(null);
  
    const [submitButtonHasIcon, setSubmitButtonHasIcon] = useState(false);
    const [submitButtonIconPosition, setSubmitButtonIconPosition] = useState('left');
    const [submitButtonIco, setSubmitButtonIco] = useState(icons.tick);
    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleImageSelection = async (e) => {
        if(e.status === true){
            setSelectedImages([...e.files]);
            setImageIsError(false);
        }
        else{
            setSelectedImages([]);
        }
    }

    const handleImage1Selection = async (e) => {
        if(e.status === true){
            setSelectedImages1([...e.files]);
            setImage1IsError(false);
        }
        else{
            setSelectedImages1([]);
        }
    }

    const handleImage2Selection = async (e) => {
        if(e.status === true){
            setSelectedImages2([...e.files]);
            setImage2IsError(false);
        }
        else{
            setSelectedImages2([]);
        }
    }

    const handleImage3Selection = async (e) => {
        if(e.status === true){
            setSelectedImages3([...e.files]);
            setImage3IsError(false);
        }
        else{
            setSelectedImages3([]);
        }
    }

    const handleAddTweetSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(description.trim() === ""){
            setHasDescriptionMessage(true);
            setDescriptionMessage("Invalid Description");
            setDescriptionMessageType("error");
        }
        else{
            setHasDescriptionMessage(false);
            setDescriptionMessage("");
            setDescriptionMessageType("");
        }

        if(selectedImages.length === 0){
            setImageIsError(true);
        }
        else{
            setImageIsError(false);
        }

        // if(selectedImages1.length === 0){
        //     setImage1IsError(true);
        // }
        // else{
        //     setImage1IsError(false);
        // }

        // if(selectedImages2.length === 0){
        //     setImage2IsError(true);
        // }
        // else{
        //     setImage2IsError(false);
        // }

        // if(selectedImages3.length === 0){
        //     setImage3IsError(true);
        // }
        // else{
        //     setImage3IsError(false);
        // }
     
        if( description === "" || entity === 0  ){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveTweetReq = await TweetServices.saveTweet({
                entity: entity,
                description: description,
                image: selectedImages[0],
                image1:selectedImages1.length > 0 ? selectedImages1[0] : null,
                image2:selectedImages2.length > 0 ? selectedImages2[0] : null,
                image3:selectedImages3.length > 0 ? selectedImages3[0] : null,
                token: user.access_token
                
            });

            if(saveTweetReq.status === false){
                ToastAlert.notifyError(saveTweetReq.message);
                setSubmitButtonLoading(false);
                
            }
            else{
                ToastAlert.notifySuccess(saveTweetReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonHasIcon(true);
                setSubmitButtonLable('Success');
                navigate(-1);
            }
        }
    }

    return(
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading=' Create Tweet'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Add tweet'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.list}
                        action={()=> navigate('/masterdata/entity') }
                    >
                        View Entity
                    </Button>
                </HeaderActions>
            </DefaultHeader>
            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type='full' styles={{paddingTop: "15px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                        <CardHeading heading={"add new tweet"} color="dark" />
                        <CardDescription description={"country: India"} />

                        <PlainContainer type='full' styles={{marginTop: "30px"}}>
                            <form onSubmit={(e) => handleAddTweetSubmission(e)} noValidate>
                                <div className="cmsForm">
                               <SplitContainer>
                               <SelectInput 
                                        hasLable={true}
                                        lable="entity"
                                        isMandatory={true}
                                        hasMessage={hasEntityMessage}
                                        message={entityMessage}
                                        messageType={entityMessageType}
                                    >
                                        <select className="cmsFormStepInputSelect" value={entity} onChange={(e) => setEntity(e.target.value)}>
                                            <option value={0} disabled={true}>Select entity</option>
                                            {
                                                entities.map((et) => {
                                                    return (
                                                        <option key={et.id} value={et.id}>{et?.name?.en}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </SelectInput>
                                    <TextAreaInput 
                                        hasLable={true}
                                        lable={"description"}
                                        isMandatory={false}
                                        hasIcon={false}
                                        hasMessage={hasDescriptionMessage}
                                        message={descriptionMessage}
                                        messageType={descriptionMessageType}
                                    >
                                       <textarea 
                                            type="text" 
                                            className="cmsFormStepInputTextArea" 
                                            placeholder={"Enter  Description"}
                                            onChange={(e) => setDescription(e.target.value)}
                                            rows={3}
                                            value={description}
                                        >
                                        </textarea>
                                    </TextAreaInput>
                               </SplitContainer>
                               <SplitContainer>
                               
                                    

                                    <FileUploadInput
                                        data={imageData}
                                        user={user} 
                                        lable={imagePopUpLable} 
                                        acceptedFileType={imageType} 
                                        isPreview={false}
                                        previewType={"image"}
                                        preview={""}
                                        isMultiple={imageIsMultiple} 
                                        maxSelection={imageMaxFileSelection} 
                                        isError={imageIsError}
                                        action={(e) => handleImageSelection(e)} 
                                    />
                                      <FileUploadInput
                                        data={image1Data}
                                        user={user} 
                                        lable={image1PopUpLable} 
                                        acceptedFileType={image1Type} 
                                        isPreview={false}
                                        previewType={"image"}
                                        preview={""}
                                        isMultiple={image1IsMultiple} 
                                        maxSelection={image1MaxFileSelection} 
                                        isError={image1IsError}
                                        action={(e) => handleImage1Selection(e)} 
                                    />
                                      <FileUploadInput
                                        data={image2Data}
                                        user={user} 
                                        lable={image2PopUpLable} 
                                        acceptedFileType={image2Type} 
                                        isPreview={false}
                                        previewType={"image"}
                                        preview={""}
                                        isMultiple={image2IsMultiple} 
                                        maxSelection={image2MaxFileSelection} 
                                        isError={image2IsError}
                                        action={(e) => handleImage2Selection(e)} 
                                    />
                                     <FileUploadInput
                                        data={image3Data}
                                        user={user} 
                                        lable={image3PopUpLable} 
                                        acceptedFileType={image3Type} 
                                        isPreview={false}
                                        previewType={"image"}
                                        preview={""}
                                        isMultiple={image3IsMultiple} 
                                        maxSelection={image3MaxFileSelection} 
                                        isError={image3IsError}
                                        action={(e) => handleImage3Selection(e)} 
                                    />
                               </SplitContainer>
                                    <FormActions>
                                        <Button 
                                            type="submit"
                                            bgType="fill"
                                            width="auto"
                                            bg={submitButtonBg}
                                            borderRadius="short"
                                            hasIcon={submitButtonHasIcon}
                                            iconPosition={submitButtonIconPosition}
                                            icon={submitButtonIco}
                                            disabled={submitButtonDisabled}
                                            isLoading={submitButtonLoading}
                                        >
                                            {submitButtonLable}
                                        </Button>
                                        <Button 
                                            type="button"
                                            bgType="fill"
                                            width="auto"
                                            bg={cancelButtonBg}
                                            borderRadius="short"
                                            action={() => navigate(-1)}
                                        >
                                            {cancelButtonLable}
                                        </Button>
                                    </FormActions>
                                </div>
                            </form>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default AddTweetPage