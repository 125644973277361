import React from 'react'
import { icons } from '../../../../../constants'
import SelectInput from '../../../../../components/core/form/SelectInput'
import Button from '../../../../../components/core/form/Button'

const MealPostingStatusFilter = ({ taluks, taluk, TalukTypeChanged, institution_type, InstitutionTypeChanged, status, statusChanged, handleRefresh })  => {
        
    return (
        <div className="fileManagerFiltersContainer">
            <div className="fileManagerFilters">
                {/* <div className="fileManagerFiltersLayout">
                    <div className="fileManagerFiltersLayoutIcon">
                        <i className={`${icons.list} fileManagerFiltersLayoutIco`}></i>
                    </div>
                    <div className="fileManagerFiltersLayoutIcon">
                        <i className={`${icons.grid} fileManagerFiltersLayoutIco active`}></i>
                    </div>
                </div> */}

                <div className="fileManagerFiltersMain">
                    <div className="fileManagerFilterSingle">
                        <SelectInput hasLable={true} lable="taluk">
                            <select  className="cmsFormStepInputSelect"
                                value={taluk}
                                onChange={(e) => TalukTypeChanged(e.target.value)}
                            >
                                <option value="ALL">All Taluks</option>    
                                {taluks.map((t,i) => {
                                    return(
                                        <option key={t?.id} value={t?.id}>
                                        {t?.name?.en}
                                    </option>
                                    )
                                })}
                            </select>
                        </SelectInput>
                    </div>
                    <div className="fileManagerFilterSingle">
                        <SelectInput hasLable={true} lable="institution type">
                            <select  className="cmsFormStepInputSelect"
                                value={institution_type}
                                onChange={(e) => InstitutionTypeChanged(e.target.value)}
                            >
                                <option value="ALL">ALL </option>
                                <option value="SCHOOL">SCHOOL </option>
                                <option value="HOSTEL">HOSTEL</option>
                            </select>
                        </SelectInput>
                    </div>
                    <div className="fileManagerFilterSingle">
                        <SelectInput hasLable={true} lable="status">
                            <select  className="cmsFormStepInputSelect"
                                value={status}
                                onChange={(e) => statusChanged(e.target.value)}
                            >
                                <option value="ALL">ALL </option>
                                <option value="POSTED_ON_TIME">On Time </option>
                                <option value="POSTED_WITH_DELAY">Delayed</option>
                                <option value="POST_NOT_CONSIDERED">After Deadline</option>
                                <option value="NOT_POSTED">Not Posted</option>
                            </select>
                        </SelectInput>
                    </div>
                    {/* <div className="fileManagerFilterSingle">
                        <SelectInput hasLable={false}>
                            <select className="cmsFormStepInputSelect">
                                <option>Created By</option>
                            </select>
                        </SelectInput>
                    </div> */}
                </div>
            </div>

            {/* <div className="fileManagerFiltersAction">
                <Button 
                    type="button"
                    bgType="fill"
                    width="auto"
                    bg="primary"
                    borderRadius="full"
                    hasIcon={true}
                    iconPosition="left"
                    icon={icons.refresh}
                    action={handleRefresh}
                >
                    Refresh
                </Button>
            </div> */}
        </div>
    )
}

export default MealPostingStatusFilter