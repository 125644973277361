import React from 'react'
import { useLocation } from 'react-router-dom';
import NavbarHeader from './NavbarHeader'
import NavBarUserDetails from './UserDetails'
import NavbarContent from './NavbarContent'
import NavbarSection from './NavSection'
import NavbarActionItem from './NavItem'
import { icons } from '../../../../../constants'
import { functions } from '../../../../../utils';

const routeList = [
    {
        basic: {
            heading: "dashboards",
            description: "All you need at your fingertips"
        },
        routes: [
            { 
                name:"home", 
                goTo:"/dashboard/home", 
                icon: icons.home, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
            { 
                name:"locations", 
                goTo:"/masterdata/locations", 
                icon: icons.mapRealistic, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [
                    { 
                        name:"Manage Districts", 
                        goTo:"/masterdata/locations/districts", 
                        tagline: '', 
                        badgeData: {
                            hasBadge: false, 
                            badgeType: "default", 
                            badgeBg: "default", 
                            lable: ""
                        }
                    },
                    { 
                        name:"Manage Taluks", 
                        goTo:"/masterdata/locations/taluks", 
                        tagline: '', 
                        badgeData: {
                            hasBadge: false, 
                            badgeType: "default", 
                            badgeBg: "default", 
                            lable: ""
                        }
                    }
                ] 
            },
            { 
                name:"Institutions", 
                goTo:"/masterdata/institutions", 
                icon: icons.bookOpenRead, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
        ]
    },
    {
        basic: {
            heading: "Reports",
            description: "All you need at your fingertips"
        },
        routes: [
            { 
                name:"Incomplete Institutes", 
                goTo:"/reports/incomplete-institutions", 
                icon: icons.home, 
                tagline: '', 
                badgeData: {
                    hasBadge: false, 
                    badgeType: "default", 
                    badgeBg: "default", 
                    lable: ""
                },
                subRoutes: [] 
            },
            // { 
            //     name:" Overall Abstract Report", 
            //     goTo:"/masterdata/overall-report", 
            //     icon: icons.bookOpenRead, 
            //     tagline: '', 
            //     badgeData: {
            //         hasBadge: false, 
            //         badgeType: "default", 
            //         badgeBg: "default", 
            //         lable: ""
            //     },
            //     subRoutes: [] 
            // },
            // { 
            //     name:" Distrtict Wise Abstract Report", 
            //     goTo:"/masterdata/district-report", 
            //     icon: icons.bookOpenRead, 
            //     tagline: '', 
            //     badgeData: {
            //         hasBadge: false, 
            //         badgeType: "default", 
            //         badgeBg: "default", 
            //         lable: ""
            //     },
            //     subRoutes: [] 
            // },
            // { 
            //     name:"Taluk Wise Abstract Report", 
            //     goTo:"/masterdata/taluk-report", 
            //     icon: icons.bookOpenRead, 
            //     tagline: '', 
            //     badgeData: {
            //         hasBadge: false, 
            //         badgeType: "default", 
            //         badgeBg: "default", 
            //         lable: ""
            //     },
            //     subRoutes: [] 
            // },
        ]
    },
]

const HelplineLayoutNavBar = ({ user }) => {
    const location = useLocation();

    return (
        <div className="dashboardNavBar">
            <NavbarHeader />
            <NavBarUserDetails
                avatar={user.avatar === "" ? '/images/default-avatar.jpg' : user?.avatar}
                name={user.name}
                emailID={user.email_id}
            />
            <NavbarContent>
                {
                    routeList.map((section, i) => {
                        return (
                            <NavbarSection heading={section.basic.heading} description={section.basic.description} key={i}>
                                {
                                    section.routes.map((route, i) => {
                                        return(
                                            <NavbarActionItem 
                                                key={i}
                                                icon={route.icon}
                                                lable={route.name}
                                                tagline={route.tagline}
                                                isBadge={route.badgeData.hasBadge}
                                                badgeType={route.badgeData.badgeType}
                                                badgeBg={route.badgeData.badgeBg}
                                                badgeContent={route.badgeData.lable}
                                                goTo={route.goTo}
                                                isActive={location.pathname.includes(route.goTo)} 
                                                hasSubRoutes={route.subRoutes.length != 0 ? true : false}
                                                subRoutes={route.subRoutes}
                                            />
                                        )
                                    })
                                }
                            </NavbarSection>
                        )
                    })
                }
            </NavbarContent>
        </div>
    )
}

export default HelplineLayoutNavBar