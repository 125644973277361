import axios from "axios";
import { statusCodes, defaults } from "../../utils"

const config = (token) => {
    return (
        {
            headers: {
                'Authorization': token
            }
        }
    )
}

const saveHostel = async (payload) => {
    if(!payload.en_name){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "English name is required.",
        });

        return response
    }

    if(!payload.kn_name){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Kannada name is required.",
        });

        return response
    }

    if(!payload.district){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "District is required.",
        });

        return response
    }
    
    if(!payload.taluk){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "District is required.",
        });

        return response
    }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    const data = {
        en_name: payload.en_name.toLowerCase(),
        kn_name: payload.kn_name,
        taluk :payload.taluk,
        district: payload.district
    }

    try{
        let headers = await config(payload.token);

        const addNewHostelReq = await axios.post(`${defaults.API_BASE_URL}location/hostel`, data, headers);
        if(addNewHostelReq.status === statusCodes.NOT_FOUND){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.NOT_FOUND,
                message: "Unabe to add School for the selected District."
            });
    
            return response
        }
        else if(addNewHostelReq.status === statusCodes.CREATED){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.CREATED,
                message: "Hostel successfully created.",
                data: addNewHostelReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}


const getAllHostels = async (payload) => {
    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    try{
        let headers = await config(payload.token);

        const getAllHostelsReq = await axios.get(`${defaults.API_BASE_URL}location/hostel?page=${payload.current_page}&size=10&district=${payload?.district === undefined ? "ALL" : payload?.district}&created_on=${payload?.created_on === undefined ? "RECENT" : payload?.created_on}&created_by=${payload?.created_by === undefined ? null : payload?.created_by}`, headers);
        if(getAllHostelsReq.status === statusCodes.SUCCESS){
        
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Hostel successfully recieved.",
                data: getAllHostelsReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}
const getThisAuthHostel = async (payload) => {

    if(!payload.id){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Hostel ID is required.",
        });

        return response
    }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }
    try{
        let headers = await config(payload.token);

        const getAuthHostelReq = await axios.get(`${defaults.API_BASE_URL}hostel/auth/${payload.id}`, headers);
        if(getAuthHostelReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Hostel successfully recieved.",
                data: getAuthHostelReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.BAD_REQUEST,
            message: "Request failed due to expected technical error."
        });

        return response
    }
}

const updateHostel = async (payload) => {
    if(!payload.id){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: " ID is required.",
        });

        return response
    }

    if(!payload.en_name){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "English name is required.",
        });

        return response
    }

    if(!payload.kn_name){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Kannada name is required.",
        });

        return response
    }
    if(!payload.district){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "District is required.",
        });

        return response
    }
    if(!payload.taluk){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Taluk is required.",
        });

        return response
    }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    const data = {
        en_name: payload.en_name.toLowerCase().trimStart().trimEnd(),
        kn_name: payload.kn_name,
        district:payload.district,
        takul:payload.taluk
    }

    try{
        let headers = await config(payload.token);

        const updateHostelReq = await axios.patch(`${defaults.API_BASE_URL}hostel/${payload.id}`, data, headers);
        if(updateHostelReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Hostel successfully updated.",
                data: updateHostelReq?.data?.data
            });
    
            return response
        }
        else if(updateHostelReq.status === statusCodes.NOT_FOUND){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.NOT_FOUND,
                message: "Invalid request. The District that you are trying update is not found."
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}

const HostelServices = {
    saveHostel,
    getAllHostels,
    getThisAuthHostel,
    updateHostel
}
export default HostelServices