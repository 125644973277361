import React, { useEffect } from 'react';
import ContentContainer from './ContentContainer';
import ContentSidebarFooter from './ContentSidebarFooter';
import HelplineLayoutNavBar from './Navbar';
import HelplineLayoutHeader from './Header';
import HelplineLayoutFooter from './Footer';

const HelplineLayout = ({ user, children }) => {
    return (
        <div className="dashboardPage">
            <HelplineLayoutNavBar user={user} />
            <ContentSidebarFooter>
                <ContentContainer>
                    <HelplineLayoutHeader user={user} />
                    {children}
                </ContentContainer>
                <HelplineLayoutFooter />
            </ContentSidebarFooter>
        </div>
    )
}

export default HelplineLayout