import React from 'react'
import { motion } from 'framer-motion';
import { icons } from '../../../../constants'
import { globals } from '../../../../utils';

const SelectUserAvatar = ({user, containerVariant, childVariant, avatars, selectedAvatar, avatarChanged}) => {

    return (
        <div className="selectAvatarSection">
            <div className="selectAvatarSectionArrow">
                <i className={`selectAvatarSectionArrowMain ${icons.play}`}></i>
            </div>
            <motion.div className="selectAvatarSectionList" variants={containerVariant} initial="hidden" animate="visible" exit="hidden">
                {
                    avatars.map((avatar, i) => {
                        return (
                            <motion.div className="selectAvatarSectionListItem" key={avatar.id} variants={childVariant} onClick={() => avatarChanged(avatar)}>
                                <div className="selectAvatarSectionListItemImage">
                                    <img src={`${globals.API_BASE_URL}${avatar.image.image_url.thumbnail.low_res}`} className="selectAvatarSectionListItemImg" alt="" />
                                    <div className={`selectedAvatarItem ${avatar?.id === selectedAvatar?.id ? 'selected' : ''}`}>
                                        <div className="selectedAvatarIcon">
                                            <i className={`selectedAvatarIco ${icons.tick}`}></i>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        )
                    })
                }
            </motion.div>
        </div>
    )
}

export default SelectUserAvatar