import React from 'react'
import { motion } from 'framer-motion';


const SidebarFileDetails = ({filename, type="file", variants, action=()=>console.log(''), children}) => {
    return (
        <motion.div className="fileManagerSidebarDetails" variants={variants}>
            <div className="fileManagerSidebarDetailsNameAction">
                <div className="fileManagerSidebarDetailsName">
                    <p className="fileManagerSidebarDetailsNameTxt">{filename}</p>
                </div>
                <div className="fileManagerSidebarDetailsAction" onClick={action}>
                    <p className="ffileManagerSidebarDetailsActionTxt">Edit Meta Data</p>
                </div>
            </div>
            <div className="fileManagerSidebarDetailsLableSteps">
                <div className="fileManagerSidebarDetailsLable">
                    <p className="fileManagerSidebarDetailsLableTxt">
                        {type === "file" ? "file details" : "folder details"}
                    </p>
                </div>
                <div className="fileManagerSidebarDetailsStepContainer">
                    {children}
                </div>
            </div>
        </motion.div>
    )
}

export default SidebarFileDetails