import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import moment from 'moment';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import DashboardHeaderSearchBar from '../../../components/core/form/DashboardHeaderSearchBar';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import Card from '../../../components/core/dashboard/Card';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableCheckbox from '../../../components/core/table/inputs/Checkbox';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../components/core/table/inputs/Button';
import SuperAdminAvatarFilters from './SuperAdminAvatarFilters';
import AvatarServices from '../../../services/Avatar';
import { functions, globals, ToastAlert } from '../../../utils';

const DragHandle = sortableHandle(() => <button className="draggableHandlerTableRow"><i className={`${icons.menu} draggableHandlerTableRowIco`}></i></button>);

const SortableItem = sortableElement(({value, index}) => (
    <TableRow key={index}>
        <DragHandle />
        <TableRowItem size={2} isCenter={false} hasPreview={true} previewType={'image'} previewItem={`${globals.API_BASE_URL}${value?.image?.image_url?.thumbnail?.low_res}`} lable={value.name} />
        <TableRowItem size={1} isCenter={false} lable={`${value.created_by.first_name} (${value.created_by.role === "SUPER_ADMIN" ? "super admin" : "admin"})`} />
        <TableRowItem size={0} isCenter={true} hasChildren={true}>
            <TableRowIcons>
                <TableRowIcon icon={icons.eye} color={"dark"} action={() => console.log()} />
            </TableRowIcons>
        </TableRowItem>
        <TableRowItem size={2} isCenter={false} hasChildren={true}>
            <TableRowActions>
                <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"}>edit</TableButton>
                <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => console.log('')}>delete</TableButton>
            </TableRowActions>
        </TableRowItem>
    </TableRow>
));
  
const SortableContainer = sortableContainer(({children}) => {
    return <TableRows>{children}</TableRows>;
});

const SuperAdminAvatarsListPage = ({ user }) => {
    const navigate = useNavigate();

    const [avatars, setAvatars] = useState([]);
    const [totalAvatarsCount, setTotalAvatarsCount] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const handleGetAvatarsRequest = async () => {
        const getAvatarsReq = await AvatarServices.getAllAvatars({
            token: user.access_token,
            current_page: currentPage
        });

        if(getAvatarsReq.status === false){
            ToastAlert.notifyError(getAvatarsReq.message);
            setAvatars([]);
            setTotalAvatarsCount(0);
            setTotalPagesCount(0);
            setCurrentPage(1);
        }
        else{
            if(getAvatarsReq.data.items.length > 0){
                setAvatars(getAvatarsReq.data.items);
                setTotalAvatarsCount(getAvatarsReq.data.total_items);
                setTotalPagesCount(getAvatarsReq.data.total_pages);
                setCurrentPage(getAvatarsReq.data.current_page);
            }
            else{
                setAvatars([]);
                setTotalAvatarsCount(0);
                setTotalPagesCount(0);
                setCurrentPage(1);
            }
        }
    }

    useEffect(() => {
        handleGetAvatarsRequest();
    }, [currentPage]);

    const handleSaveSortedList = async (list) => {

        const sortAvatarReq = await AvatarServices.sortAvatar({
            items: JSON.stringify(list),
            token: user.access_token
        });

        if(sortAvatarReq.status === false){
            ToastAlert.notifyError(sortAvatarReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortAvatarReq.message);
            handleGetAvatarsRequest();
        }
    }

    const handleSortEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(avatars, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedList(entityIdWithIndex);

        setAvatars([...arrayMove]);
    }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Avatars'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Default Representation of The Users.'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions>
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        <PlainContainer type="full" styles={{paddingTop: "15px", paddingRight: "25px", paddingBottom: "25px", paddingLeft: "25px"}}>
                            <SuperAdminAvatarFilters />
                        </PlainContainer>
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={2} lable={"full name"} />
                                    <TableHeadingItem size={1} lable={"created by"} />
                                    <TableHeadingItem size={0} lable={""} />
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>

                                <SortableContainer onSortEnd={handleSortEnd} useDragHandle>
                                    {avatars.map((item, index) => (
                                        <SortableItem key={`item-${index}`} index={index} value={item} />
                                    ))}
                                </SortableContainer>
                            </Table>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default SuperAdminAvatarsListPage