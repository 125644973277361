import axios from "axios";
import { statusCodes, defaults } from "../../utils"

const config = (token) => {
    return (
        {
            headers: {
                'Authorization': token
            }
        }
    )
}

const saveWarden = async (payload) => {
    if(!payload.email_id){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "email_id is required.",
        });

        return response
    }
   
    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }
    const data = {
        email_id:payload.email_id
    }

    try{
        let headers = await config(payload.token);

        const addNewWardenReq = await axios.post(`${defaults.API_BASE_URL}admin/check/warden` ,data ,headers);
        if(addNewWardenReq.status === statusCodes.NOT_FOUND){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.NOT_FOUND,
                message: "Warden details  not found"
            });
    
            return response
        }
        else if(addNewWardenReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Warden successfully created.",
                data: addNewWardenReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}
const saveAdmin = async (payload) => {
    if(!payload.email_id){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "email_id is required.",
        });

        return response
    }
    if(!payload.firstName){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "firstName is required.",
        });

        return response
    }
    if(!payload.lastName){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "lastName is required.",
        });

        return response
    }
    if(!payload.phoneNumber){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "phoneNumber is required.",
        });

        return response
    }
    if(!payload.password){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "passWord is required.",
        });

        return response
    }
   
   
    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }
    const data = {
        email_id:payload.email_id,
        first_name:payload.firstName,
        last_name:payload.lastName,
        phone_number:payload.phoneNumber,
        password:payload.password,
    }

    try{
        let headers = await config(payload.token);

        const addNewWardenReq = await axios.post(`${defaults.API_BASE_URL}admin/warden` ,data ,headers);
        if(addNewWardenReq.status === statusCodes.NOT_FOUND){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.NOT_FOUND,
                message: "Warden details  not found"
            });
    
            return response
        }
        else if(addNewWardenReq.status === statusCodes.CREATED){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.CREATED,   
                message: "Warden successfully created.",
                data: addNewWardenReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}


const getAllWardens = async (payload) => {
    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    try{
        let headers = await config(payload.token);

        const getAllSchoolsReq = await axios.get(`${defaults.API_BASE_URL}location/school?page=${payload.current_page}&size=10&district=${payload?.district === undefined ? "ALL" : payload?.district}&created_on=${payload?.created_on === undefined ? "RECENT" : payload?.created_on}&created_by=${payload?.created_by === undefined ? null : payload?.created_by}`, headers);
        if(getAllSchoolsReq.status === statusCodes.SUCCESS){
        
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Warden successfully recieved.",
                data: getAllSchoolsReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}


const WardenServices = {
    saveWarden,
    saveAdmin,
    getAllWardens,
}
export default WardenServices