import axios from "axios";
import { statusCodes, defaults } from "../../utils"

const config = (token) => {
    return (
        {
            headers: {
                'Authorization': token
            }
        }
    )
}

const saveTweet = async (payload) => {

    if(!payload.entity){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "entity is required.",
        });

        return response
    }
    if(!payload.description){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "description is required.",
        });

        return response
    }
    if(!payload.image){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "image is required.",
        });

        return response
    }

    // if(!payload.image1){
    //     const response = defaults.ServiceResponse({
    //         status: false,
    //         status_code: statusCodes.VALIDATION_FAILED,
    //         message: "image 1 is required.",
    //     });

    //     return response
    // }

    // if(!payload.image2){
    //     const response = defaults.ServiceResponse({
    //         status: false,
    //         status_code: statusCodes.VALIDATION_FAILED,
    //         message: "image 2 is required.",
    //     });

    //     return response
    // }

    // if(!payload.image3){
    //     const response = defaults.ServiceResponse({
    //         status: false,
    //         status_code: statusCodes.VALIDATION_FAILED,
    //         message: "image 3 is required.",
    //     });

    //     return response
    // }
    
    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    const data = {
        organization_id: payload.entity,
        content : payload.description,
        image_1 : payload.image,
        image_2 : payload.image1,
        image_3 : payload.image2,
        image_4 : payload.image3
        
    }

    try{
        let headers = await config(payload.token);

        const addNewTwitterApiReq = await axios.post(`${defaults.API_BASE_URL}tweet`, data, headers);
        
        if(addNewTwitterApiReq.status === statusCodes.CREATED){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.CREATED,
                message: "Tweet credentials successfully updated.",
                data: addNewTwitterApiReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}

const getThisWardenTweets = async (payload) => {
    
        if(!payload.token){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Token is required.",
            });
    
            return response
        }
    
        try{
            let headers = await config(payload.token);
    
            const getThisWardenTweetsReq = await axios.get(`${defaults.API_BASE_URL}tweet/warden`, headers);
            if(getThisWardenTweetsReq.status === statusCodes.SUCCESS){
            
                const response = defaults.ServiceResponse({
                    status: true,
                    status_code: statusCodes.SUCCESS,
                    message: "Tweets successfully recieved.",
                    data: getThisWardenTweetsReq?.data?.data
                });
        
                return response
            }
            else {
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.BAD_REQUEST,
                    message: "Request failed due to unexpected technical error."
                });
        
                return response
            }
        }
        catch(error){
            if(error.response.status === statusCodes.UNAUTHORISED){
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.UNAUTHORISED,
                    message: "Please provide your token.",
                });
        
                return response
            }
            else if(error.response.status === statusCodes.FORBIDDEN){
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.FORBIDDEN,
                    message: "User doesn't have required rights to process this request.",
                });
        
                return response
            }
            else if(error.response.status === statusCodes.VALIDATION_FAILED){
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.VALIDATION_FAILED,
                    message: "Invalid Inputs",
                });
        
                return response
            }
            else{
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.BAD_REQUEST,
                    message: "Request failed due to expected technical error."
                });
        
                return response
            }
        }
    }


    
    const getThisWardenStats = async (payload) => {
    
        if(!payload.token){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Token is required.",
            });
    
            return response
        }
    
        try{
            let headers = await config(payload.token);
    
            const getThisWardenStatsReq = await axios.get(`${defaults.API_BASE_URL}tweet/stats`, headers);
            if(getThisWardenStatsReq.status === statusCodes.SUCCESS){
            
                const response = defaults.ServiceResponse({
                    status: true,
                    status_code: statusCodes.SUCCESS,
                    message: "Tweets successfully recieved.",
                    data: getThisWardenStatsReq?.data?.data
                });
        
                return response
            }
            else {
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.BAD_REQUEST,
                    message: "Request failed due to unexpected technical error."
                });
        
                return response
            }
        }
        catch(error){
            if(error.response.status === statusCodes.UNAUTHORISED){
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.UNAUTHORISED,
                    message: "Please provide your token.",
                });
        
                return response
            }
            else if(error.response.status === statusCodes.FORBIDDEN){
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.FORBIDDEN,
                    message: "User doesn't have required rights to process this request.",
                });
        
                return response
            }
            else if(error.response.status === statusCodes.VALIDATION_FAILED){
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.VALIDATION_FAILED,
                    message: "Invalid Inputs",
                });
        
                return response
            }
            else{
                const response = defaults.ServiceResponse({
                    status: false,
                    status_code: statusCodes.BAD_REQUEST,
                    message: "Request failed due to expected technical error."
                });
        
                return response
            }
        }
    }

const TweetServices = {
    saveTweet,
    getThisWardenTweets,
    getThisWardenStats,
}
export default TweetServices