import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Card from '../../../components/core/dashboard/Card';
import CardHeading from '../../../components/core/typography/dashboard/CardHeading';
import CardDescription from '../../../components/core/typography/dashboard/CardDescription';
import TextInputRT from '../../../components/core/form/TextInputRT';
import SelectInput from '../../../components/core/form/SelectInput';
import RadioButtonGroup from '../../../components/core/form/RadioButtonGroup';
import RadioButtonInput from '../../../components/core/form/RadioButtonInput';
import FormActions from '../../../components/form/FormActions';
import { constants, icons } from '../../../constants';
import { globals, ToastAlert, validations } from '../../../utils';
import { PageServices } from '../../../services';

const AddPagesPage = ({user}) => {
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [hasNameIcon, setHasNameIcon] = useState(false);
    const [nameIcon, setNameIcon] = useState("");
    const [nameIconPosition, setNameIconPosition] = useState("right");
    const [nameIconType, setNameIconType] = useState("");
    const [hasNameMessage, setHasNameMessage] = useState(false);
    const [nameMessage, setNameMessage] = useState("");
    const [nameMessageType, setNameMessageType] = useState("");

    const [pageType, setPageType] = useState("PARENT");

    const [path, setPath] = useState("");
    const [hasPathIcon, setHasPathIcon] = useState(false);
    const [pathIcon, setPathIcon] = useState("");
    const [pathIconPosition, setPathIconPosition] = useState("right");
    const [pathIconType, setPathIconType] = useState("");
    const [hasPathMessage, setHasPathMessage] = useState(false);
    const [pathMessage, setPathMessage] = useState("");
    const [pathMessageType, setPathMessageType] = useState("");
    const [pathAlreadyExist, setPathAlreadyExist] = useState(false);

    const [layoutType, setLayoutType] = useState("CUSTOM");

    const nameChanged = async (e) => {
        setName(e);
    }

    const pathChanged = async (e) => {
        if(e.length > 0){
            setPath(e);

            const checkPathAvailabilityReq = await PageServices.checkPagePathAvailability({
                path: e,
                token: user.access_token
            });
    
            if(checkPathAvailabilityReq.status === false){
                if(checkPathAvailabilityReq.status_code === 403){
                    setHasPathMessage(true);
                    setPathMessage("Page with same path already exists.");
                    setPathMessageType("error");
                    setSubmitButtonDisabled(true);
                    setPathAlreadyExist(true);
                }
                else{
                    ToastAlert.notifyError(checkPathAvailabilityReq.message);
                    setSubmitButtonLoading(false);
                    setSubmitButtonDisabled(true);
                    setPathAlreadyExist(false);
                }
            }
            else{
                setHasPathMessage(false);
                setPathMessage("");
                setPathMessageType("");
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(false);
                setPathAlreadyExist(false);
            }
        }
        else{
            setPath(e);
            setSubmitButtonDisabled(true);
        }
    }

    const [submitButtonHasIcon, setSubmitButtonHasIcon] = useState(false);
    const [submitButtonIconPosition, setSubmitButtonIconPosition] = useState('left');
    const [submitButtonIco, setSubmitButtonIco] = useState(icons.tick);
    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleAddPageSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(name.trim() === ""){
            setHasNameIcon(true);
            setNameIcon(icons.wrongCircle);
            setNameIconType("error");
            setHasNameMessage(true);
            setNameMessage("Invalid Name");
            setNameMessageType("error");
        }
        else{
            setHasNameIcon(true);
            setNameIcon(icons.tickCircle);
            setNameIconType("success");
            setHasNameMessage(false);
            setNameMessage("");
            setNameMessageType("");
        }

        if(pathAlreadyExist === false){
            if(path.trim() === ""){
                setHasPathIcon(true);
                setPathIcon(icons.wrongCircle);
                setPathIconType("error");
                setHasPathMessage(true);
                setPathMessage("Invalid Name");
                setPathMessageType("error");
            }
            else{
                setHasPathIcon(true);
                setPathIcon(icons.tickCircle);
                setPathIconType("success");
                setHasPathMessage(false);
                setPathMessage("");
                setPathMessageType("");
            }
        }
        else{
            setHasPathIcon(true);
            setPathIcon(icons.wrongCircle);
            setPathIconType("error");
            setHasPathMessage(true);
            setPathMessage("Invalid Name");
            setPathMessageType("error");
        }

        if(name === "" || path === ""){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const savePageReq = await PageServices.savePage({
                name: name,
                page_type: pageType,
                path: path,
                layout_type: layoutType,
                content: "",
                token: user.access_token
            });

            if(savePageReq.status === false){
                ToastAlert.notifyError(savePageReq.message);
                setSubmitButtonLoading(false);
                setHasNameIcon(false);
                setNameIcon("");
                setNameIconType("");
                setHasPathIcon(false);
                setPathIcon("");
                setPathIconType("");
                setLayoutType("CUSTOM");
            }
            else{
                ToastAlert.notifySuccess(savePageReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonHasIcon(true);
                setSubmitButtonLable('Success');
                navigate(-1);
            }
        }
    }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Pages'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Default Representation of The Pages.'
                />
                <HeaderActions>

                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.list}
                        action={()=> navigate('/general/pages') }
                    >
                        View Pages
                    </Button>
                        
                </HeaderActions>
            </DefaultHeader>
            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type='full' styles={{paddingTop: "15px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                        <CardHeading heading={"add new page"} color="dark" />
                        <CardDescription description={"you can still modify this content later"} />

                        <PlainContainer type='full' styles={{marginTop: "30px"}}>
                            <form onSubmit={(e) => handleAddPageSubmission(e)} noValidate>
                                <div className="cmsForm">
                                    <SplitContainer>
                                        <TextInputRT 
                                            hasLable={true}
                                            lable="Name"
                                            isMandatory={true}
                                            hasIcon={hasNameIcon}
                                            icon={nameIcon}
                                            iconPosition={nameIconPosition}
                                            iconType={nameIconType}
                                            hasMessage={hasNameMessage}
                                            message={nameMessage}
                                            messageType={nameMessageType}
                                        >
                                            <input 
                                                type="text" 
                                                className="cmsFormStepInputText" 
                                                placeholder={"Enter Name"}
                                                onChange={(e) => nameChanged(e.target.value)}
                                                value={name}
                                            />
                                        </TextInputRT>

                                        <TextInputRT 
                                            hasLable={true}
                                            lable="Path"
                                            isMandatory={true}
                                            hasIcon={hasPathIcon}
                                            icon={pathIcon}
                                            iconPosition={pathIconPosition}
                                            iconType={pathIconType}
                                            hasMessage={hasPathMessage}
                                            message={pathMessage}
                                            messageType={pathMessageType}
                                        >
                                            <input 
                                                type="text" 
                                                className="cmsFormStepInputText" 
                                                placeholder={"Enter Path"}
                                                onChange={(e) => pathChanged(e.target.value)}
                                                value={path}
                                            />
                                        </TextInputRT>

                                    </SplitContainer>

                                    <RadioButtonGroup lable="Layout Type" isMandatory={true} isError={false} error={""}>
                                        <RadioButtonInput lable="custom" name="layoutType" value="CUSTOM" checked={layoutType === "CUSTOM" ? true : false} action={(data) => setLayoutType(data)} />
                                        <RadioButtonInput lable="blank" name="layoutType" value="BLANK" checked={layoutType === "BLANK" ? true : false} action={(data) => setLayoutType(data)} />
                                    </RadioButtonGroup>
                                    <FormActions>
                                        <Button 
                                            type="submit"
                                            bgType="fill"
                                            width="auto"
                                            bg={submitButtonBg}
                                            borderRadius="short"
                                            hasIcon={submitButtonHasIcon}
                                            iconPosition={submitButtonIconPosition}
                                            icon={submitButtonIco}
                                            disabled={submitButtonDisabled}
                                            isLoading={submitButtonLoading}
                                        >
                                            {submitButtonLable}
                                        </Button>
                                        <Button 
                                            type="button"
                                            bgType="fill"
                                            width="auto"
                                            bg={cancelButtonBg}
                                            borderRadius="short"
                                            action={() => navigate(-1)}
                                        >
                                            {cancelButtonLable}
                                        </Button>
                                    </FormActions>
                                </div>
                            </form>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default AddPagesPage