import React from 'react'

const FileSection = ({ children }) => {
    return (
        <div className="fileManagerContentSection">
            <div className="fileManagerContentSectionHeading">
                <p className="fileManagerContentSectionHeadingTxt">Files</p>
            </div>
            <div className="fileManagerContentSectionList">
                {children}
            </div>
        </div>
    )
}

export default FileSection