import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultHeader from '../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../../components/core/dashboard/Button';
import PlainContainer from '../../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../../components/core/containers/SplitContainer';
import Card from '../../../../components/core/dashboard/Card';
import CardHeading from '../../../../components/core/typography/dashboard/CardHeading';
import CardDescription from '../../../../components/core/typography/dashboard/CardDescription';
import TextInputRT from '../../../../components/core/form/TextInputRT';
import FormActions from '../../../../components/form/FormActions';
import { constants, icons } from '../../../../constants';
import { globals, ToastAlert, validations } from '../../../../utils';
import FileUploadInput from '../../../../components/core/form/FileUploadInput';
import AvatarServices from '../../../../services/Avatar';

const SuperAdminAddAvatarPage = ({user}) => {
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [hasNameIcon, setHasNameIcon] = useState(false);
    const [nameIcon, setNameIcon] = useState("");
    const [nameIconPosition, setNameIconPosition] = useState("right");
    const [nameIconType, setNameIconType] = useState("");
    const [hasNameMessage, setHasNameMessage] = useState(false);
    const [nameMessage, setNameMessage] = useState("");
    const [nameMessageType, setNameMessageType] = useState("");

    const [selectedAvatars, setSelectedAvatars] = useState([]);
    const [avatarType, setAvatarType] = useState("image");
    const [avatarIsMultiple, setAvatarIsMultiple] = useState(false);
    const [avatarMaxFileSelection, setAvatarMaxFileSelection] = useState(1);
    const [avatarPopUpLable, setAvatarPopUpLable] = useState("Avatar");
    const [avatarIsError, setAvatarIsError] = useState(false);
    const [avatarData, setAvatarData] = useState(null);

    const nameChanged = async (e) => {
        const isNameValid = await validations.validateNameStrict(e);
        if(isNameValid.status){
            setName(e);
        }
        else if(e.length === 0){
            setName('');
        }
    }
    
    const handleAvatarSelection = async (e) => {
        if(e.status === true){
            setSelectedAvatars([...e.files]);
            setAvatarIsError(false);
        }
        else{
            setSelectedAvatars([]);
        }
    }

    const [submitButtonHasIcon, setSubmitButtonHasIcon] = useState(false);
    const [submitButtonIconPosition, setSubmitButtonIconPosition] = useState('left');
    const [submitButtonIco, setSubmitButtonIco] = useState(icons.tick);
    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleAddAvatarSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        const isNameValid = await validations.validateNameStrict(name);

        if(isNameValid.status === false){
            setHasNameIcon(true);
            setNameIcon(icons.wrongCircle);
            setNameIconType("error");
            setHasNameMessage(true);
            setNameMessage("Invalid Name");
            setNameMessageType("error");
        }
        else{
            setHasNameIcon(true);
            setNameIcon(icons.tickCircle);
            setNameIconType("success");
            setHasNameMessage(false);
            setNameMessage("");
            setNameMessageType("");
        }

        if(selectedAvatars.length === 0){
            setAvatarIsError(true);
        }
        else{
            setAvatarIsError(false);
        }

        if(isNameValid.status === false || selectedAvatars.length === 0){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveAvatarReq = await AvatarServices.saveAvatar({
                name: name,
                image: selectedAvatars[0],
                token: user.access_token
            });

            if(saveAvatarReq.status === false){
                ToastAlert.notifyError(saveAvatarReq.message);
                setSubmitButtonLoading(false);
                setHasNameIcon(false);
                setNameIcon("");
                setNameIconType("");
            }
            else{
                ToastAlert.notifySuccess(saveAvatarReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonHasIcon(true);
                setSubmitButtonLable('Success');
                navigate(-1);
            }
        }
    }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Avatars'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Default Representation of The Users.'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.list}
                        action={()=> navigate('/masterdata/avatars') }
                    >
                        View Avatars
                    </Button>
                        
                </HeaderActions>
            </DefaultHeader>
            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type='full' styles={{paddingTop: "15px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                        <CardHeading heading={"add new avatar"} color="dark" />
                        <CardDescription description={"you can still modify this content later"} />

                        <PlainContainer type='full' styles={{marginTop: "30px"}}>
                            <form onSubmit={(e) => handleAddAvatarSubmission(e)} noValidate>
                                <div className="cmsForm">
                                    <TextInputRT 
                                        hasLable={true}
                                        lable="Name"
                                        isMandatory={true}
                                        hasIcon={hasNameIcon}
                                        icon={nameIcon}
                                        iconPosition={nameIconPosition}
                                        iconType={nameIconType}
                                        hasMessage={hasNameMessage}
                                        message={nameMessage}
                                        messageType={nameMessageType}
                                    >
                                        <input 
                                            type="text" 
                                            className="cmsFormStepInputText" 
                                            placeholder={"Enter Name"}
                                            onChange={(e) => nameChanged(e.target.value)}
                                            value={name}
                                        />
                                    </TextInputRT>
                                    <FileUploadInput 
                                        data={avatarData}
                                        user={user} 
                                        lable={avatarPopUpLable} 
                                        acceptedFileType={avatarType} 
                                        isMultiple={avatarIsMultiple} 
                                        maxSelection={avatarMaxFileSelection} 
                                        isError={avatarIsError}
                                        action={(e) => handleAvatarSelection(e)} 
                                    />
                                    <FormActions>
                                        <Button 
                                            type="submit"
                                            bgType="fill"
                                            width="auto"
                                            bg={submitButtonBg}
                                            borderRadius="short"
                                            hasIcon={submitButtonHasIcon}
                                            iconPosition={submitButtonIconPosition}
                                            icon={submitButtonIco}
                                            disabled={submitButtonDisabled}
                                            isLoading={submitButtonLoading}
                                        >
                                            {submitButtonLable}
                                        </Button>
                                        <Button 
                                            type="button"
                                            bgType="fill"
                                            width="auto"
                                            bg={cancelButtonBg}
                                            borderRadius="short"
                                            action={() => navigate(-1)}
                                        >
                                            {cancelButtonLable}
                                        </Button>
                                    </FormActions>
                                </div>
                            </form>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default SuperAdminAddAvatarPage