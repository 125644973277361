import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DefaultHeader from '../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import DashboardHeaderSearchBar from '../../components/core/form/DashboardHeaderSearchBar';
import Button from '../../components/core/dashboard/Button';
import { icons } from '../../constants';
import PlainContainer from '../../components/core/containers/PlainContainer';
import Card from '../../components/core/dashboard/Card';
import Table from '../../components/core/table/containers/Table';
import TableHeading from '../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../components/core/table/containers/TableHeadingItem';
import TableCheckbox from '../../components/core/table/inputs/Checkbox';
import TableRows from '../../components/core/table/containers/TableRows';
import TableRow from '../../components/core/table/containers/TableRow';
import TableRowItem from '../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../components/core/table/containers/TableRowActions';
import TableButton from '../../components/core/table/inputs/Button';
// import DistrictsListFilters from './DistrictsListFilters';
import TalukServices from '../../services/Location/Taluk';
import { ToastAlert } from '../../utils';
import Pagination from '../../components/core/table/pagination';
import DistrictServices from '../../services/Location/District';
import AdminServices from '../../services/Admin';
import { EntityServices } from '../../services';
import DDEntityListFilters from './EntityListFilters';

const DDEntityListPage = ({ user }) => {
    const navigate = useNavigate();

    const [search, setSearch] = useState("");
    const [showSearchAction, setShowSearchAction] = useState(false);

    useEffect(() => {
        if(search.length >= 0){
            setShowSearchAction(true);
        }
        else{
            setShowSearchAction(false);
        }
    }, [search]);

    const [entity, setEntity] = useState([]);
    const [totalEntityCount, setTotalEntityCount] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [createdOn, setCreatedOn] = useState("RECENT");
   
    const [createdBy,setCreatedBy]=useState("ALL");

    const [districts, setDistricts]=useState([]);
    const [taluks, setTaluks] = useState([]);

    const [district, setDistrict] = useState('ALL');
    const [taluk, setTaluk] = useState('ALL');

    const handleGetPublicTaluksReq = async () => {
        const getTaluksReq = await TalukServices.getDropdownTaluks({
            district: district,
            token: user.access_token
        })
      

        if(getTaluksReq.status === false){
            ToastAlert.notifyError(getTaluksReq.message);   
            setTaluks([]);
        }
        else{
            setTaluks(getTaluksReq.data);
        }
    }

    useEffect(()=>{
        if(district != "ALL"){
            handleGetPublicTaluksReq()
        }
        else{
            setDistricts([]);
        }
    },[district])

    useEffect(() => {
        console.log();
    }, [taluks]);

    const handleGetEntityRequest = async () => {

        const getEntityReq = await EntityServices.getAllEntity({
            token: user.access_token,
            current_page: currentPage,
            created_on: createdOn,
            created_by:createdBy,
            taluk:taluk,
            search: search
        });

        if(getEntityReq.status === false){
            ToastAlert.notifyError(getEntityReq.message);
            setEntity([]);
            setTotalEntityCount(0);
            setTotalPagesCount(0);
            setCurrentPage(1);
        }
        else{
            setDistrict(getEntityReq?.data?.items[0].district._id);
            setEntity(getEntityReq?.data?.items);
            setTotalEntityCount(getEntityReq.data.total_items);
            setTotalPagesCount(getEntityReq.data.total_pages);
            setCurrentPage(getEntityReq.data?.current_page === undefined ? 1 : getEntityReq.data.current_page);
        }
    }

    const handleDeleteEntityReq = async (id) => {

        const deleteEntityReq = await EntityServices.deleteEntity({
            token: user.access_token,
            id: id
        });

        if(deleteEntityReq.status === false){
            ToastAlert.notifyError(deleteEntityReq.message);
            setEntity([]);
        }
        else{
            ToastAlert.notifySuccess(deleteEntityReq.message);
            handleGetEntityRequest();
        }
    }

    useEffect(() => {
        handleGetEntityRequest();
    }, [currentPage, taluk, createdOn, search]);


    // const [users, setUsers] = useState([]);

    // const handleGetUsersRequest = async () => {
    //     const getUsersReq = await AdminServices.getAllAuthAdminsSuperAdmin({
    //         token: user.access_token,
    //         created_on: "OLD",
    //         roles: "SUPER_ADMIN,ADMIN"
    //     });

    //     if(getUsersReq.status === false){
    //         ToastAlert.notifyError(getUsersReq.message);
    //         setUsers([]);
    //     }
    //     else{
    //         if(getUsersReq.data.length > 0){
    //             setUsers(getUsersReq.data);
    //         }
    //         else{
    //             setUsers([]);
    //         }
    //     }
    // }

    // useEffect(() => {
    //     handleGetUsersRequest();
    // }, []);


    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Institutions'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='List of All Institutions.'
                />
                <HeaderActions>
                    <DashboardHeaderSearchBar showAction={showSearchAction} action={''}>
                        <input type="text" className="cmsDashboardHeaderSearchBarInputTxt" placeholder="Search Institution" onChange={(e) => setSearch(e.target.value)} value={search} />
                    </DashboardHeaderSearchBar>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions>
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        <PlainContainer type="full" styles={{paddingTop: "15px", paddingRight: "25px", paddingBottom: "25px", paddingLeft: "25px"}}>
                            <DDEntityListFilters
                                // users={users}
                                taluks={taluks} 
                                taluk={taluk}
                                TalukTypeChanged={setTaluk}
                                createdOn={createdOn}
                                createdOnChanged={setCreatedOn} 
                            />
                        </PlainContainer>
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    {/* <TableHeadingItem size={0} hasChildren={true}>
                                        <TableCheckbox name={"items-selected-ckb"} />
                                    </TableHeadingItem> */}
                                    <TableHeadingItem size={5} lable={"name"} />
                                    <TableHeadingItem size={1} lable={"district"} />
                                    <TableHeadingItem size={1} lable={"taluk"} />
                                    {/* <TableHeadingItem size={1} lable={"organization Type"} /> */}
                                    {/* <TableHeadingItem size={1} lable={"no. of cities"} /> */}
                                    <TableHeadingItem size={0} lable={""} />
                                    <TableHeadingItem size={0} lable={""} />
                                    <TableHeadingItem size={1} lable={"Actions"} />
                                </TableHeading>
                                <TableRows>
                                    {
                                        entity.map((entity, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    {/* <TableRowItem size={0} hasChildren={true} isCenter={false}>
                                                        <TableCheckbox name={`items-selected-ckb-${1}`} />
                                                    </TableRowItem> */}
                                                    <TableRowItem size={5} isCenter={false} hasPreview={false} lable={entity?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={entity?.district?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={entity?.taluk?.name?.en} />
                                                    {/* <TableRowItem size={1} isCenter={false} lable={`${school?.created_by.first_name} (${school?.created_by?.role === "SUPER_ADMIN" ? "Super Admin" : "Admin" })`} /> */}
                                                    {/* <TableRowItem size={1} isCenter={false} lable={entity?.organization_type} /> */}
                                                    <TableRowItem size={0} isCenter={true} hasChildren={true}>
                                                        <TableRowIcons>
                                                            <TableRowIcon icon={icons.eye} color={"dark"} action={() => navigate(`${entity.id}`)} />
                                                        </TableRowIcons>
                                                    </TableRowItem>
                                                    <TableRowItem size={0} isCenter={true} hasChildren={true}>
                                                        <a href={`https://x.com/${entity?.twitter?.url}`} target='_blank'>
                                                            <TableRowIcons>
                                                                <TableRowIcon icon={icons.twitter} color={"dark"} action={() => console.log()} />
                                                            </TableRowIcons>
                                                        </a>
                                                        
                                                    </TableRowItem>
                                                    <TableRowItem size={1} isCenter={false} hasChildren={true}>
                                                        <TableRowActions>
                                                            <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => navigate(`edit/${entity?.id}`)}>edit</TableButton>
                                                            {/* <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => handleDeleteEntityReq(entity.id)}>delete</TableButton> */}
                                                        </TableRowActions>
                                                    </TableRowItem>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                        {
                            totalPagesCount > 1
                            ?
                                <PlainContainer type='full' styles={{ padding: "0px 25px" }}>
                                    <Pagination totalPages={totalPagesCount} setCurrentPage={setCurrentPage}/>
                                </PlainContainer>
                            :
                                <></>
                        }
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default DDEntityListPage