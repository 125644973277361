import React from 'react'
import { motion } from 'framer-motion'

const SidebarFileDetailStep = ({lable, value, lowercase=false, variants}) => {
    return (
        <motion.div className="fileManagerSidebarDetailsStep" variants={variants}>
            <div className="fileManagerSidebarDetailsStepLable">
                <p className="fileManagerSidebarDetailsStepLableTxt">{lable}</p>
            </div>
            <div className="fileManagerSidebarDetailsStepValue">
                <p className={`fileManagerSidebarDetailsStepValueTxt ${lowercase === true ? 'emailTxt' : ''}`}>{value}</p>
            </div>
        </motion.div>
    )
}

export default SidebarFileDetailStep