import React from 'react'
import FooterActions from './FooterActions'
import FooterButton from './FooterButton'
import FooterInfo from './FooterInfo'
import FooterCopyright from './FooterInfo/Copyright'
import FooterCMSv from './FooterInfo/CMSv'
import FooterPoweredBy from './FooterInfo/PoweredBy'
import { icons } from '../../../../../constants'

const WardenLayoutFooter = () => {
    return (
        <div className="dashboardFooter">
            {/* <FooterActions>
                <FooterButton 
                    lable={"watch support videos"}
                    icon={icons.film}
                    buttonBg={"primary"}
                    goTo={'/'}
                />
                <FooterButton 
                    lable={"read documentation"}
                    icon={icons.bookOpenSolid}
                    buttonBg={"greyDark"}
                    goTo={'/'}
                />
            </FooterActions> */}
            <FooterInfo>
                <FooterCopyright 
                    year={2024}
                />
                <FooterCMSv 
                    version={1}
                />
                <FooterPoweredBy 
                    logo="/images/logo-light-square-icon.png"
                />
            </FooterInfo>
        </div>
    )
}

export default WardenLayoutFooter