import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import icons from '../../../constants/icons';
import BasicBigHeader from '../../../components/dashboard/common/PageHeaders/BasicBigHeader';
import ContentWithUserDetails from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/ContentWithUserDetails';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import BasicStats from '../../../components/dashboard/common/BasicStats';
import BasicStat from '../../../components/dashboard/common/BasicStats/BasicStat';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableCheckbox from '../../../components/core/table/inputs/Checkbox';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import Pagination from '../../../components/core/table/pagination';
import {globals} from '../../../utils';
import Card from '../../../components/core/dashboard/Card';
import BasicListItem from '../../../components/dashboard/common/Requests/BasicListItem';
import BasicActivitiesList from '../../../components/dashboard/common/Activities/BasicActivitiesList';
import BasicActivityListItem from '../../../components/dashboard/common/Activities/BasicActivityListItem';
import { DDServices, StatsServices, TweetServices } from '../../../services';
import { ToastAlert } from '../../../utils';
import NoDataContainer from '../../../components/core/containers/NoDataContainer';

const DDHomePage = ({user}) => {
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    
    useEffect(() => {
        let nameArray = user.name.split(" ");
        setFirstName(nameArray[0]);
    }, [user]);

     const [stats,setStats] = useState([])

     const [tweets, setTweets] = useState([]);
     const [schools , setSchools] = useState([]);
     const [hostels,setHostels] = useState([]);
     const [taluks , setTaluks] = useState([])
     const [totalTweetsCount, setTotalTweetsCount] = useState(0);
     const [totalPagesCount, setTotalPagesCount] = useState(0);
     const [currentPage, setCurrentPage] = useState(1);


    const handleGetThisStatReq = async () => {
        const getThisStatReq = await StatsServices.getThisStats({
            token:user.access_token,
        })
        
        if(getThisStatReq.status === false){
            ToastAlert.notifyError(getThisStatReq.message);
            setStats([]);
        }
        else{
            setStats(getThisStatReq?.data);
        }
    }

    useEffect(()=>{
        handleGetThisStatReq()
    },[])

    const handleGetTweetsRequest = async () => {
        const getTweetsReq = await DDServices.getDDDashboardTweets({
            token: user.access_token,
        });

        if(getTweetsReq.status === false){
            ToastAlert.notifyError(getTweetsReq.message);
            setTweets([]);
        }
        else{
            const Tweets = getTweetsReq?.data?.map(tweet => ({
                ...tweet,
                createdAtFormatted : moment(tweet.createdAt).format('MMMM Do YYYY, h:mm:ss A'),
            }));
            setTweets(Tweets);
        }
    }

    // useEffect(() => {
    //     handleGetTweetsRequest();
    // },[]);

    const handleGetSchoolsRequest = async () => {
        const getSchoolsReq = await DDServices.getDDDashboardSchools({
            token: user.access_token,
        });

        if(getSchoolsReq.status === false){
            ToastAlert.notifyError(getSchoolsReq.message);
            setSchools([]);
        }
        else{
            const schools = getSchoolsReq?.data?.map(school => ({
                ...school,
                createdAtFormatted : moment(school.createdAt).format('MMMM Do YYYY, h:mm:ss A'),
            }));
            setSchools(schools);
        }
    }

    useEffect(() => {
        handleGetSchoolsRequest();
    },[]);

    const handleGetHostelsRequest = async () => {
        const getHostelReq = await DDServices.getDDDashboardHostels({
            token: user.access_token,
        });

        if(getHostelReq.status === false){
            ToastAlert.notifyError(getHostelReq.message);
            setHostels([]);
        }
        else{
            const hostels = getHostelReq?.data?.map(hostel => ({
                ...hostel,
                createdAtFormatted : moment(hostel.createdAt).format('MMMM Do YYYY, h:mm:ss A'),
            }));
            setHostels(hostels);
        }
    }

    useEffect(() => {
        handleGetHostelsRequest();
    },[]);

    const handleGetTaluksRequest = async () => {
        const getTaluksReq = await DDServices.getDDDashboardTaluks({
            token: user.access_token,
        });

        if(getTaluksReq.status === false){
            ToastAlert.notifyError(getTaluksReq.message);
            setTaluks([]);
        }
        else{
            const Taluks = getTaluksReq?.data?.map(taluk => ({
                ...taluk,
                createdAtFormatted : moment(taluk.createdAt).format('MMMM Do YYYY, h:mm:ss A'),
            }));
            setTaluks(Taluks);
        }
    }

    useEffect(() => {
        handleGetTaluksRequest();
    },[]);

    return(
        <>
            <BasicBigHeader>
                <ContentWithUserDetails 
                    avatar={'/images/default-avatar.jpg'}
                    heading={`welcome back, ${firstName}!`}
                    descriptionIcon={icons.bellSolid}
                    hasDescriptionIcon={true}
                    description={`Stay connected ${user?.name} : Create and review your tweets seamlessly!`}
                />
                {/* 
                    <HeaderActions>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg="primary"
                            borderRadius="full"
                            hasIcon={true}
                            iconPosition="left"
                            icon={icons.tickCircle}
                        >
                            pending approvals
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg="dashboard"
                            borderRadius="full"
                            hasIcon={true}
                            iconPosition="left"
                            icon={icons.settingsGear}
                        >
                            settings
                        </Button>
                    </HeaderActions> */}
            </BasicBigHeader>

            <BasicStats>
                <BasicStat 
                    cardHeading={"Taluks"}
                    statCount={stats?.no_of_taluks}
                    statLable="No. of Taluks"
                    cardStatColor="blue"
                    secondaryStatLable="Under Progress"
                    secondaryStatCount={20}
                    statId={`websites`}
                    //headingActionIcon={icons.rightArrow}
                />
                <BasicStat 
                    cardHeading={"Schools"}
                    statCount={stats?.no_of_schools}
                    statLable="No. of Schools"
                    cardStatColor="red"
                    secondaryStatLable="Feature Updates"
                    secondaryStatCount={12}
                    statId={`pendingRequests`}
                />
                <BasicStat 
                    cardHeading={"Hostels"}
                    statCount={stats?.no_of_hostels}
                    statLable="No. of Hostels"
                    cardStatColor="yellow"
                    secondaryStatLable="Regular"
                    secondaryStatCount={20}
                    statId={`pendingApprovals`}
                />
                <BasicStat 
                    cardHeading={"Tweets"}
                    statCount={stats?.no_of_tweets}
                    statLable="No. of Tweets"
                    cardStatColor="green"
                    secondaryStatLable="Staff Under Owner"
                    secondaryStatCount={20}
                    statId={`users`}
                />
            </BasicStats>


            <PlainContainer type="full" styles={{paddingLeft: '30px', paddingRight: '30px'}}>
                <SplitContainer type="full" styles={{margin: '0px', flex: '50%'}}>
                    <PlainContainer type="full">
                        <Card 
                            heading={"taluks"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryActionLable={"see all taluks"}
                            bottomPrimaryAction={() => navigate("/masterdata/taluk")}
                        >
                           <PlainContainer type="full" styles={{ marginTop: '5px' }}>
                                    {taluks.map((taluk) => (
                                        <BasicListItem 
                                            key={taluk.id} 
                                            heading={taluk?.name?.en} 
                                            hasPrimaryInfo={true}
                                            hasPrimaryInfoIcon={true}
                                            primaryInfoHeading={taluk?.district?.name?.en}
                                            primaryInfoIcon={icons.sitemap}
                                            hasSecondaryInfo={true}
                                            hasSecondaryInfoIcon={true}
                                            secondaryInfoHeading={taluk?.createdAtFormatted}
                                            secondaryInfoIcon={icons.clockSolid}
                                            hasAction={true}
                                            actionIcon={icons.rightArrow}
                                            action={() => console.log()} 
                                        />
                                    ))}
                                </PlainContainer>
                        </Card>
                    </PlainContainer>
                    <PlainContainer type="full">
                        <Card 
                            heading={"schools"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryActionLable={"see all schools"}
                            bottomPrimaryAction={() => navigate("/masterdata/institutions")}
                        >
                               <PlainContainer type="full" styles={{ marginTop: '5px' }}>
                                    {schools.map((school) => (
                                        <BasicListItem 
                                            key={school.id} 
                                            heading={school?.name?.en} 
                                            hasPrimaryInfo={true}
                                            hasPrimaryInfoIcon={true}
                                            primaryInfoHeading={`${school?.district?.name?.en}, ${school?.taluk?.name?.en}`}
                                            primaryInfoIcon={icons.sitemap}
                                            hasSecondaryInfo={true}
                                            hasSecondaryInfoIcon={true}
                                            secondaryInfoHeading={school?.createdAtFormatted}
                                            secondaryInfoIcon={icons.clockSolid}
                                            hasAction={true}
                                            actionIcon={icons.rightArrow}
                                            action={() => navigate(`/masterdata/institutions/${school?.id}`)} 
                                        />
                                    ))}
                                </PlainContainer> 
                     </Card>
                    </PlainContainer>

                </SplitContainer>
            </PlainContainer>

            <PlainContainer type="full" styles={{paddingLeft: '30px', paddingRight: '30px', marginTop: '30px', marginBottom: '30px'}}>
                <SplitContainer type="full"styles={{margin: '0px', flex: '50%'}}>
                    <PlainContainer type="full">
                        <Card 
                            heading={"hostels"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryActionLable={"see all hostels"}
                            bottomPrimaryAction={() => navigate("/masterdata/institutions")}
                        >
                           <PlainContainer type="full" styles={{ marginTop: '5px' }}>
                                {hostels.map((hostel) => (
                                    <BasicListItem 
                                        key={hostel.id} 
                                        heading={hostel?.name?.en} 
                                        hasPrimaryInfo={true}
                                        hasPrimaryInfoIcon={true}
                                        primaryInfoHeading={`${hostel?.district?.name?.en}, ${hostel?.taluk?.name?.en}`}
                                        primaryInfoIcon={icons.sitemap}
                                        hasSecondaryInfo={true}
                                        hasSecondaryInfoIcon={true}
                                        secondaryInfoHeading={hostel?.createdAtFormatted}
                                        secondaryInfoIcon={icons.clockSolid}
                                        hasAction={true}
                                        actionIcon={icons.rightArrow}
                                        action={() => navigate(`/masterdata/institutions/${hostel?.id}`)} 
                                    />
                                ))}
                            </PlainContainer>
                        </Card>
                    </PlainContainer>
                    <PlainContainer type="full">
                        {
                            tweets.length === 0
                            ?
                            <>
                            <Card 
                                heading={"tweets"} 
                                isHeadingAction={false} 
                                isBottomAction={false} 
                                isBottomPrimaryAction={false} 
                                bottomPrimaryActionLable={"see all tweets"}
                            >
                                <PlainContainer type="full" styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    {/* <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No Tweet Details found!"}
                                        description={"Update Tweet Details details by clicking on the below button."}
                                        hasSectionAction={false}
                                        // sectionAction={() => alert("section add")}
                                        // hasActions={true}
                                        // hasPrimaryAction={true}
                                        // primaryActionLable={"update"}
                                        // primaryAction={()=> console.log(true)}
                                        // hasSecondaryAction={true}
                                        // secondaryActionLable={"refresh"}
                                        // secondaryAction={() =>console.log("refresh")}
                                    /> */}

                                    <div className="noDataContainerDashboard">
                                        <div className="noDataContainerDashboardInner">
                                            <div className="noDataContainerDashboardIcon">
                                                <i className={`noDataContainerIco ${icons.sad}`}></i>
                                            </div>
                                            <div className="noDataContainerHeadingDescription">
                                                <div className="noDataContainerHeading">
                                                    <p className="noDataContainerHeadingTxt">No Tweets found!</p>
                                                </div>
                                                <div className="noDataContainerDescription">
                                                    <p className="noDataContainerDescriptionTxt">The tweets made by the wardens of your district will be displayed here. Comeback to this section later to review.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </PlainContainer>
                                
                            </Card>
                                
                            </>
                            :
                        <Card 
                            heading={"tweets"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryActionLable={"see all tweets"}
                        >
                            <PlainContainer type="full" styles={{ marginTop: '5px' }}>
                                    {tweets.map((tweet) => (
                                        <BasicListItem 
                                            key={tweet.id} 
                                            heading={tweet?.name?.en} 
                                            hasPrimaryInfo={true}
                                            hasPrimaryInfoIcon={true}
                                            primaryInfoHeading={tweet?.district?.name?.en}
                                            primaryInfoIcon={icons.sitemap}
                                            hasSecondaryInfo={true}
                                            hasSecondaryInfoIcon={true}
                                            secondaryInfoHeading={tweet?.createdAtFormatted}
                                            secondaryInfoIcon={icons.clockSolid}
                                            hasAction={true}
                                            actionIcon={icons.rightArrow}
                                            action={() => console.log()} 
                                        />
                                    ))}
                            </PlainContainer>
                        </Card>
                        }
                        
                    </PlainContainer>
                </SplitContainer>
            </PlainContainer>
            
            {/* <PlainContainer type="full" styles={{paddingLeft: '30px', paddingRight: '30px'}}>
                    <PlainContainer type="full">
                        <Card   
                            heading={"list of schools"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryAction={()=>navigate("/masterdata/get-tweet")}
                            bottomPrimaryActionLable={"see all schools"}
                        >
                            <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={2} lable={"schools"} />
                                    <TableHeadingItem size={1} lable={"Description"} />
                                    <TableHeadingItem size={2} lable={"Posted On"} />
                                    <TableHeadingItem size={1} lable={"created by"} />
                                </TableHeading>
                                <TableRows>
                                    {
                                        tweets.map((tweet, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    <TableRowItem size={2} isCenter={false} hasPreview={true} previewType={"image"} previewItem={`${globals.API_BASE_URL}${tweet?.image?.image_url?.full?.high_res}`} lable={tweet?.content} />
                                                    <TableRowItem size={1} isCenter={false} lable={tweet?.district?.name?.en} />
                                                    <TableRowItem size={2} isCenter={false} lable={tweet?.createdAtFormatted} />
                                                    <TableRowItem size={1} isCenter={false} lable={`${tweet?.created_by?.first_name} ${tweet?.created_by?.last_name}`} />
                                                  
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                        
                        </PlainContainer>
                         </Card>
                        </Card>
                    </PlainContainer>
                    </PlainContainer>

                    <PlainContainer type="full" styles={{paddingLeft: '30px', paddingRight: '30px'}}>
                    <PlainContainer type="full">
                        <Card   
                            heading={"list of Hostels"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryAction={()=>navigate("/masterdata/get-tweet")}
                            bottomPrimaryActionLable={"see all Hostels"}
                        >
                            <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={2} lable={"hostels"} />
                                    <TableHeadingItem size={1} lable={"Description"} />
                                    <TableHeadingItem size={2} lable={"Posted On"} />
                                    <TableHeadingItem size={1} lable={"created by"} />
                                </TableHeading>
                                <TableRows>
                                    {
                                        tweets.map((tweet, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    <TableRowItem size={2} isCenter={false} hasPreview={true} previewType={"image"} previewItem={`${globals.API_BASE_URL}${tweet?.image?.image_url?.full?.high_res}`} lable={tweet?.content} />
                                                    <TableRowItem size={1} isCenter={false} lable={tweet?.district?.name?.en} />
                                                    <TableRowItem size={2} isCenter={false} lable={tweet?.createdAtFormatted} />
                                                    <TableRowItem size={1} isCenter={false} lable={`${tweet?.created_by?.first_name} ${tweet?.created_by?.last_name}`} />
                                                  
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                        
                        </PlainContainer>
                         </Card>
                        </Card>
                    </PlainContainer>
                    </PlainContainer>

                    <PlainContainer type="full" styles={{paddingLeft: '30px', paddingRight: '30px'}}>
                    <PlainContainer type="full">
                        <Card   
                            heading={"list of taluks"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryAction={()=>navigate("/masterdata/get-tweet")}
                            bottomPrimaryActionLable={"see all taluks"}
                        >
                            <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={2} lable={"taluks"} />
                                    <TableHeadingItem size={1} lable={"Description"} />
                                    <TableHeadingItem size={2} lable={"Posted On"} />
                                    <TableHeadingItem size={1} lable={"created by"} />
                                </TableHeading>
                                <TableRows>
                                    {
                                        taluks.map((tweet, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    <TableRowItem size={2} isCenter={false} hasPreview={true} previewType={"image"} previewItem={`${globals.API_BASE_URL}${tweet?.image?.image_url?.full?.high_res}`} lable={tweet?.content} />
                                                    <TableRowItem size={1} isCenter={false} lable={tweet?.district?.name?.en} />
                                                    <TableRowItem size={2} isCenter={false} lable={tweet?.createdAtFormatted} />
                                                    <TableRowItem size={1} isCenter={false} lable={`${tweet?.created_by?.first_name} ${tweet?.created_by?.last_name}`} />
                                                  
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                        
                        </PlainContainer>
                         </Card>
                        </Card>
                    </PlainContainer>
                    </PlainContainer>

                    <PlainContainer type="full" styles={{paddingLeft: '30px', paddingRight: '30px'}}>
                    <PlainContainer type="full">
                        <Card   
                            heading={" List of Recent Tweets"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryAction={()=>navigate("/masterdata/get-tweet")}
                            bottomPrimaryActionLable={"see all Tweets"}
                        >
                            <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={2} lable={"tweets"} />
                                    <TableHeadingItem size={1} lable={"Description"} />
                                    <TableHeadingItem size={2} lable={"Posted On"} />
                                    <TableHeadingItem size={1} lable={"created by"} />
                                </TableHeading>
                                <TableRows>
                                    {
                                        tweets.map((tweet, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    <TableRowItem size={2} isCenter={false} hasPreview={true} previewType={"image"} previewItem={`${globals.API_BASE_URL}${tweet?.image?.image_url?.full?.high_res}`} lable={tweet?.content} />
                                                    <TableRowItem size={1} isCenter={false} lable={tweet?.district?.name?.en} />
                                                    <TableRowItem size={2} isCenter={false} lable={tweet?.createdAtFormatted} />
                                                    <TableRowItem size={1} isCenter={false} lable={`${tweet?.created_by?.first_name} ${tweet?.created_by?.last_name}`} />
                                                  
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                        
                        </PlainContainer>
                         </Card>
                        </Card>
                    </PlainContainer>
                    </PlainContainer> */}
                    {/* <PlainContainer type="full">
                        <Card 
                            heading={"pending approvals"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryActionLable={"see all recent approvals"}
                        >
                            <PlainContainer type="full" styles={{marginTop: '5px'}}>
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                            </PlainContainer>
                        </Card>
                    </PlainContainer> */}
                {/* </SplitContainer> */}
            

            {/* <PlainContainer type="full" styles={{paddingLeft: '30px', paddingRight: '30px', marginTop: '30px', marginBottom: '30px'}}>
                <SplitContainer type="full"styles={{margin: '0px', flex: '50%'}}>
                    <PlainContainer type="full">
                        <Card 
                            heading={"recent complaints"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryActionLable={"see all recent complaints"}
                        >
                            <PlainContainer type="full" styles={{marginTop: '5px'}}>
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                                <BasicListItem 
                                    heading={"new website creation"}
                                    hasPrimaryInfo={true}
                                    hasPrimaryInfoIcon={true}
                                    primaryInfoHeading={"social audit directorate"}
                                    primaryInfoIcon={icons.sitemap}
                                    hasSecondaryInfo={true}
                                    hasSecondaryInfoIcon={true}
                                    secondaryInfoHeading={"monday, 10:34 am"}
                                    secondaryInfoIcon={icons.clockSolid}
                                    hasAction={true}
                                    actionIcon={icons.rightArrow}
                                    action={() => navigate('/')}
                                />
                            </PlainContainer>
                        </Card>
                    </PlainContainer>
                    <PlainContainer type="full">
                        <Card 
                            heading={"recent activities"} 
                            isHeadingAction={false} 
                            isBottomAction={true} 
                            isBottomPrimaryAction={true} 
                            bottomPrimaryActionLable={"see all recent activities"}
                        >
                            <PlainContainer type="full" styles={{marginTop: '5px', padding: '0px 25px'}}>
                                <BasicActivitiesList>
                                    <BasicActivityListItem 
                                        activityType="updated"
                                        activityBy="sharath"
                                        activityIcon={icons.image}
                                        activityPrimaryEntity="disclaimer"
                                        activitySecondaryEntity="defaults"
                                        activityTime={"monday, 10:50 am"}
                                        activityLink="/"
                                    />
                                    <BasicActivityListItem 
                                        activityType="updated"
                                        activityBy="sharath"
                                        activityIcon={icons.add}
                                        activityPrimaryEntity="disclaimer"
                                        activitySecondaryEntity="defaults"
                                        activityTime={"monday, 10:50 am"}
                                        activityLink="/"
                                    />
                                    <BasicActivityListItem 
                                        activityType="updated"
                                        activityBy="sharath"
                                        activityIcon={icons.add}
                                        activityPrimaryEntity="disclaimer"
                                        activitySecondaryEntity="defaults"
                                        activityTime={"monday, 10:50 am"}
                                        activityLink="/"
                                    />
                                    <BasicActivityListItem 
                                        activityType="updated"
                                        activityBy="sharath"
                                        activityIcon={icons.add}
                                        activityPrimaryEntity="disclaimer"
                                        activitySecondaryEntity="defaults"
                                        activityTime={"monday, 10:50 am"}
                                        activityLink="/"
                                    />
                                    <BasicActivityListItem 
                                        activityType="updated"
                                        activityBy="sharath"
                                        activityIcon={icons.add}
                                        activityPrimaryEntity="disclaimer"
                                        activitySecondaryEntity="defaults"
                                        activityTime={"monday, 10:50 am"}
                                        activityLink="/"
                                    />
                                </BasicActivitiesList>
                            </PlainContainer>
                        </Card>
                    </PlainContainer>
                </SplitContainer>
            </PlainContainer> */}
                        {
                            totalPagesCount > 1
                            ?
                                <PlainContainer type='full' styles={{ padding: "0px 25px" }}>
                                    <Pagination totalPages={totalPagesCount} setCurrentPage={setCurrentPage}/>
                                </PlainContainer>
                            :
                                <></>
                        }
        </>
    )
}

export default DDHomePage