import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DefaultHeader from '../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import DashboardHeaderSearchBar from '../../../../components/core/form/DashboardHeaderSearchBar';
import Button from '../../../../components/core/dashboard/Button';
import { icons } from '../../../../constants';
import PlainContainer from '../../../../components/core/containers/PlainContainer';
import Card from '../../../../components/core/dashboard/Card';
import Table from '../../../../components/core/table/containers/Table';
import TableHeading from '../../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../../components/core/table/containers/TableHeadingItem';
import TableCheckbox from '../../../../components/core/table/inputs/Checkbox';
import TableRows from '../../../../components/core/table/containers/TableRows';
import TableRow from '../../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../../components/core/table/inputs/Button';
// import AdminUsersFilters from './AdminUsersFilters';
import { globals, ToastAlert } from '../../../../utils';
import { AdminServices } from '../../../../services';

const SuperAdminHelplineUsersPage = ({user}) => {
    const navigate = useNavigate();
    
    const [search, setSearch] = useState("");
    const [showSearchAction, setShowSearchAction] = useState(false);

    useEffect(() => {
        if(search.length > 0){
            setShowSearchAction(true);
        }
        else{
            setShowSearchAction(false);
        }
    }, [search]);

    let date = new Date();

    const [users, setUsers] = useState([]);
    const [totalUsersCount, setTotalUsersCount] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const handleGetUsersRequest = async () => {
        const getUsersReq = await AdminServices.getAuthAdminsSuperAdmin({
            token: user.access_token,
            created_on: "RECENT",
            roles: "HELPLINE"
        });

        console.log(getUsersReq);

        if(getUsersReq.status === false){
            ToastAlert.notifyError(getUsersReq.message);
            setUsers([]);
            setTotalUsersCount(0);
            setTotalPagesCount(0);
            setCurrentPage(1);
        }
        else{
            if(getUsersReq.data.items.length > 0){
                setUsers(getUsersReq.data.items);
                setTotalUsersCount(getUsersReq.data.total_items);
                setTotalPagesCount(getUsersReq.data.total_pages);
                setCurrentPage(getUsersReq.data.current_page);
            }
            else{
                setUsers([]);
                setTotalUsersCount(0);
                setTotalPagesCount(0);
                setCurrentPage(1);
            }
        }
    }

    useEffect(() => {
        handleGetUsersRequest();
    }, [currentPage]);

    return(
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Users'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='2 Admins, 5 Creators, 3 Moderators, 2 Approvers.'
                />
                <HeaderActions>
                    <DashboardHeaderSearchBar showAction={showSearchAction} action={''}>
                        <input type="text" className="cmsDashboardHeaderSearchBarInputTxt" placeholder="Search User" onChange={(e) => setSearch(e.target.value)} value={search} />
                    </DashboardHeaderSearchBar>

                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                        
                </HeaderActions>
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        <PlainContainer type="full" styles={{paddingTop: "15px", paddingRight: "25px", paddingBottom: "25px", paddingLeft: "25px"}}>
                            {/* <AdminUsersFilters /> */}
                        </PlainContainer>

                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={0} hasChildren={true}>
                                        <TableCheckbox name={"items-selected-ckb"} />
                                    </TableHeadingItem>
                                    <TableHeadingItem size={3} lable={"full name"} />
                                    <TableHeadingItem size={0} lable={"role"} />
                                    <TableHeadingItem size={1} lable={"last login"} />
                                    <TableHeadingItem size={0} lable={""} />
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>
                                <TableRows>
                                    {
                                        users.map((user) => {
                                            return (
                                                <TableRow isCenter={false} key={user.id}>
                                                    <TableRowItem size={0} hasChildren={true} isCenter={false}>
                                                        <TableCheckbox name={`items-selected-ckb-${1}`} />
                                                    </TableRowItem>
                                                    <TableRowItem size={3} isCenter={false} hasPreview={true} previewType={'image'} previewItem={`${globals.API_BASE_URL}${user.profile_pic.thumbnail.low_res}`} lable={`${user.first_name} ${user.last_name}`} />
                                                    <TableRowItem size={0} isCenter={false} lable={user.role === "ADMIN" ? "admin" :user.role === "DD" ? "dd" : user.role === "CREATOR" ? "creator" : user.role === "MODERATOR" ? "moderator" : user.role === "APPROVER" ? "approver" : user.role === "CONTENT_REVIEWER" ? "content reviewer" : "invalid"} />
                                                    <TableRowItem size={1} isCenter={false} lable={user.last_login_time ? `${moment(user.last_login_time).format('DD/MM/YY, h:mm a')}` : `Never Logged In`} />
                                                    <TableRowItem size={0} isCenter={true} hasChildren={true}>
                                                        <TableRowIcons>
                                                            <TableRowIcon icon={icons.eye} color={"dark"} action={() => console.log()} />
                                                        </TableRowIcons>
                                                    </TableRowItem>
                                                    <TableRowItem size={2} isCenter={false} hasChildren={true}>
                                                        <TableRowActions>
                                                            <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"}>edit</TableButton>
                                                            <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => console.log('')}>delete</TableButton>
                                                        </TableRowActions>
                                                    </TableRowItem>
                                                </TableRow>
                                            )
                                        })
                                    }
                                    
                                </TableRows>
                            </Table>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default SuperAdminHelplineUsersPage