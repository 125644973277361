import React from 'react'

const FoldersAndFiles = ({showSidebar=false, children}) => {
    return (
        <div className={`fileManagerContentContainer ${showSidebar === true ? 'hasSidebar' : ''}`}>
            {children}
        </div>
    )
}

export default FoldersAndFiles