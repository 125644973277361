import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Card from '../../../components/core/dashboard/Card';
import CardHeading from '../../../components/core/typography/dashboard/CardHeading';
import CardDescription from '../../../components/core/typography/dashboard/CardDescription';
import TextInputRT from '../../../components/core/form/TextInputRT';
import FormActions from '../../../components/form/FormActions';
import { ToastAlert, validations } from '../../../utils';
import SelectInput from '../../../components/core/form/SelectInput';
import DistrictServices from '../../../services/Location/District';
import TalukServices from '../../../services/Location/Taluk';
import { DDServices } from '../../../services';

const AddDDTalukPage = ({user}) => {
    const navigate = useNavigate();

    // const [districts, setDistricts] = useState([]);

    // const handleGetPublicDistrictsRequest = async () => {
    //     const getDistrictsReq = await DistrictServices.getPublicDistricts();

    //     if(getDistrictsReq.status === false){
    //         ToastAlert.notifyError(getDistrictsReq.message);
    //         setDistricts([]);
    //     }
    //     else{
    //         setDistricts(getDistrictsReq.data);
    //     }
    // }

    // useEffect(() => {
    //     handleGetPublicDistrictsRequest();
    // }, []);

    // useEffect(() => {
    //     console.log(districts);
    // }, [districts]);

    // const [district, setDistrict] = useState(0);
    // const [hasDistrictIcon, setHasDistrictIcon] = useState(false);
    // const [districtIcon, setDistrictIcon] = useState("");
    // const [districtIconPosition, setDistrictIconPosition] = useState("right");
    // const [districtIconType, setDistrictIconType] = useState("");
    // const [hasDistrictMessage, setHasDistrictMessage] = useState(false);
    // const [districtMessage, setDistrictMessage] = useState("");
    // const [districtMessageType, setDistrictMessageType] = useState("");

    const [enName, setEnName] = useState("");
    const [hasEnNameIcon, setHasEnNameIcon] = useState(false);
    const [enNameIcon, setEnNameIcon] = useState("");
    const [enNameIconPosition, setEnNameIconPosition] = useState("right");
    const [enNameIconType, setEnNameIconType] = useState("");
    const [hasEnNameMessage, setHasEnNameMessage] = useState(false);
    const [enNameMessage, setEnNameMessage] = useState("");
    const [enNameMessageType, setEnNameMessageType] = useState("");

    const [knName, setKnName] = useState("");
    const [hasKnNameIcon, setHasKnNameIcon] = useState(false);
    const [knNameIcon, setKnNameIcon] = useState("");
    const [knNameIconPosition, setKnNameIconPosition] = useState("right");
    const [knNameIconType, setKnNameIconType] = useState("");
    const [hasKnNameMessage, setHasKnNameMessage] = useState(false);
    const [knNameMessage, setKnNameMessage] = useState("");
    const [knNameMessageType, setKnNameMessageType] = useState("");

    const enNameChanged = async (e) => {
        const isEnNameValid = await validations.disableSpecialCharacterUserName(e);
        if(isEnNameValid.status){
            setEnName(e);
        }
        else if(e.length === 0){
            setEnName('');
        }
    }

    const knNameChanged = async (e) => {
        // const isKnNameValid = await validations.disableSpecialCharacter(e);
        // if(isKnNameValid){
        //     setKnName(e);
        // }
        // else if(e.length === 0){
        //     setKnName('');
        // }

        setKnName(e);
    }

    const [submitButtonHasIcon, setSubmitButtonHasIcon] = useState(false);
    const [submitButtonIconPosition, setSubmitButtonIconPosition] = useState('left');
    const [submitButtonIco, setSubmitButtonIco] = useState(icons.tick);
    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleAddTalukSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        // if(district === 0){
        //     setHasDistrictIcon(true);
        //     setDistrictIcon(icons.wrongCircle);
        //     setDistrictIconType("error");
        //     setHasDistrictMessage(true);
        //     setDistrictMessage("Invalid District");
        //     setDistrictMessageType("error");
        // }
        // else{
        //     setHasDistrictIcon(true);
        //     setDistrictIcon(icons.tickCircle);
        //     setDistrictIconType("success");
        //     setHasDistrictMessage(false);
        //     setDistrictMessage("");
        //     setDistrictMessageType("");
        // }

        const isEnNameValid = await validations.validateUserName(enName);

        if(isEnNameValid.status === false){
            setHasEnNameIcon(true);
            setEnNameIcon(icons.wrongCircle);
            setEnNameIconType("error");
            setHasEnNameMessage(true);
            setEnNameMessage("Invalid English Name");
            setEnNameMessageType("error");
        }
        else{
            setHasEnNameIcon(true);
            setEnNameIcon(icons.tickCircle);
            setEnNameIconType("success");
            setHasEnNameMessage(false);
            setEnNameMessage("");
            setEnNameMessageType("");
        }

        if(knName === ""){
            setHasKnNameIcon(true);
            setKnNameIcon(icons.wrongCircle);
            setKnNameIconType("error");
            setHasKnNameMessage(true);
            setKnNameMessage("Invalid Kannada Name");
            setKnNameMessageType("error");
        }
        else{
            setHasKnNameIcon(true);
            setKnNameIcon(icons.tickCircle);
            setKnNameIconType("success");
            setHasKnNameMessage(false);
            setKnNameMessage("");
            setKnNameMessageType("");
        }

        if(isEnNameValid.status === false || knName === "" ){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveTalukReq = await DDServices.saveDDTaluk({
                en_name: enName,
                kn_name: knName,
                token: user.access_token
            });

            if(saveTalukReq.status === false){
                ToastAlert.notifyError(saveTalukReq.message);
                setSubmitButtonLoading(false);
                setHasEnNameIcon(false);
                setEnNameIcon("");
                setEnNameIconType("");
                setHasKnNameIcon(false);
                setKnNameIcon("");
                setKnNameIconType("");
                
            }
            else{
                ToastAlert.notifySuccess(saveTalukReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonHasIcon(true);
                setSubmitButtonLable('Success');
                navigate(-1);
            }
        }
    }

    return(
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Taluks'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Add any Taluk of Karnataka'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.list}
                        action={()=> navigate('/masterdata/locations/taluks') }
                    >
                        View Taluks
                    </Button>
                </HeaderActions>
            </DefaultHeader>
            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type='full' styles={{paddingTop: "15px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                        <CardHeading heading={"add new district"} color="dark" />
                        <CardDescription description={"country: India"} />

                        <PlainContainer type='full' styles={{marginTop: "30px"}}>
                            <form onSubmit={(e) => handleAddTalukSubmission(e)} noValidate>
                                <div className="cmsForm">
                                    {/* <SelectInput 
                                        hasLable={true}
                                        lable="District"
                                        isMandatory={true}
                                        hasIcon={hasDistrictIcon}
                                        icon={districtIcon}
                                        iconPosition={districtIconPosition}
                                        iconType={districtIconType}
                                        hasMessage={hasDistrictMessage}
                                        message={districtMessage}
                                        messageType={districtMessageType}
                                    >
                                        <select className="cmsFormStepInputSelect" value={district} onChange={(e) => setDistrict(e.target.value)}>
                                            <option value={0} disabled={true}>Select District</option>
                                            {
                                                districts.map((dt) => {
                                                    return (
                                                        <option key={dt.id} value={dt.id}>{dt?.name?.en}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </SelectInput> */}
                                    <SplitContainer>
                                        <TextInputRT 
                                            hasLable={true}
                                            lable="English Name"
                                            isMandatory={true}
                                            hasIcon={hasEnNameIcon}
                                            icon={enNameIcon}
                                            iconPosition={enNameIconPosition}
                                            iconType={enNameIconType}
                                            hasMessage={hasEnNameMessage}
                                            message={enNameMessage}
                                            messageType={enNameMessageType}
                                        >
                                            <input 
                                                type="text" 
                                                className="cmsFormStepInputText" 
                                                placeholder={"Enter Name"}
                                                onChange={(e) => enNameChanged(e.target.value)}
                                                value={enName}
                                            />
                                        </TextInputRT>
                                        <TextInputRT 
                                            hasLable={true}
                                            lable="Kannada Name"
                                            isMandatory={true}
                                            hasIcon={hasKnNameIcon}
                                            icon={knNameIcon}
                                            iconPosition={knNameIconPosition}
                                            iconType={knNameIconType}
                                            hasMessage={hasKnNameMessage}
                                            message={knNameMessage}
                                            messageType={knNameMessageType}
                                        >
                                            <input 
                                                type="text" 
                                                className="cmsFormStepInputText" 
                                                placeholder={"Enter Name"}
                                                onChange={(e) => knNameChanged(e.target.value)}
                                                value={knName}
                                            />
                                        </TextInputRT>
                                    </SplitContainer>
                                    <FormActions>
                                        <Button 
                                            type="submit"
                                            bgType="fill"
                                            width="auto"
                                            bg={submitButtonBg}
                                            borderRadius="short"
                                            hasIcon={submitButtonHasIcon}
                                            iconPosition={submitButtonIconPosition}
                                            icon={submitButtonIco}
                                            disabled={submitButtonDisabled}
                                            isLoading={submitButtonLoading}
                                        >
                                            {submitButtonLable}
                                        </Button>
                                        <Button 
                                            type="button"
                                            bgType="fill"
                                            width="auto"
                                            bg={cancelButtonBg}
                                            borderRadius="short"
                                            action={() => navigate(-1)}
                                        >
                                            {cancelButtonLable}
                                        </Button>
                                    </FormActions>
                                </div>
                            </form>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default AddDDTalukPage