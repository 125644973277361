import React from 'react'

const FolderFileAndSidebar = ({ children }) => {
    return (
        <div className="fileManagerContentSidebar">
            {children}
        </div>
    )
}

export default FolderFileAndSidebar