import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import DefaultHeader from '../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../../components/core/dashboard/Button';
import PlainContainer from '../../../../components/core/containers/PlainContainer';
import Card from '../../../../components/core/dashboard/Card';
import CardHeading from '../../../../components/core/typography/dashboard/CardHeading';
import CardDescription from '../../../../components/core/typography/dashboard/CardDescription';
import TableCheckbox from '../../../../components/core/table/inputs/Checkbox';
import TableButton from '../../../../components/core/table/inputs/Button';
import Table from '../../../../components/core/table/containers/Table';
import TableHeading from '../../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../../components/core/table/containers/TableHeadingItem';
import TableRows from '../../../../components/core/table/containers/TableRows';
import TableRow from '../../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../../components/core/table/containers/TableRowItem';
import TableRowActions from '../../../../components/core/table/containers/TableRowActions';
import TableRowIcons from '../../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../../components/core/table/containers/TableRowIcon';
import { icons, constants } from '../../../../constants';
import { ToastAlert, functions } from '../../../../utils/';
import TableIconButton from '../../../../components/core/table/inputs/IconButton';
import TableErrorPlain from '../../../../components/core/typography/dashboard/TableErrorPlain';
import TooltipWrapper from '../../../../components/core/tooltip';
import TooltipContent from '../../../../components/core/tooltip/content';
import TooltipList from '../../../../components/core/tooltip/list';
import TooltipListItem from '../../../../components/core/tooltip/list/item';
import TooltipContentText from '../../../../components/core/typography/dashboard/TooltipContentText';
import Backdrop from '../../../../components/core/containers/Backdrop';
import BasicImageCrop from '../../../../components/dashboard/common/ImageCrop/BasicImageCrop';
import PopUp from '../../../../components/core/popup';
import BasicCropPopUp from '../../../../components/dashboard/popups/BasicCropPopUp';
import FileServices from '../../../../services/Media/File';


const SuperAdminAddFilesPage = ({user}) => {
    const navigate = useNavigate();

    const [files, setFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    let fileTypes = constants.allowedFileTypes;
    const [acceptedFileTypes, setAcceptedFileTypes] = useState(fileTypes.map(type => type.mimetype).join());
    const [showAvailableFileTypesTooltip, setShowAvailableFileTypesTooltip] = useState(false);
    const [showFileSizeLimitExceededTooltip, setShowFileSizeLimitExceededTooltip] = useState(false);

    const [showImageCropPopUp, setShowImageCropPopUp] = useState(false);
    const [fileToCrop, setFileToCrop] = useState(null);
    const [fileToCropFileData, setFileToCropFileData] = useState(null);

    const [selectedImagesCount, setSelectedImagesCount] = useState(0);
    const [selectedAudiosCount, setSelectedAudiosCount] = useState(0);
    const [selectedVideosCount, setSelectedVideosCount] = useState(0);
    const [selectedDocsCount, setSelectedDocsCount] = useState(0);

    const filesInputRef = useRef();

    const checkFilesCountOfEachFileTypeHandler = () => {
        let temp_selected_images_count = 0;
        let temp_selected_audios_count = 0;
        let temp_selected_videos_count = 0;
        let temp_selected_docs_count = 0;

        selectedFiles.map(s_file => {
            if(s_file.document_type === "image"){
                temp_selected_images_count += 1;
            }
            else if(s_file.document_type === "audio"){
                temp_selected_audios_count += 1;
            }
            else if(s_file.document_type === "video"){
                temp_selected_videos_count += 1;
            }
            else if(s_file.document_type === "document"){
                temp_selected_docs_count += 1;
            }
        });

        setSelectedImagesCount(temp_selected_images_count);
        setSelectedAudiosCount(temp_selected_audios_count);
        setSelectedVideosCount(temp_selected_videos_count);
        setSelectedDocsCount(temp_selected_docs_count);
    }

    useEffect(() => {
        if(selectedFiles.length != 0){
            checkFilesCountOfEachFileTypeHandler();
        }
    }, [selectedFiles]);

    const handleFileUpload = async (e) => {
        e.preventDefault();

        if(e.target.files.length > constants.maxFileSelection){
            ToastAlert.notifyError(`You can select upto ${constants.maxFileSelection} files at once.`);
            return
        }
        else{
            let temp_files = [];
            Array.from(e.target.files).forEach(file => temp_files.push(file));

            const temp_selected_files = [...files];
            const selected_files = [...selectedFiles];

            // Step 1 - Loop through each selected file
            temp_files.map((file, i) => {

                let isFileTypeError = false;
                let isFileSizeError = false;

                // Step 1.1 - Check if the file type is allowed to be uploaded
                const thisFileType = file.type;

                const acceptedFileTypesArray = acceptedFileTypes.split(',');
                const fileTypeIndexCheck = acceptedFileTypesArray.indexOf(thisFileType);

                // "fileTypeIndexCheck" will return -1 If not supported & will return 0 to any positive integer if supported.
                if(fileTypeIndexCheck === -1){
                    isFileTypeError = true;
                }
                else{
                    isFileTypeError = false;
                }

                // Step 1.2 - Get the max file size limit defined for this file type.

                let max_upload_size_of_this_file_type;

                if(isFileTypeError === true){
                    max_upload_size_of_this_file_type = 0;
                }
                else{
                    max_upload_size_of_this_file_type = constants.allowedFileTypes[fileTypeIndexCheck].max_upload_size;
                }
                
                    
                const selectedFileSizeInMegaBytesWithDecimal = (file.size / (1024*1024)).toFixed(2);

                const selectedFileSizeInMegaBytesWithoutDecimal = Math.floor(selectedFileSizeInMegaBytesWithDecimal);

                // Step 1.3 - Check if the selected file size is lesser than the allowed size.

                if(isFileSizeError === false){
                    if(selectedFileSizeInMegaBytesWithoutDecimal >= max_upload_size_of_this_file_type){
                        isFileSizeError = true;
                    }
                    else{
                        isFileSizeError = false;
                    }
                }
                else{
                    isFileSizeError = false;
                }
                
                // Step 1.4 - Create a preview if the selected file type is image
                
                let selectedFileType;

                if(isFileTypeError === false){
                    selectedFileType = constants.allowedFileTypes[fileTypeIndexCheck].type;
                }
                else{
                    selectedFileType = "unkown";
                }

                let preview_file = URL.createObjectURL(file);
                
                // Step 1.5 - Create a new file object which will be used to push to the preview files array

                const file_data = {
                    id: `${Date.now()}${i}`,
                    file: file,
                    name: file.name,
                    document_type: selectedFileType,
                    preview: selectedFileType === "image" ? preview_file : isFileTypeError === false ? constants.allowedFileTypes[fileTypeIndexCheck].icon : "",
                    file_path: preview_file,
                    file_size: functions.getReadableFileSize(file.size),
                    max_file_size: max_upload_size_of_this_file_type,
                    show_crop: selectedFileType === "image" ? true : false,
                    // show_preview: selectedFileType === "image" || selectedFileType === "document" ? true : false,
                    show_preview: true,
                    is_file_type_error: isFileTypeError,
                    is_file_size_error: isFileSizeError,
                    is_allowed: isFileTypeError === true || isFileSizeError === true ? false : true,
                    is_uploading: false,
                    is_uploaded: false
                }

                selected_files.push(file_data);
                temp_selected_files.push(file);
            });

            setSelectedFiles(selected_files);
            setFiles(temp_selected_files);
            filesInputRef.current.value = "";
        }
    }

    const openFileInNewTab = (file) => {
        window.open(file, '_blank');
    }

    const showAvailableFileTypesTooltipHandler = () => {
        setShowAvailableFileTypesTooltip(showAvailableFileTypesTooltip === true ? false : true);
    }

    const showFileSizeLimitExceededTooltipHandler = () => {
        setShowFileSizeLimitExceededTooltip(showFileSizeLimitExceededTooltip === true ? false : true);
    }

    const cropThisFileHandler = async (file) => {
        const file_url = await functions.convertFileToBase64(file.file);
        setFileToCrop(file_url);
        setFileToCropFileData(file);
        setShowImageCropPopUp(true);
    }

    const handleConcludeCrop = async (e) => {
        if(e.status === false){
            setShowImageCropPopUp(false); 
        }
        else{

            const selectedFileIndex = selectedFiles.findIndex(s_file => s_file.id === fileToCropFileData.id);

            let temp_selected_files = selectedFiles;

            temp_selected_files[selectedFileIndex].preview = e.preview;
            temp_selected_files[selectedFileIndex].file = e.file;

            temp_selected_files[selectedFileIndex].file_path = URL.createObjectURL(e.file);;
            temp_selected_files[selectedFileIndex].file_size = functions.getReadableFileSize(e.file.size)

            setSelectedFiles(temp_selected_files);

            setShowImageCropPopUp(false); 
        }
    }

    const deleteSelectedFileHandler = async (file_id) => {
        const temp_selected_files = [...selectedFiles];
        let selected_file_index = temp_selected_files.findIndex(s_file => s_file.id === file_id);
        temp_selected_files.splice(selected_file_index, 1);
        setSelectedFiles(temp_selected_files);
    }

    const uploadThisFile = async (file_id) => {
        let temp_selected_files = [...selectedFiles];
        let selected_file_index = temp_selected_files.findIndex(s_file => s_file.id === file_id);
        temp_selected_files[selected_file_index].is_uploading = true;
        setSelectedFiles(temp_selected_files);

        const uploadFileReq = await FileServices.saveThisFile({
            file: selectedFiles[selected_file_index],
            token: user.access_token
        });

        if(uploadFileReq.status === false){
            if(uploadFileReq.status_code === 403){
                ToastAlert.notifyError(uploadFileReq.message);
                temp_selected_files[selected_file_index].is_uploading = false;
                temp_selected_files[selected_file_index].is_uploaded = false;
                setSelectedFiles(temp_selected_files);
            }
            else{
                ToastAlert.notifyError(uploadFileReq.message);
                temp_selected_files[selected_file_index].is_uploading = false;
                temp_selected_files[selected_file_index].is_uploaded = false;
                setSelectedFiles(temp_selected_files);
            }
        }
        else{
            ToastAlert.notifySuccess("Media upload successfull");
            temp_selected_files[selected_file_index].is_uploading = false;
            temp_selected_files[selected_file_index].is_uploaded = true;
            console.log(temp_selected_files[selected_file_index]);
            setSelectedFiles([...temp_selected_files]);
            // setShowUploadMediaTab(false);
            // setShowMediaLibraryTab(true);
        }
    }

    return(
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Upload Files'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Images, Photos, Videos, Documents & More.'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.imageVideo}
                        action={()=> navigate('/general/file-manager') }
                    >
                        manage files
                    </Button>
                </HeaderActions>
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{padding: "15px 25px 25px"}}>
                        <div className="uploadFilesDropzone">
                            <div className="uploadFilesDropzoneInner">
                                <div className="uploadFilesDropzoneIcon">
                                    <i className={`${icons.imageVideo} uploadFilesDropzoneIco`}></i>
                                </div>
                                <div className="uploadFilesDropzoneDetails">
                                    <div className="uploadFilesDropzoneHeading">
                                        <p className="uploadFilesDropzoneHeadingTxt">Drag &amp; Drop or Click To Select Files</p>
                                    </div>
                                    <div className="uploadFilesDropzoneDescription">
                                        <p className="uploadFilesDropzoneDescriptionTxt">You can upload upto {constants.maxFileSelection} files at once.</p>
                                        <p className="uploadFilesDropzoneDescriptionTxt">Maximum Sizes: Images/Photos: {constants.fileTypeLimits.image} MB, Videos: {constants.fileTypeLimits.video} MB, Documents: {constants.fileTypeLimits.document} MB.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="uploadFilesDropzoneInput">
                                <input type="file" className="uploadFilesDropzoneInputFile" accept={acceptedFileTypes} ref={filesInputRef} onChange={(e) => handleFileUpload(e)} multiple />
                            </div>
                        </div>
                    </PlainContainer>
                    {
                        selectedFiles.length != 0
                        ?
                            <PlainContainer type="full" styles={{marginTop: "5px", paddingBottom: "25px"}}>
                                <PlainContainer type="full" styles={{paddingLeft: "25px", paddingRight: "25px"}}>
                                    <CardHeading heading={"Selected Files"} color="dark" />
                                    <CardDescription description={
                                        `
                                            ${selectedImagesCount != 0 ? `${selectedImagesCount} ${selectedImagesCount > 1 ? 'images' : 'image'}${selectedAudiosCount === 0 && selectedVideosCount === 0 && selectedDocsCount === 0 ? '' : ', '}` : ''} 
                                            ${selectedAudiosCount != 0 ? `${selectedAudiosCount} ${selectedAudiosCount > 1 ? 'audios' : 'audio'}${selectedVideosCount === 0 && selectedDocsCount === 0 ? '' : ', '}` : ''} 
                                            ${selectedVideosCount != 0 ? `${selectedVideosCount} ${selectedVideosCount > 1 ? 'videos' : 'video'}${selectedDocsCount === 0 ? '' : ', '}` : ''} 
                                            ${selectedDocsCount != 0 ? `${selectedDocsCount} ${selectedDocsCount > 1 ? 'documents' : 'document'}` : ''} 
                                            Selected`
                                        } />
                                </PlainContainer>
                                <PlainContainer type="full" styles={{paddingTop: "15px", paddingBottom: "25px"}}>
                                    <Table>
                                        <TableHeading>
                                            <TableHeadingItem size={0} hasChildren={true}>
                                                <TableCheckbox name={"items-selected-ckb"} />
                                            </TableHeadingItem>
                                            <TableHeadingItem size={5} lable={"File Name"} />
                                            <TableHeadingItem size={1} lable={"File Type"} />
                                            <TableHeadingItem size={1} lable={"File Size"} />
                                            <TableHeadingItem size={1} lable={""} />
                                            <TableHeadingItem size={3} lable={"Actions"} />
                                        </TableHeading>
                                        {
                                            selectedFiles.length != 0
                                            ?
                                                <TableRows>
                                                    {
                                                        selectedFiles.map((selectedFile) => {
                                                            return (
                                                                <TableRow isCenter={false} key={selectedFile.id}>
                                                                    <TableRowItem size={0} hasChildren={true} isCenter={false}>
                                                                        <TableCheckbox name={`items-selected-ckb-${selectedFile.id}`} />
                                                                    </TableRowItem>
                                                                    <TableRowItem size={5} isCenter={false} hasPreview={selectedFile.is_allowed === true ? true : false} previewType={selectedFile.document_type} previewItem={selectedFile.preview} lable={selectedFile.name} isCapital={false} />
                                                                    <TableRowItem size={1} isCenter={false} lable={selectedFile.document_type} />
                                                                    <TableRowItem size={1} isCenter={false} lable={selectedFile.file_size} />
                                                                    <TableRowItem size={1} isCenter={true} hasChildren={true}>
                                                                    {
                                                                        selectedFile.is_uploaded === false
                                                                        ?
                                                                        <TableRowIcons>
                                                                            {
                                                                                selectedFile.show_crop === true && selectedFile.is_allowed === true
                                                                                ?
                                                                                    <TableRowIcon icon={icons.crop} color={"dark"} action={() => cropThisFileHandler(selectedFile)} />
                                                                                :
                                                                                    <></>
                                                                            }
                                                                            <TableRowIcon icon={icons.eye} color={"dark"} action={ ()=> openFileInNewTab(selectedFile.file_path) } />
                                                                        </TableRowIcons>
                                                                        :
                                                                        <></>
                                                                    }
                                                                    </TableRowItem>
                                                                    <TableRowItem size={3} isCenter={false} hasChildren={true}>
                                                                        <TableRowActions>
                                                                            {
                                                                                selectedFile.is_allowed === true
                                                                                ?
                                                                                    <>
                                                                                        {
                                                                                            selectedFile.is_uploaded === false
                                                                                            ?
                                                                                                <>
                                                                                                    <TableButton icon={icons.upload} iconPosition={"left"} hasIcon={true} isLoading={selectedFile.is_uploading ? true : false} bg={"dashboard"} action={() => uploadThisFile(selectedFile.id)}>upload</TableButton>
                                                                                                    <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"error"} disabled={selectedFile.is_uploading ? true : false} action={() => deleteSelectedFileHandler(selectedFile.id)}>delete</TableButton>
                                                                                                </>
                                                                                            :
                                                                                            <TableButton clickable={false} icon={icons.tick} iconPosition={"left"} hasIcon={true} isLoading={false} bg={"success"} action={() => console.log(selectedFile.id)}>uploaded!</TableButton>
                                                                                        }
                                                                                    </>
                                                                                :
                                                                                    <>
                                                                                        {
                                                                                            selectedFile.is_file_type_error === true
                                                                                            ?
                                                                                                <TooltipWrapper>
                                                                                                    <TableErrorPlain lable='File type not supported!' action={showAvailableFileTypesTooltipHandler} />
                                                                                                    <TooltipContent 
                                                                                                        showContent={showAvailableFileTypesTooltip} 
                                                                                                        width="small" 
                                                                                                        position="topRight" 
                                                                                                        bg="lightBg" 
                                                                                                        border="curvedBorder" 
                                                                                                        shadow="defaultShadow"
                                                                                                        action={() => showAvailableFileTypesTooltipHandler(false)}
                                                                                                    >
                                                                                                        <TooltipContentText content={`Available file types comes here!`} />
                                                                                                    </TooltipContent>
                                                                                                </TooltipWrapper>
                                                                                                
                                                                                            :
                                                                                            selectedFile.is_file_size_error === true
                                                                                            ?
                                                                                                <TooltipWrapper>
                                                                                                    <TableErrorPlain lable='File size limit exceeded!' action={showFileSizeLimitExceededTooltipHandler} />
                                                                                                    <TooltipContent 
                                                                                                        showContent={showFileSizeLimitExceededTooltip} 
                                                                                                        width="small" 
                                                                                                        position="topRight" 
                                                                                                        bg="lightBg" 
                                                                                                        border="curvedBorder" 
                                                                                                        shadow="defaultShadow"
                                                                                                        action={() => showFileSizeLimitExceededTooltipHandler(false)}
                                                                                                    >
                                                                                                        <TooltipContentText content={`${selectedFile.document_type} should be below ${selectedFile.max_file_size} MB.`} />
                                                                                                    </TooltipContent>
                                                                                                </TooltipWrapper>
                                                                                                
                                                                                            :
                                                                                            <></>
                                                                                        }
                                                                                        <TableIconButton icon={icons.removePlain} iconPosition={"left"} bg={"error"} action={() => deleteSelectedFileHandler(selectedFile.id)} />
                                                                                    </> 
                                                                            }
                                                                        </TableRowActions>
                                                                    </TableRowItem>
                                                                </TableRow>
                                                            )
                                                        })
                                                    }
                                                </TableRows>
                                            :
                                                <></>
                                        }
                                    </Table>
                                </PlainContainer>
                            </PlainContainer>
                        :
                            <></>
                    }
                </Card>
            </PlainContainer>

            {
                showImageCropPopUp === true
                ?
                    <PopUp heading="Crop Image" hasActions={false} closePopUp={() => setShowImageCropPopUp(false)}>
                        <BasicCropPopUp fileToCrop={fileToCrop} fileData={fileToCropFileData} concludeCropPopUp={(e) => handleConcludeCrop(e)} />
                    </PopUp>
                :
                    <></>
            }
        </>
    )
}

export default SuperAdminAddFilesPage