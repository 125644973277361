import React from 'react'
import { motion } from 'framer-motion';

const containerVariant = {
    hidden: {
        transition: {
            staggerChildren: 0.1,
            staggerDirection: -1
        }
    },
    visible: {
        transition: {
            delay: 0.5,
            staggerChildren: 0.1,
            staggerDirection: 1
        }
    }
}

const TooltipList = ({ children }) => {
    return (
        <motion.div 
            className="cmsDashboardTooltipList" 
            variants={containerVariant} 
            initial="hidden" 
            animate="visible" 
            exit="hidden"
        >
            {children}
        </motion.div>
    )
}

export default TooltipList