import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Card from '../../../components/core/dashboard/Card';
import DetailsRow from '../../../components/core/containers/DetailsRow';
import DetailsItem from '../../../components/core/containers/DetailsItem';
import { defaults, ToastAlert, functions, moments, globals } from '../../../utils';
import { HostelServices, ProductServices } from '../../../services';
import PopUp from '../../../components/core/popup';
import NoDataContainer from '../../../components/core/containers/NoDataContainer';
import AddHostelWardenPopUp from '../../../components/dashboard/popups/WardenPage/AddHostelWardenPopUp';

const HostelDetailsPage = ({ user }) => {
    const navigate = useNavigate();
    const params = useParams();

    const [hostel, setHostel] = useState(null);

    const handleGetHostelRequest = async () => {
        const getHostelReq = await HostelServices.getThisAuthHostel({
            id: params.id,
            token: user.access_token
        });

        if(getHostelReq.status === false){
            ToastAlert.notifyError(getHostelReq.message);
            setHostel(null);
        }
        else{
            setHostel(getHostelReq.data);
        }
    }

    useEffect(() => {
        handleGetHostelRequest();
    }, []);

    const [showAddWardenPopUp, setShowAddWardenPopUp] = useState(false);

    const handleConcludeAddWardenPopUp = async (e) => {
        if(e.status === false){
            setShowAddWardenPopUp(false);
        }
        else{
            setShowAddWardenPopUp(false);
            handleGetHostelRequest();
        }
    }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Hostel Details'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to the Hostel in this page'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                <Card hasHeading={true} heading={"Hostel"} headingActionIcon={icons.addSolidCircle} headingAction={() => console.log("add")} isBottomAction={false}>
                    {
                        hostel?.seo === null
                        ?
                        <>
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <NoDataContainer
                                    icon={icons.sad}
                                    heading={"Basic Details"}
                                    description={"Update Hostel details by clicking on the below button."}
                                    hasSectionAction={false}
                                    sectionAction={() => alert("section add")}
                                    hasActions={true}
                                    hasPrimaryAction={true}
                                    primaryActionLable={"update"}
                                    primaryAction={() => console.log("hostel")}
                                    hasSecondaryAction={true}
                                    secondaryActionLable={"refresh"}
                                    secondaryAction={() => handleGetHostelRequest("refresh")}
                                />
                            </PlainContainer>
                        </>
                        :
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            {
                                hostel?.seo?.title != undefined && hostel?.seo?.title != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"district"} 
                                        description={defaults.capitalize(hostel?.seo?.title)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                hostel?.seo?.slug != undefined && hostel?.seo?.slug != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"taluk"} 
                                        description={defaults.capitalize(hostel?.seo?.slug)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                hostel?.seo?.description != undefined && hostel?.seo?.description != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"hostel"} 
                                        description={defaults.capitalize(hostel?.seo?.description)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                        </PlainContainer>
                    }
                </Card>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Warden"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddWardenPopUp(true)}  isBottomAction={false}>
                        {
                            hostel?.warden === null
                            ?
                            <>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No Sitemap Details found!"}
                                        description={"Update sitemap details by clicking on the below button."}
                                        hasSectionAction={false}
                                        sectionAction={() => alert("section add")}
                                        hasActions={true}
                                        hasPrimaryAction={true}
                                        primaryActionLable={"update"}
										hasSecondaryAction={true}
										secondaryActionLable={"refresh"}
                                        secondaryAction={() => handleGetHostelRequest("refresh")}
                                    />
                                </PlainContainer>
                            </>
                            :
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                {
                                    hostel?.sitemap?.page?.name != ""
                                    ?
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"name"} 
                                            description={defaults.capitalize(hostel?.sitemap?.page?.name)} 
                                        />
                                    </DetailsRow>
                                    :
                                    <></>
                                }
                                {
                                    hostel?.sitemap?.page?.name != ""
                                    ?
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"phone number"} 
                                            description={defaults.capitalize(hostel?.sitemap?.page?.name)} 
                                        />
                                    </DetailsRow>
                                    :
                                    <></>
                                }
                                {
                                    hostel?.sitemap?.page?.name != ""
                                    ?
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"email-id"} 
                                            description={defaults.capitalize(hostel?.sitemap?.page?.name)} 
                                        />
                                    </DetailsRow>
                                    :
                                    <></>
                                }
                                {/* {
                                    school?.sitemap?.image != undefined
                                    ?
                                    <DetailsRow>
                                        <DetailsItemWithPreview 
                                            lable={`Image`}
                                            preview={`${globals.API_BASE_URL}${school?.sitemap?.image?.image_url?.full?.high_res}`}
                                            previewType={"image"}
                                            fileName={school?.sitemap?.image?.name?.original}
                                            fileExtension={school?.sitemap?.image?.extension?.original}
                                            fileSize={school?.sitemap?.image?.size?.current}
                                        />
                                    </DetailsRow>
                                    :
                                    <></>
                                } */}
                            </PlainContainer>
                        }
                    </Card>
                </PlainContainer>
            </PlainContainer>

            {
                showAddWardenPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Add New Warden" closePopUp={() => handleConcludeAddWardenPopUp({status: false})}>
                    <AddHostelWardenPopUp user={user} Hostel_id={hostel} concludeAddWardenPopUp={(e) => handleConcludeAddWardenPopUp(e)} />
                </PopUp>
                :
                <></>
            }
        </>
    )
}

export default HostelDetailsPage