import React from 'react'
import { motion } from 'framer-motion'
import Card from '../../../../../components/core/dashboard/Card'
import { icons } from '../../../../../constants'
import { globals } from '../../../../../utils'

const FileCard = ({ file, action=()=>console.log('') }) => {
    return (
        <motion.div className="fileManagerContentSectionListSingle" whileHover={{ scale: 1.05 }} onClick={action}>
            <Card hasHeading={true} heading={``} isHeadingAction={true} headingActionIcon={icons.warningCircle}>
                <div className="fileManagerContentSectionListSingleFileType">
                    {
                        file.media_type.name === "image"
                        ?
                        <div className="fileManagerContentSectionListSingleFileTypeImage">
                            <div className="fileManagerContentSectionListSingleFileTypeImageInner">
                                <div className="fileManagerContentSectionListSingleFileTypeImageContainer" style={{backgroundImage: `url(${globals.API_BASE_URL}${file.image_url.thumbnail.low_res})`}}>&nbsp;</div>
                            </div>
                        </div>
                        :
                        <div className="fileManagerContentSectionListSingleFileTypeIcon">
                            <i className={`${icons.filePDF} fileManagerContentSectionListSingleFileTypeIco`}></i>
                        </div>
                    }
                </div>
                <div className="fileManagerContentSectionListSingleFileDetails">
                    <div className="fileManagerContentSectionListSingleFileName">
                        <p className="fileManagerContentSectionListSingleFileNameTxt">{file.name.current}</p>
                    </div>
                </div>
            </Card>
        </motion.div>
    )
}

export default FileCard