import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import DashboardHeaderSearchBar from '../../../components/core/form/DashboardHeaderSearchBar';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import Card from '../../../components/core/dashboard/Card';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableCheckbox from '../../../components/core/table/inputs/Checkbox';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../components/core/table/inputs/Button';
// import DistrictsListFilters from './DistrictsListFilters';
import TalukServices from '../../../services/Location/Taluk';
import { ToastAlert } from '../../../utils';
import Pagination from '../../../components/core/table/pagination';
import DistrictServices from '../../../services/Location/District';
import AdminServices from '../../../services/Admin';

const TaluksListPage = ({ user }) => {
    const navigate = useNavigate();

    const [taluks, setTaluks] = useState([]);
    const [totalTaluksCount, setTotalTaluksCount] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [createdOn, setCreatedOn] = useState("RECENT");
    const [district, setDistrict]=useState("ALL");
    const [createdBy,setCreatedBy]=useState("ALL");

    const [districts, setDistricts]=useState([]);

    const handleGetDistrictsReq = async () => {
        const getDistrictsReq = await DistrictServices.getDropdownDistricts({
            token: user.access_token
        });
      

        if(getDistrictsReq.status === false){
            ToastAlert.notifyError(getDistrictsReq.message);
            setDistricts([]);
        }
        else{
            setDistricts(getDistrictsReq.data);
        }
    }

    useEffect(()=>{
        handleGetDistrictsReq()
    },[])

    const handleGetTaluksRequest = async () => {
        const getTaluksReq = await TalukServices.getAllTaluks({
            token: user.access_token,
            current_page: currentPage,
            created_on: createdOn,
            created_by:createdBy,
            district: district
        });

        if(getTaluksReq.status === false){
            ToastAlert.notifyError(getTaluksReq.message);
            setTaluks([]);
            setTotalTaluksCount(0);
            setTotalPagesCount(0);
            setCurrentPage(1);
        }
        else{
            setTaluks(getTaluksReq.data.items);
            setTotalTaluksCount(getTaluksReq.data.total_items);
            setTotalPagesCount(getTaluksReq.data.total_pages);
            setCurrentPage(getTaluksReq.data?.current_page === undefined ? 1 : getTaluksReq.data.current_page);
        }
    }

    const handleDeleteTalukRequest = async (id) => {

        const deleteTalukReq = await TalukServices.deleteTaluk({
            token: user.access_token,
            id: id
        });

        if(deleteTalukReq.status === false){
            ToastAlert.notifyError(deleteTalukReq.message);
            setTaluks([]);
            setTotalTaluksCount(0);
            setTotalPagesCount(0);
            setCurrentPage(1);
        }
        else{
            ToastAlert.notifySuccess(deleteTalukReq.message);
            handleGetTaluksRequest();
        }
    }

    useEffect(() => {
        handleGetTaluksRequest();
    }, [currentPage, district, createdBy, createdOn]);


    // const [users, setUsers] = useState([]);

    // const handleGetUsersRequest = async () => {
    //     const getUsersReq = await AdminServices.getAllAuthAdminsSuperAdmin({
    //         token: user.access_token,
    //         created_on: "OLD",
    //         roles: "SUPER_ADMIN,ADMIN"
    //     });

    //     if(getUsersReq.status === false){
    //         ToastAlert.notifyError(getUsersReq.message);
    //         setUsers([]);
    //     }
    //     else{
    //         if(getUsersReq.data.length > 0){
    //             setUsers(getUsersReq.data);
    //         }
    //         else{
    //             setUsers([]);
    //         }
    //     }
    // }

    // useEffect(() => {
    //     handleGetUsersRequest();
    // }, []);


    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Taluks'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='List of All Taluks in India.'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions>
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        {/* <PlainContainer type="full" styles={{paddingTop: "15px", paddingRight: "25px", paddingBottom: "25px", paddingLeft: "25px"}}>
                            <DistrictsListFilters
                            users={users}
                             states={states} 
                             state={state}
                             StateTypeChanged={setState}
                             createdOn={createdOn}
                             createdOnChanged={setCreatedOn} 
                             createdBy={createdBy}
                             createdByChanged={setCreatedOn}
                            />
                        </PlainContainer> */}
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    {/* <TableHeadingItem size={0} hasChildren={true}>
                                        <TableCheckbox name={"items-selected-ckb"} />
                                    </TableHeadingItem> */}
                                    <TableHeadingItem size={2} lable={"name"} />
                                    <TableHeadingItem size={1} lable={"district"} />
                                    <TableHeadingItem size={1} lable={"created by"} />
                                    {/* <TableHeadingItem size={1} lable={"no. of cities"} /> */}
                                    {/* <TableHeadingItem size={0} lable={""} /> */}
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>
                                <TableRows>
                                    {
                                        taluks.map((taluk, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    {/* <TableRowItem size={0} hasChildren={true} isCenter={false}>
                                                        <TableCheckbox name={`items-selected-ckb-${1}`} />
                                                    </TableRowItem> */}
                                                    <TableRowItem size={2} isCenter={false} hasPreview={false} lable={taluk?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={taluk?.district?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={`${taluk?.created_by.first_name} (${taluk?.created_by?.role === "SUPER_ADMIN" ? "Super Admin" : "Admin" })`} />
                                                    {/* <TableRowItem size={1} isCenter={false} lable={district?.cities} /> */}
                                                    {/* <TableRowItem size={0} isCenter={true} hasChildren={true}>
                                                        <TableRowIcons>
                                                            <TableRowIcon icon={icons.eye} color={"dark"} action={() => navigate(`${taluk.id}`)} />
                                                        </TableRowIcons>
                                                    </TableRowItem> */}
                                                    <TableRowItem size={2} isCenter={false} hasChildren={true}>
                                                    <TableRowActions>
                                                            <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => navigate(`edit/${taluk.id}`) }>edit</TableButton>
                                                            {
                                                                user?.role === "SUPER_ADMIN" ?
                                                                <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true}  bg={"warning"} action={() => handleDeleteTalukRequest(district.id)}>delete</TableButton> 
                                                                :
                                                                <TableButton icon={icons.removePlain} iconPosition={"left"} disabled={true} hasIcon={true}  bg={"warning"} action={() => handleDeleteTalukRequest(district.id)}>delete</TableButton> 
                                                            }
                                                             
                                                    </TableRowActions>
                                                    </TableRowItem>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                        {
                            totalPagesCount > 1
                            ?
                                <PlainContainer type='full' styles={{ padding: "0px 25px" }}>
                                    <Pagination totalPages={totalPagesCount} setCurrentPage={setCurrentPage}/>
                                </PlainContainer>
                            :
                                <></>
                        }
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default TaluksListPage