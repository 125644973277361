import React from 'react'
import { icons } from '../../../constants'

const DetailsItem = ({ hasIcon, icon, lable, description, language="en", isEditable=false, editIcon=icons.pencilSolid, editAction=()=>console.log() }) => {
    return (
        <div className="sectionFieldItem">
            {
                hasIcon === true
                ?
                <div className="sectionFieldItemIconContainer">
                    <div className="sectionFieldItemIconInner">
                        <i className={`sectionFieldItemIco ${icon}`}></i>
                    </div>
                </div>
                :
                <></>
            }
            
            <div className={`sectionFieldItemLableContent ${hasIcon === true ? 'hasIcon' : ""} ${isEditable === true ? 'isEditable' : ""}`}>
                <div className="sectionFieldItemLable">
                    <p className="sectionFieldItemLableTxt">{lable}</p>
                </div>
                <div className="sectionFieldItemContent">
                    <p className={`sectionFieldItemContentTxt ${language === "kn" ? "kn" : ""}`}>{description}</p>
                </div>
            </div>

            {
                isEditable === true
                ?
                <div className="sectionFieldItemEditContainer">
                    <div className="sectionFieldItemEditIcon" onClick={editAction}>
                        <i className={`sectionFieldItemEditIco ${editIcon}`}></i>
                    </div>
                </div>
                :
                <></>
            }
        </div>
    )
}

export default DetailsItem