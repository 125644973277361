import React from 'react'
import { motion } from 'framer-motion';
import SidebarFileVisual from './Visual';
import SidebarFileDetails from './Details';
import SidebarFileDetailStep from './Details/Step';
import Button from '../../../../../components/core/dashboard/Button';
import { icons } from '../../../../../constants';
import { functions, moments } from '../../../../../utils';

const containerVariant = {
    hidden: {
        transition: {
            staggerChildren: 0.1,
            staggerDirection: -1
        }
    },
    visible: {
        transition: {
            delay: 0.5,
            staggerChildren: 0.1,
            staggerDirection: 1
        }
    }
}

const childVariant = {
    hidden: {
        opacity: 0,
        y: -10
    },
    visible: {
        opacity: 1,
        y: 0,
    }
}

const FileManagerSidebar = ({ type="folder", ext="", details={}, closeSidebar }) => {
    return (
        <motion.div 
            className="fileManagerSidebarInner"
            variants={containerVariant} 
            initial="hidden" 
            animate="visible" 
            exit="hidden"
        >
            <SidebarFileVisual type={type} ext={ext} details={details} closeSidebar={closeSidebar} variants={childVariant} />

            <SidebarFileDetails filename={type === "folder" ? details.name : details?.name?.current} type={type} variants={containerVariant}>
                <SidebarFileDetailStep lable="created by" value={type === "folder" ? details?.created_by?.first_name : details?.uploaded_by?.first_name} variants={childVariant} />
                <SidebarFileDetailStep lable="created on" value={moments.getBasicDateFormat(details?.createdAt)} variants={childVariant} />
                <SidebarFileDetailStep lable="original name" value={type === "folder" ? details.name : details?.name?.original} lowercase={false} variants={childVariant} />
                {
                    type === "folder" 
                    ? 
                    <SidebarFileDetailStep lable="no. of files" value={type === "folder" ? details.no_of_files : ""} lowercase={true} variants={childVariant} />
                    :
                    <></>
                }
                <SidebarFileDetailStep lable="original size" value={type === "folder" ? functions.getReadableFileSize(details.uploaded_files_size) : functions.getReadableFileSize(details?.size?.original)} variants={childVariant} />
                <SidebarFileDetailStep lable="optimize size" value={type === "folder" ? functions.getReadableFileSize(details.optimized_files_size) : functions.getReadableFileSize(details?.size?.current)} variants={childVariant} />
                {
                    type === "folder"
                    ?
                    <SidebarFileDetailStep lable="resource saved" value={details.no_of_files === 0 ? `0%` : `${Math.round(functions.getResourceSavedPercentage(details.optimized_files_size, details.uploaded_files_size))}%` } variants={childVariant} />
                    :
                    <SidebarFileDetailStep lable="resource saved" value={`${Math.round(functions.getResourceSavedPercentage(details?.size?.current, details?.size?.original))}%`} variants={childVariant} />
                }
            </SidebarFileDetails>

            <motion.div className="fileManagerSidebarDetailsActions" variants={childVariant}>
                <div className="fileManagerSidebarDetailsActionSingle">
                    <Button 
                        type="button"
                        bgType="fill"
                        width="full"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.dowload}
                    >
                        download
                    </Button>
                </div>
                <div className="fileManagerSidebarDetailsActionSingle">
                    <Button 
                        type="button"
                        bgType="fill"
                        width="full"
                        bg="error"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.removePlainSolid}
                    >
                        delete
                    </Button>
                </div>
            </motion.div>
        </motion.div>
    )
}

export default FileManagerSidebar