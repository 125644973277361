import axios from "axios";
import { statusCodes, defaults } from "../../../utils";

const config = (token) => {
    return (
        {
            headers: {
                'Authorization': token
            }
        }
    )
}

const saveDistrict = async (payload) => {
    if(!payload.en_name){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "English name is required.",
        });

        return response
    }

    if(!payload.kn_name){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Kannada name is required.",
        });

        return response
    }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    const data = {
        en_name: payload.en_name.toLowerCase().trimStart().trimEnd(),
        kn_name: payload.kn_name
    }

    try{
        let headers = await config(payload.token);

        const addNewDistrictReq = await axios.post(`${defaults.API_BASE_URL}district`, data, headers);
        if(addNewDistrictReq.status === statusCodes.CREATED){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.CREATED,
                message: "District successfully created.",
                data: addNewDistrictReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}

const updateDistrict = async (payload) => {
    if(!payload.id){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "District ID is required.",
        });

        return response
    }

    if(!payload.en_name){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "English name is required.",
        });

        return response
    }

    if(!payload.kn_name){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Kannada name is required.",
        });

        return response
    }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    const data = {
        en_name: payload.en_name.toLowerCase().trimStart().trimEnd(),
        kn_name: payload.kn_name
    }

    try{
        let headers = await config(payload.token);

        const updateDistrictReq = await axios.patch(`${defaults.API_BASE_URL}district/${payload.id}`, data, headers);
        if(updateDistrictReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "District successfully updated.",
                data: updateDistrictReq?.data?.data
            });
    
            return response
        }
        else if(updateDistrictReq.status === statusCodes.NOT_FOUND){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.NOT_FOUND,
                message: "Invalid request. The District that you are trying update is not found."
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}

const getAllDistricts = async (payload) => {

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    try{
        let headers = await config(payload.token);

        const getAllDistrictsReq = await axios.get(`${defaults.API_BASE_URL}district/?page=${payload.current_page}&size=10&created_on=${payload?.created_on === undefined ? "RECENT" : payload?.created_on}&created_by=${payload?.created_by === undefined ? null : payload?.created_by}`, headers);
        if(getAllDistrictsReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "District successfully recieved.",
                data: getAllDistrictsReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}

const getDropdownDistricts = async (payload) => {
    try{
        let headers = await config(payload.token);

        const getPublicDistrictsReq = await axios.get(`${defaults.API_BASE_URL}district/public/all`, headers);
        if(getPublicDistrictsReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "District successfully recieved.",
                data: getPublicDistrictsReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.BAD_REQUEST,
            message: "Request failed due to expected technical error."
        });

        return response
    }
}

const getPublicDistricts = async () => {
    try{
        const getPublicDistrictsReq = await axios.get(`${defaults.API_BASE_URL}district/public`);
        if(getPublicDistrictsReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "District successfully recieved.",
                data: getPublicDistrictsReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.BAD_REQUEST,
            message: "Request failed due to expected technical error."
        });

        return response
    }
}

const getThisAuthDistrict = async (payload) => {

    if(!payload.id){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "District ID is required.",
        });

        return response
    }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Tokenn is required.",
        });

        return response
    }
    try{
        let headers = await config(payload.token);

        const getAuthDistrictReq = await axios.get(`${defaults.API_BASE_URL}district/auth/${payload.id}`, headers);
        if(getAuthDistrictReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "District successfully recieved.",
                data: getAuthDistrictReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.BAD_REQUEST,
            message: "Request failed due to expected technical error."
        });

        return response
    }
}

const deleteDistrict = async (payload) => {
    if(!payload.id){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "District ID is required.",
        });

        return response
    }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    try{
        let headers = await config(payload.token);

        const updateDistrictReq = await axios.delete(`${defaults.API_BASE_URL}district/${payload.id}`, headers);
        if(updateDistrictReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "District successfully deleted.",
                data: updateDistrictReq?.data?.data
            });
    
            return response
        }
        else if(updateDistrictReq.status === statusCodes.NOT_FOUND){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.NOT_FOUND,
                message: "Invalid request. The District that you are trying delete is not found."
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}
const saveDirector = async (payload) => {
  

    if(!payload.director){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "director name is required.",
        });

        return response
    }
    if(!payload.director_id){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "director id is required.",
        });

        return response
    }
    if(!payload.district_id){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "district id is required.",
        });

        return response
    }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    const data = {
        director: payload.director,
    }

    try{
        let headers = await config(payload.token);

        const addNewDirectorReq = await axios.post(`${defaults.API_BASE_URL}district/assign-director/${payload.district_id}/${payload.director_id}`, data, headers);
        if(addNewDirectorReq.status === statusCodes.CREATED){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.CREATED,
                message: "director successfully created.",
                data: addNewDirectorReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}
const updateDirectorToDistrict = async (payload) => {

    if(!payload.district_id){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Organization ID is required.",
        });

        return response
    }

    if(!payload.director_id){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Warden ID is required.",
        });

        return response
    }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    const data = {}

    try{
        let headers = await config(payload.token);

        const updateDirectorToDistrictReq = await axios.patch(`${defaults.API_BASE_URL}district/assign-director/${payload.district_id}/${payload.director_id}`, data, headers);
        if(updateDirectorToDistrictReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Warden Successfully Assigned to The Organization!"
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}

const DistrictServices = {
    saveDistrict,
    updateDistrict,
    getAllDistricts,
    getDropdownDistricts,
    getPublicDistricts,
    getThisAuthDistrict,
    deleteDistrict,
    saveDirector,
    updateDirectorToDistrict
}

export default DistrictServices