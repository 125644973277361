import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DefaultHeader from '../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import DashboardHeaderSearchBar from '../../components/core/form/DashboardHeaderSearchBar';
import Button from '../../components/core/dashboard/Button';
import { icons } from '../../constants';
import PlainContainer from '../../components/core/containers/PlainContainer';
import Card from '../../components/core/dashboard/Card';
import Table from '../../components/core/table/containers/Table';
import TableHeading from '../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../components/core/table/containers/TableHeadingItem';
import TableCheckbox from '../../components/core/table/inputs/Checkbox';
import TableRows from '../../components/core/table/containers/TableRows';
import TableRow from '../../components/core/table/containers/TableRow';
import TableRowItem from '../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../components/core/table/containers/TableRowActions';
import TableButton from '../../components/core/table/inputs/Button';
// import DistrictsListFilters from './DistrictsListFilters';
import TalukServices from '../../services/Location/Taluk';
import { ToastAlert } from '../../utils';
import Pagination from '../../components/core/table/pagination';
import DistrictServices from '../../services/Location/District';
import AdminServices from '../../services/Admin';
import { HostelServices, SchoolServices } from '../../services';

const HostelsListPage = ({ user }) => {
    const navigate = useNavigate();

    const [hostels, setHostels] = useState([]);
    const [totalHostelsCount, setTotalHostelsCount] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [createdOn, setCreatedOn] = useState("RECENT");
   
    const [createdBy,setCreatedBy]=useState("ALL");

    const [districts, setDistricts]=useState([]);
    const [taluks, setTaluks] = useState([]);

    const handleGetDistrictsReq = async () => {
        const getDistrictsReq = await DistrictServices.getDropdownDistricts({
            token: user.access_token
        });
      

        if(getDistrictsReq.status === false){
            ToastAlert.notifyError(getDistrictsReq.message);
            setDistricts([]);
        }
        else{
            setDistricts(getDistrictsReq.data);
        }
    }

    useEffect(()=>{
        handleGetDistrictsReq()
    },[])

    const handleGetTaluksReq = async () => {
        const getTaluksReq = await TalukServices.getDropdownTaluks({
            token: user.access_token
        })
      

        if(getTaluksReq.status === false){
            ToastAlert.notifyError(getTaluksReq.message);
            setTaluks([]);
        }
        else{
            setTaluks(getTaluksReq.data);
        }
    }

    useEffect(()=>{
        handleGetTaluksReq()
    },[])

    const handleGetHostelsRequest = async () => {
        const getHostelsReq = await HostelServices.getAllHostels({
            token: user.access_token,
            current_page: currentPage,
            created_on: createdOn,
            created_by:createdBy,
        });

        if(getHostelsReq.status === false){
            ToastAlert.notifyError(getHostelsReq.message);
            setHostels([]);
            setTotalHostelsCount(0);
            setTotalPagesCount(0);
            setCurrentPage(1);
        }
        else{
            setHostels(getHostelsReq.data.items);
            setTotalHostelsCount(getHostelsReq.data.total_items);
            setTotalPagesCount(getHostelsReq.data.total_pages);
            setCurrentPage(getHostelsReq.data?.current_page === undefined ? 1 : getHostelsReq.data.current_page);
        }
    }

    // const handleDeleteTalukRequest = async (id) => {

    //     const deleteTalukReq = await TalukServices.deleteTaluk({
    //         token: user.access_token,
    //         id: id
    //     });

    //     if(deleteTalukReq.status === false){
    //         ToastAlert.notifyError(deleteTalukReq.message);
    //         setTaluks([]);
    //         setTotalTaluksCount(0);
    //         setTotalPagesCount(0);
    //         setCurrentPage(1);
    //     }
    //     else{
    //         ToastAlert.notifySuccess(deleteTalukReq.message);
    //         handleGetTaluksRequest();
    //     }
    // }

    useEffect(() => {
        handleGetHostelsRequest();
    }, [currentPage, districts, createdBy, createdOn]);


    const [users, setUsers] = useState([]);

    const handleGetUsersRequest = async () => {
        const getUsersReq = await AdminServices.getAllAuthAdminsSuperAdmin({
            token: user.access_token,
            created_on: "OLD",
            roles: "SUPER_ADMIN,ADMIN"
        });

        if(getUsersReq.status === false){
            ToastAlert.notifyError(getUsersReq.message);
            setUsers([]);
        }
        else{
            if(getUsersReq.data.length > 0){
                setUsers(getUsersReq.data);
            }
            else{
                setUsers([]);
            }
        }
    }

    useEffect(() => {
        handleGetUsersRequest();
    }, []);

    const hostelss = [
        {
            id: "1",
            name: "ABC High School",
            district: "Hassan",
            taluk: "Taluk A",
            created_by: "John (Super Admin)",
        },
        {
            id: "2",
            name: "XYZ Public School",
            district: "Mysore",
            taluk: "Taluk B",
            created_by: "Jane (Admin)",
        },
        {
            id: "3",
            name: "LMN International",
            district: "Bangalore",
            taluk: "Taluk C",
            created_by: "Alex (Super Admin)",
        },
        {
            id: "4",
            name: "PQR Academy",
            district: "Tumkur",
            taluk: "Taluk D",
            created_by: "Emily (Admin)",
        },
    ];
    


    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Hostels'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='List of All Hostels in Karnataka.'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions>
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        {/* <PlainContainer type="full" styles={{paddingTop: "15px", paddingRight: "25px", paddingBottom: "25px", paddingLeft: "25px"}}>
                            <DistrictsListFilters
                            users={users}
                             states={states} 
                             state={state}
                             StateTypeChanged={setState}
                             createdOn={createdOn}
                             createdOnChanged={setCreatedOn} 
                             createdBy={createdBy}
                             createdByChanged={setCreatedOn}
                            />
                        </PlainContainer> */}
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    {/* <TableHeadingItem size={0} hasChildren={true}>
                                        <TableCheckbox name={"items-selected-ckb"} />
                                    </TableHeadingItem> */}
                                    <TableHeadingItem size={2} lable={"name"} />
                                    <TableHeadingItem size={1} lable={"district"} />
                                    <TableHeadingItem size={1} lable={"taluk"} />
                                    <TableHeadingItem size={1} lable={"created by"} />
                                    {/* <TableHeadingItem size={1} lable={"no. of cities"} /> */}
                                    <TableHeadingItem size={0} lable={""} />
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>
                                <TableRows>
                                    {
                                        hostelss.map((hostel, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    {/* <TableRowItem size={0} hasChildren={true} isCenter={false}>
                                                        <TableCheckbox name={`items-selected-ckb-${1}`} />
                                                    </TableRowItem> */}
                                                    <TableRowItem size={2} isCenter={false} hasPreview={true} lable={hostel?.name} />
                                                    <TableRowItem size={1} isCenter={false} lable={hostel?.district} />
                                                    <TableRowItem size={1} isCenter={false} lable={hostel?.taluk} />
                                                    <TableRowItem size={1} isCenter={false} lable={hostel?.created_by} />
                                                    {/* <TableRowItem size={1} isCenter={false} lable={district?.cities} /> */}
                                                    <TableRowItem size={0} isCenter={true} hasChildren={true}>
                                                        <TableRowIcons>
                                                            <TableRowIcon icon={icons.eye} color={"dark"} action={() => navigate(`${hostel.id}`)} />
                                                        </TableRowIcons>
                                                    </TableRowItem>
                                                    <TableRowItem size={2} isCenter={false} hasChildren={true}>
                                                        <TableRowActions>
                                                            <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => navigate(`edit/${hostel.id}`)}>edit</TableButton>
                                                            <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => console.log(hostel.id)}>delete</TableButton>
                                                        </TableRowActions>
                                                    </TableRowItem>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                        {
                            totalPagesCount > 1
                            ?
                                <PlainContainer type='full' styles={{ padding: "0px 25px" }}>
                                    <Pagination totalPages={totalPagesCount} setCurrentPage={setCurrentPage}/>
                                </PlainContainer>
                            :
                                <></>
                        }
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default HostelsListPage