import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import DefaultHeader from '../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import DashboardHeaderSearchBar from '../../../../components/core/form/DashboardHeaderSearchBar';
import TooltipWrapper from '../../../../components/core/tooltip';
import Button from '../../../../components/core/dashboard/Button';
import TooltipContent from '../../../../components/core/tooltip/content';
import TooltipList from '../../../../components/core/tooltip/list';
import TooltipListItem from '../../../../components/core/tooltip/list/item';
import { icons } from '../../../../constants';
import PlainContainer from '../../../../components/core/containers/PlainContainer';
import Card from '../../../../components/core/dashboard/Card';
import FileManagerFilters from './FileManagerFilters';
import FolderFileAndSidebar from './FolderFilesAndSidebar';
import FoldersAndFiles from './FoldersAndFiles';
import FolderSection from './FoldersSection';
import FolderCard from './FolderCard';
import FileSection from './FilesSection';
import FileCard from './FileCard';
import FileManagerSidebar from './Sidebar';
import Backdrop from '../../../../components/core/containers/Backdrop';
import PopUp from '../../../../components/core/popup';
import TextInputRT from '../../../../components/core/form/TextInputRT';
import CreateNewFolderPopUp from '../../../../components/dashboard/popups/CreateNewFolderPopUp';
import { FolderServices } from '../../../../services';
import { ToastAlert } from '../../../../utils';
import FileServices from '../../../../services/Media/File';
import Pagination from '../../../../components/core/table/pagination';

const SuperAdminFileManagerPage = ({ user }) => {
    const navigate = useNavigate();
    
    const [search, setSearch] = useState("");
    const [showSearchAction, setShowSearchAction] = useState(false);

    const [showCreateOptions, setShowCreateOptions] = useState(false);

    const [showCreateNewFolderPopUp, setShowCreateNewFolderPopUp] = useState(false);

    useEffect(() => {
        if(search.length > 0){
            setShowSearchAction(true);
        }
        else{
            setShowSearchAction(false);
        }
    }, [search]);

    const [showSidebar, setShowSidebar] = useState(false);

    const parentVariant = {
        hidden:{ opacity: 0, x: '100%' },
        visible: {
            opacity: 1, 
            x: '0'
        },
        exit: {
            opacity: 0, 
            x: '100%',
            transition: { delay: 0.3, duration: 0.3 }
        }
    }

    const handleShowCreateNewFolder = () => {
        setShowCreateOptions(false);
        setShowCreateNewFolderPopUp(true);
    }

    const handleConcludeNewFolderPopUp = (e) => {
        if(e.status === true){
            console.log("created new folder");
            setShowCreateNewFolderPopUp(false);
            // handleGetFoldersRequest();
        }
        else if(e.status === false){
            setShowCreateNewFolderPopUp(false);
        }
        else{
            console.log("invalid status");
        }
    }

    const [folders, setFolders] = useState([]);
    const [totalFoldersCount, setTotalFoldersCount] = useState(0);
    const [totalFolderPagesCount, setTotalFolderPagesCount] = useState(0);
    const [currentFolderPage, setCurrentFolderPage] = useState(1);

    // const handleGetFoldersRequest = async () => {
    //     const getFoldersReq = await FolderServices.getAllFolders({
    //         token: user.access_token,
    //         current_page: currentFolderPage
    //     });

    //     console.log(getFoldersReq);

    //     if(getFoldersReq.status === false){
    //         ToastAlert.notifyError(getFoldersReq.message);
    //         setFolders([]);
    //         setTotalFoldersCount(0);
    //         setTotalFolderPagesCount(0);
    //         setCurrentFolderPage(1);
    //     }
    //     else{
    //         if(getFoldersReq.data.items.length > 0){
    //             setFolders(getFoldersReq.data.items);
    //             setTotalFoldersCount(getFoldersReq.data.total_items);
    //             setTotalFolderPagesCount(getFoldersReq.data.total_pages);
    //             setCurrentFolderPage(getFoldersReq.data.current_page);
    //         }
    //         else{
    //             setFolders([]);
    //             setTotalFoldersCount(0);
    //             setTotalFolderPagesCount(0);
    //             setCurrentFolderPage(1);
    //         }
    //     }
    // }

    // useEffect(() => {
    //     handleGetFoldersRequest();
    // }, [currentFolderPage]);

    const [files, setFiles] = useState([]);
    const [totalFilesCount, setTotalFilesCount] = useState(0);
    const [totalFilePagesCount, setTotalFilePagesCount] = useState(0);
    const [currentFilePage, setCurrentFilePage] = useState(1);

    const [createdOnFilter, setCreatedOnFilter] = useState("RECENT");
    const [createdByFilter, setCreatedByFilter] = useState(0);
    const [mediaTypesFilter, setMediaTypesFilter] = useState("ALL");
    const [belongsToFolderFilter, setBelongsToFolderFilter] = useState(false);

    const handleGetFilesRequest = async () => {
        const getFilesReq = await FileServices.getAllAuthFiles({
            token: user.access_token,
            current_page: currentFilePage,
            belongs_to_folder: belongsToFolderFilter,
            media_types: mediaTypesFilter,
            created_on: createdOnFilter,
            created_by: createdByFilter
        });

        if(getFilesReq.status === false){
            ToastAlert.notifyError(getFilesReq.message);
            setFiles([]);
            setTotalFilesCount(0);
            setTotalFilePagesCount(0);
            setCurrentFilePage(1);
        }
        else{
            if(getFilesReq.data.items.length > 0){
                setFiles(getFilesReq.data.items);
                setTotalFilesCount(getFilesReq.data.total_items);
                setTotalFilePagesCount(getFilesReq.data.total_pages);
                setCurrentFilePage(getFilesReq.data.current_page);
            }
            else{
                setFiles([]);
                setTotalFilesCount(0);
                setTotalFilePagesCount(0);
                setCurrentFilePage(1);
            }
        }
    }

    useEffect(() => {
        handleGetFilesRequest();
    }, [currentFilePage]);

    const [sideBarData, setSideBarData] = useState(null);
    const [sideBarFileExtension, setSideBarFileExtension] = useState(null);
    const [isSelectedSideBarFolder, setIsSelectedSideBarFolder] = useState(false);

    const handleCloseSideBar = () => {
        setSideBarData(null);
        setIsSelectedSideBarFolder(false);
        setShowSidebar(false);
        setSideBarFileExtension(null);
    }

    const handleShowFolderDetails = (folder) => {
        setSideBarData(folder);
        setIsSelectedSideBarFolder(true);
        setShowSidebar(true)
    }

    const handleShowFileDetails = (file) => {
        console.log(file);
        setSideBarFileExtension(file.extension.current);
        setSideBarData(file);
        setIsSelectedSideBarFolder(false);
        setShowSidebar(true);
    }

    return(
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='file manager'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='20 folders, 128k files.'
                />
                <HeaderActions>
                    <DashboardHeaderSearchBar showAction={showSearchAction} action={''}>
                        <input type="text" className="cmsDashboardHeaderSearchBarInputTxt" placeholder="Search File / Folder" onChange={(e) => setSearch(e.target.value)} value={search} />
                    </DashboardHeaderSearchBar>

                    <TooltipWrapper>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg="dashboard"
                            borderRadius="full"
                            hasIcon={true}
                            iconPosition="left"
                            icon={icons.add}
                            action={()=> setShowCreateOptions(showCreateOptions ? false : true)}
                        >
                            Add New
                        </Button>
                        
                        <TooltipContent 
                            showContent={showCreateOptions} 
                            width="small" 
                            position="bottomRight" 
                            bg="lightBg" 
                            border="curvedBorder" 
                            shadow="defaultShadow"
                            action={()=> setShowCreateOptions(showCreateOptions ? false : true)}
                        >
                            <TooltipList>
                                <TooltipListItem hasIcon={true} icon={icons.folderOpen} lable="Create Folder" action={() => handleShowCreateNewFolder()} />
                                <TooltipListItem hasIcon={true} icon={icons.fileBasic} lable="Upload Files" action={() => navigate('upload')} />
                            </TooltipList>
                        </TooltipContent>
                    </TooltipWrapper>
                    
                </HeaderActions>
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>

                <Card hasHeading={false} isBottomAction={false}>

                    <PlainContainer type="full" styles={{paddingTop: "15px", paddingRight: "25px", paddingBottom: "25px", paddingLeft: "25px"}}>
                        
                        <FileManagerFilters />
                        
                        <FolderFileAndSidebar>
                            <FoldersAndFiles showSidebar={showSidebar}>
                                {/* <FolderSection>
                                    {
                                        folders.map((folder, i) => {
                                            return (
                                                <FolderCard key={i} folder={folder} action={() => handleShowFolderDetails(folder)} />
                                            )
                                        })
                                    }
                                </FolderSection> */}

                                <FileSection>
                                    {
                                        files.map((file, i) => {
                                            return (
                                                <FileCard key={i} file={file} action={() => handleShowFileDetails(file)} />
                                            )
                                        })
                                    }

                                    {
                                        totalFilePagesCount > 1
                                        ?
                                            <PlainContainer type='full' styles={{ padding: "0px 25px" }}>
                                                <Pagination totalPages={totalFilePagesCount} setCurrentPage={setCurrentFilePage} align={"center"} />
                                            </PlainContainer>
                                        :
                                            <></>
                                    }
                                </FileSection>
                                
                            </FoldersAndFiles>
                                
                            {
                                <AnimatePresence>
                                    {showSidebar && (
                                        <motion.div 
                                            className="fileManagerSidebar"
                                            variants={parentVariant}
                                            initial="hidden"
                                            animate="visible"
                                            exit="exit"
                                        >
                                            <FileManagerSidebar details={sideBarData} ext={sideBarFileExtension} type={isSelectedSideBarFolder ? "folder": "file"} closeSidebar={() => handleCloseSideBar()} />
                                        </motion.div>
                                        
                                    )}
                                </AnimatePresence>
                            }
                        </FolderFileAndSidebar>

                    </PlainContainer>

                </Card>

            </PlainContainer>

            {
                showCreateNewFolderPopUp === true
                ?
                <PopUp width="min" heading="Create New Folder" closePopUp={() => handleConcludeNewFolderPopUp({status: false})}>
                    <CreateNewFolderPopUp user={user} concludeNewFolderPopUp={(e) => handleConcludeNewFolderPopUp(e)} />
                </PopUp>
                :
                <></>
            }
            
        </>
    )
}

export default SuperAdminFileManagerPage