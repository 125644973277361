import React from 'react'
import { icons } from '../../../constants'
import SelectInput from '../../../components/core/form/SelectInput'
import Button from '../../../components/core/form/Button'

const DDEntityListFilters = ({  taluks, taluk, TalukTypeChanged, createdOn, createdOnChanged })  => {
        
    return (
        <div className="fileManagerFiltersContainer">
            <div className="fileManagerFilters">
                {/* <div className="fileManagerFiltersLayout">
                    <div className="fileManagerFiltersLayoutIcon">
                        <i className={`${icons.list} fileManagerFiltersLayoutIco`}></i>
                    </div>
                    <div className="fileManagerFiltersLayoutIcon">
                        <i className={`${icons.grid} fileManagerFiltersLayoutIco active`}></i>
                    </div>
                </div> */}

                <div className="fileManagerFiltersMain">
                    {/* <div className="fileManagerFilterSingle">
                        <SelectInput hasLable={false}>
                            <select className="cmsFormStepInputSelect"
                                    value={district}
                                    onChange={(e) => DistrictTypeChanged(e.target.value)}
                            >
                            <option value ="ALL">All Districts</option>
                                {districts.map((d,i) => {
                                    return(
                                        <option key={d?.id} value={d?.id}>
                                        {d?.name?.en}
                                    </option>
                                    )
                                    })}
                            </select>
                        </SelectInput>
                    </div> */}
                    <div className="fileManagerFilterSingle">
                        <SelectInput hasLable={false}>
                            <select  className="cmsFormStepInputSelect"
                                     value={taluk}
                                     onChange={(e) => TalukTypeChanged(e.target.value)}
                            >
                            <option value="ALL">All Taluks</option>    
                                {taluks?.map((t,i) => {
                                    return(
                                        <option key={t?.id} value={t?.id}>
                                        {t?.name?.en}
                                    </option>
                                    )
                                })}
                            </select>
                        </SelectInput>
                    </div>
                    <div className="fileManagerFilterSingle">
                        <SelectInput hasLable={false}>
                            <select className="cmsFormStepInputSelect"
                                    value={createdOn}
                                    onChange={(e) => createdOnChanged(e.target.value)}
                            >
                            <option value="RECENT">RECENT </option>
                            <option value="OLD">OLD</option>
                            </select>
                        </SelectInput>
                    </div>
                    {/* <div className="fileManagerFilterSingle">
                        <SelectInput hasLable={false}>
                            <select className="cmsFormStepInputSelect">
                                <option>Created By</option>
                            </select>
                        </SelectInput>
                    </div> */}
                </div>
            </div>

            {/* <div className="fileManagerFiltersAction">
                <Button 
                    type="button"
                    bgType="fill"
                    width="auto"
                    bg="primary"
                    borderRadius="full"
                    hasIcon={true}
                    iconPosition="left"
                    icon={icons.refresh}
                    action={handleRefresh}
                >
                    Refresh
                </Button>
            </div> */}
        </div>
    )
}

export default DDEntityListFilters