import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Card from '../../../components/core/dashboard/Card';
import CardHeading from '../../../components/core/typography/dashboard/CardHeading';
import CardDescription from '../../../components/core/typography/dashboard/CardDescription';
import TextInputRT from '../../../components/core/form/TextInputRT';
import FormActions from '../../../components/form/FormActions';
import { ToastAlert, validations } from '../../../utils';
import SelectInput from '../../../components/core/form/SelectInput';
import RadioButtonGroup from '../../../components/core/form/RadioButtonGroup';
import RadioButtonInput from '../../../components/core/form/RadioButtonInput';
import DistrictServices from '../../../services/Location/District';
import TalukServices from '../../../services/Location/Taluk';
import { EntityServices, SchoolServices } from '../../../services';
import { useParams } from 'react-router-dom';

const EditEntityPage = ({user}) => {
    const navigate = useNavigate();
    const params = useParams()

    const [districts, setDistricts] = useState([]);
    const [taluks, setTaluks] = useState([]);

    const schoolOrgTypeRef = useRef(null);
    const hostelOrgTypeRef = useRef(null);
    const postMatricTypeRef = useRef(null);
    const PreMatricTypeRef = useRef(null);

    const handleGetPublicDistrictsRequest = async () => {
        const getDistrictsReq = await DistrictServices.getDropdownDistricts({
            token: user.access_token
        });

        if(getDistrictsReq.status === false){
            ToastAlert.notifyError(getDistrictsReq.message);
            setDistricts([]);
        }
        else{
            setDistricts(getDistrictsReq.data);
        }
    }

    useEffect(() => {
        handleGetPublicDistrictsRequest();
    }, []);

    
    useEffect(() => {
        console.log(districts);
    }, [districts]);


    const [district, setDistrict] = useState(0);
    const [hasDistrictIcon, setHasDistrictIcon] = useState(false);
    const [districtIcon, setDistrictIcon] = useState("");
    const [districtIconPosition, setDistrictIconPosition] = useState("right");
    const [districtIconType, setDistrictIconType] = useState("");
    const [hasDistrictMessage, setHasDistrictMessage] = useState(false);
    const [districtMessage, setDistrictMessage] = useState("");
    const [districtMessageType, setDistrictMessageType] = useState("");

    const handleGetPublicTaluksReq = async () => {
        const getTaluksReq = await TalukServices.getDropdownTaluks({
            district:district,
            token: user.access_token
        })
      

        if(getTaluksReq.status === false){
            ToastAlert.notifyError(getTaluksReq.message);   
            setTaluks([]);
        }
        else{
            setTaluks(getTaluksReq.data);
        }
    }

    useEffect(()=>{
        // setTaluk(0)
        if(district != 0){
            handleGetPublicTaluksReq()
        }
        else{
            setDistricts([]);
        }
        
    },[district])

    useEffect(() => {
        console.log(taluks);
    }, [taluks]);

   
    const [taluk, setTaluk] = useState(0);
    const [hasTalukIcon, setHasTalukIcon] = useState(false);
    const [talukIcon, setTalukIcon] = useState("");
    const [talukIconPosition, setTalukIconPosition] = useState("right");
    const [talukIconType, setTalukIconType] = useState("");
    const [hasTalukMessage, setHasTalukMessage] = useState(false);
    const [talukMessage, setTalukMessage] = useState("");
    const [talukMessageType, setTalukMessageType] = useState("");


    const [enName, setEnName] = useState("");
    const [hasEnNameIcon, setHasEnNameIcon] = useState(false);
    const [enNameIcon, setEnNameIcon] = useState("");
    const [enNameIconPosition, setEnNameIconPosition] = useState("right");
    const [enNameIconType, setEnNameIconType] = useState("");
    const [hasEnNameMessage, setHasEnNameMessage] = useState(false);
    const [enNameMessage, setEnNameMessage] = useState("");
    const [enNameMessageType, setEnNameMessageType] = useState("");

    const [knName, setKnName] = useState("");
    const [hasKnNameIcon, setHasKnNameIcon] = useState(false);
    const [knNameIcon, setKnNameIcon] = useState("");
    const [knNameIconPosition, setKnNameIconPosition] = useState("right");
    const [knNameIconType, setKnNameIconType] = useState("");
    const [hasKnNameMessage, setHasKnNameMessage] = useState(false);
    const [knNameMessage, setKnNameMessage] = useState("");
    const [knNameMessageType, setKnNameMessageType] = useState("");

    const [orgType,setOrgType] =useState("")

    const [hostelType,setHostelType]=useState("")

    const enNameChanged = async (e) => {
        setEnName(e);

        // const isEnNameValid = await validations.disableSpecialCharacterUserName(e);
        // if(isEnNameValid.status){
        //     setEnName(e);
        // }
        // else if(e.length === 0){
        //     setEnName('');
        // }
    }

    const knNameChanged = async (e) => {
        // const isKnNameValid = await validations.disableSpecialCharacter(e);
        // if(isKnNameValid){
        //     setKnName(e);
        // }
        // else if(e.length === 0){
        //     setKnName('');
        // }

        setKnName(e);
    }

    const [entity,setEntity]=useState("")

    const handleGetThisAuthEntityReq = async () => {
        const getThisAuthEntityReq = await EntityServices.getThisAuthEntity({
            token:user.access_token,
            id:params.id
        })
      
      
        if(getThisAuthEntityReq.status === false){
            ToastAlert.notifyError(getThisAuthEntityReq.message);
        }
        else{
            setEntity(getThisAuthEntityReq?.data)
            setDistrict(getThisAuthEntityReq?.data?.district?.id)
            setTaluk(getThisAuthEntityReq?.data?.taluk?.id)
            setEnName(getThisAuthEntityReq?.data?.name?.en)
            setKnName(getThisAuthEntityReq?.data?.name?.kn)
            setOrgType(getThisAuthEntityReq?.data?.organization_type)
            setHostelType(getThisAuthEntityReq?.data?.hostel_type)

            if(getThisAuthEntityReq?.data?.organization_type === "SCHOOL"){
                schoolOrgTypeRef.current.checked = true;
                hostelOrgTypeRef.current.checked = false;
            }
            else{
                hostelOrgTypeRef.current.checked = true;
                schoolOrgTypeRef.current.checked = false;
            }

            // if(getThisAuthEntityReq?.data?.hostel_type === "POST_MATRIC"){
            //     postMatricTypeRef.current.checked = true;
            //     PreMatricTypeRef.current.checked = false;
            // }
            // else{
            //     PreMatricTypeRef.current.checked = true;
            //     postMatricTypeRef.current.checked = false;
            // }
            
        }
    }

    useEffect(()=>{
        handleGetThisAuthEntityReq()
    },[]) 

    const [submitButtonHasIcon, setSubmitButtonHasIcon] = useState(false);
    const [submitButtonIconPosition, setSubmitButtonIconPosition] = useState('left');
    const [submitButtonIco, setSubmitButtonIco] = useState(icons.tick);
    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleAddEntitySubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(district === 0){
            setHasDistrictIcon(true);
            setDistrictIcon(icons.wrongCircle);
            setDistrictIconType("error");
            setHasDistrictMessage(true);
            setDistrictMessage("Invalid District");
            setDistrictMessageType("error");
        }
        else{
            setHasDistrictIcon(true);
            setDistrictIcon(icons.tickCircle);
            setDistrictIconType("success");
            setHasDistrictMessage(false);
            setDistrictMessage("");
            setDistrictMessageType("");
        }
        if(taluk === 0){
            setHasTalukIcon(true);
            setTalukIcon(icons.wrongCircle);
            setTalukIconType("error");
            setHasTalukMessage(true);
            setTalukMessage("Invalid District");
            setTalukMessageType("error");
        }
        else{
            setHasTalukIcon(true);
            setTalukIcon(icons.tickCircle);
            setTalukIconType("success");
            setHasTalukMessage(false);
            setTalukMessage("");
            setTalukMessageType("");
        }

        // const isEnNameValid = await validations.validateUserName(enName);

        if(enName === ""){
            setHasEnNameIcon(true);
            setEnNameIcon(icons.wrongCircle);
            setEnNameIconType("error");
            setHasEnNameMessage(true);
            setEnNameMessage("Invalid English Name");
            setEnNameMessageType("error");
        }
        else{
            setHasEnNameIcon(true);
            setEnNameIcon(icons.tickCircle);
            setEnNameIconType("success");
            setHasEnNameMessage(false);
            setEnNameMessage("");
            setEnNameMessageType("");
        }

        if(knName === ""){
            setHasKnNameIcon(true);
            setKnNameIcon(icons.wrongCircle);
            setKnNameIconType("error");
            setHasKnNameMessage(true);
            setKnNameMessage("Invalid Kannada Name");
            setKnNameMessageType("error");
        }
        else{
            setHasKnNameIcon(true);
            setKnNameIcon(icons.tickCircle);
            setKnNameIconType("success");
            setHasKnNameMessage(false);
            setKnNameMessage("");
            setKnNameMessageType("");
        }

        if(enName === "" || knName === "" || district === 0 || taluk === 0 || orgType === "" || hostelType === ""){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const editEntityReq = await EntityServices.updateEntity({
                id:entity.id,
                en_name: enName,
                kn_name: knName,
                district: district,
                taluk:taluk,
                orgType:orgType,
                hostelType:hostelType,
                token: user.access_token
            });

            if(editEntityReq.status === false){
                ToastAlert.notifyError(editEntityReq.message);
                setSubmitButtonLoading(false);
                setHasEnNameIcon(false);
                setEnNameIcon("");
                setEnNameIconType("");
                setHasKnNameIcon(false);
                setKnNameIcon("");
                setKnNameIconType("");
                setHasDistrictIcon(false);
                setDistrictIcon("");
                setDistrictIconType("");
                setHasTalukIcon(false);
                setTalukIcon("");
                setTalukIconType("");
            }
            else{
                ToastAlert.notifySuccess(editEntityReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonHasIcon(true);
                setSubmitButtonLable('Success');
                navigate(-1);
            }
        }
    }

    return(
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Institution'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Update Institution'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.list}
                        action={()=> navigate('/masterdata/entity') }
                    >
                        View Institutions
                    </Button>
                </HeaderActions>
            </DefaultHeader>
            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type='full' styles={{paddingTop: "15px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                        <CardHeading heading={"update new Institution"} color="dark" />
                        <CardDescription description={"Institution: Dr. B.R. Ambedkar Pre Matric Boys Hostel"} />

                        <PlainContainer type='full' styles={{marginTop: "30px"}}>
                            <form onSubmit={(e) => handleAddEntitySubmission(e)} noValidate>
                                <div className="cmsForm">
                               <SplitContainer>
                               <SelectInput 
                                        hasLable={true}
                                        lable="District"
                                        isMandatory={true}
                                        hasIcon={hasDistrictIcon}
                                        icon={districtIcon}
                                        iconPosition={districtIconPosition}
                                        iconType={districtIconType}
                                        hasMessage={hasDistrictMessage}
                                        message={districtMessage}
                                        messageType={districtMessageType}
                                    >
                                        <select className="cmsFormStepInputSelect" value={district} onChange={(e) => setDistrict(e.target.value)}>
                                            <option value={0} disabled={true}>Select District</option>
                                            {
                                                districts.map((dt) => {
                                                    return (
                                                        <option key={dt.id} value={dt.id}>{dt?.name?.en}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </SelectInput>
                                    {
                                        district !== 0 &&
                                      (
                                     <SelectInput 
                                        hasLable={true}
                                        lable="Taluk"
                                        isMandatory={true}
                                        hasIcon={hasTalukIcon}
                                        icon={talukIcon}
                                        iconPosition={talukIconPosition}
                                        iconType={talukIconType}
                                        hasMessage={hasTalukMessage}
                                        message={talukMessage}
                                        messageType={talukMessageType}
                                    >
                                        <select className="cmsFormStepInputSelect" value={taluk} onChange={(e) => setTaluk(e.target.value)}>
                                            <option value={0} disabled={true}>Select Taluk</option>
                                            {
                                                taluks.map((tt) => {
                                                    return (
                                                        <option key={tt.id} value={tt.id}>{tt?.name?.en}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </SelectInput>
                                    )}
                               </SplitContainer>
                                    <SplitContainer>
                                        <TextInputRT 
                                            hasLable={true}
                                            lable="English Name"
                                            isMandatory={true}
                                            hasIcon={hasEnNameIcon}
                                            icon={enNameIcon}
                                            iconPosition={enNameIconPosition}
                                            iconType={enNameIconType}
                                            hasMessage={hasEnNameMessage}
                                            message={enNameMessage}
                                            messageType={enNameMessageType}
                                        >
                                            <input 
                                                type="text" 
                                                className="cmsFormStepInputText" 
                                                placeholder={"Enter Name"}
                                                onChange={(e) => enNameChanged(e.target.value)}
                                                value={enName}
                                            />
                                        </TextInputRT>
                                        <TextInputRT 
                                            hasLable={true}
                                            lable="Kannada Name"
                                            isMandatory={true}
                                            hasIcon={hasKnNameIcon}
                                            icon={knNameIcon}
                                            iconPosition={knNameIconPosition}
                                            iconType={knNameIconType}
                                            hasMessage={hasKnNameMessage}
                                            message={knNameMessage}
                                            messageType={knNameMessageType}
                                        >
                                            <input 
                                                type="text" 
                                                className="cmsFormStepInputText" 
                                                placeholder={"Enter Name"}
                                                onChange={(e) => knNameChanged(e.target.value)}
                                                value={knName}
                                            />
                                        </TextInputRT>
                                    </SplitContainer>
                                    <SplitContainer>
                                    <RadioButtonGroup lable="Institution Type" isMandatory={true} isError={false} error={""}>
                                        <RadioButtonInput lable="school" name="org_type" reference={schoolOrgTypeRef} value="SCHOOL" checked={orgType === "SCHOOL" ? true : false} action={(data) => setOrgType(data)} />
                                        <RadioButtonInput lable="hostel" name="org_type" reference={hostelOrgTypeRef} value="HOSTEL" checked={orgType === "HOSTEL" ? true : false} action={(data) => setOrgType(data)} />
                                    </RadioButtonGroup>
                                 
                                    {
                                        orgType === "HOSTEL"
                                        ?
                                        <>
                                             <RadioButtonGroup lable="Hostel Type " isMandatory={true} isError={false} error={""}>
                                        <RadioButtonInput lable="pre-materic" name="hostelType" reference={PreMatricTypeRef} value="PRE_MATRIC" checked={hostelType === "PRE_MATRIC" ? true : false} action={(data) => setHostelType(data)} />
                                        <RadioButtonInput lable="post-matric" name="hostelType" reference={postMatricTypeRef} value="POST_MATRIC" checked={hostelType === "POST_MATRIC" ? true : false} action={(data) => setHostelType(data)} />
                                    </RadioButtonGroup>
                                        </>
                                        :
                                        <></>
                                    }
                                    </SplitContainer>
                                   
                                  
                                    <FormActions>
                                        <Button 
                                            type="submit"
                                            bgType="fill"
                                            width="auto"
                                            bg={submitButtonBg}
                                            borderRadius="short"
                                            hasIcon={submitButtonHasIcon}
                                            iconPosition={submitButtonIconPosition}
                                            icon={submitButtonIco}
                                            disabled={submitButtonDisabled}
                                            isLoading={submitButtonLoading}
                                        >
                                            {submitButtonLable}
                                        </Button>
                                        <Button 
                                            type="button"
                                            bgType="fill"
                                            width="auto"
                                            bg={cancelButtonBg}
                                            borderRadius="short"
                                            action={() => navigate(-1)}
                                        >
                                            {cancelButtonLable}
                                        </Button>
                                    </FormActions>
                                </div>
                            </form>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default EditEntityPage